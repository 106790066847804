import React from 'react';
import Breadcrumbs from '../../widgets/breadcrumbs';
import WelcomeMessage from '../../widgets/welcome-message';
import SalesDisposalAlert from './sales-disposal-alert';
import SalesDisposalProvider from './sales-disposal-provider';
import SalesDisposalTable from './sales-disposal-table';
import './styles/sales-disposal.scss';

const SalesDisposal = () => {
  document.title = 'AFP - Sales and Disposal Inventory Management';
  return (
    <div className="grid-col sales-disposal-page">
      <SalesDisposalProvider>
        <>
          <Breadcrumbs
            path={[
              {
                location: `${window.AFP_CONFIG.appURLs.home}/home`,
                label: 'Home',
              },
            ]}
            current="Sales and Disposal Inventory Management"
          />
          <SalesDisposalAlert />
          <h1>Sales and Disposal Inventory Management</h1>
          <WelcomeMessage message="The Disposal Inventory page lists all vehicles that are in the sale/disposal process and where they are in that process. This page allows users to search for and find particular vehicles individually by VIN or tag, status, and/or location. Actions to move a vehicle forward in the disposal process can be taken by selecting the three dots to the right of each vehicle or by selecting. Actions - if multiple vehicles will be impacted at the same time." />
          <SalesDisposalTable />
        </>
      </SalesDisposalProvider>
    </div>
  );
};

export default SalesDisposal;
