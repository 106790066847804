/* eslint-disable react/button-has-type */
import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import AutoCompleteInput from '../../../components/autocomplete-input';
import { useFilter } from '../../../components/filter/filter-provider';
import { LOCATION_SEARCH } from '../sales-listing.gql';

const SaleLocationFilter = () => {
  const { filterState, onFilterItemChange } = useFilter();
  const [options, setOptions] = useState([]);

  const [fetchOptions] = useLazyQuery(LOCATION_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted: ({ locationSearch }) => {
      let filteredOptions = ['Error: 404'];
      if (locationSearch?.length) {
        filteredOptions = locationSearch.map((o) => ({
          key: o.id,
          label: `${o.name} - (${o.subsectionTypeCode})`,
          ...o,
        }));
      }
      setOptions(filteredOptions);
    },
  });

  const getOptions = (searchQuery) => {
    fetchOptions({
      variables: {
        limit: 25,
        typeCodes: ['AHO', 'MSI'],
        nameQuery: searchQuery,
      },
    });
  };

  return (
    <AutoCompleteInput
      id="sale-location-name"
      data-testid="sale-location-name"
      value={filterState?.saleLocation}
      options={options}
      onSelect={(selected) => {
        onFilterItemChange({ id: 'saleLocation', value: selected });
      }}
      onFilter={(keyword) => {
        getOptions(keyword);
      }}
      inputProps={{
        placeholder: 'Sale Location',
      }}
    />
  );
};

export default SaleLocationFilter;
