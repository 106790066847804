import { Accordion } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import FilterItemClear from './filter-item-clear';
import { useFilter } from './filter-provider';

const FilterItem = ({ id, title, content, clearButtons, expanded }) => {
  const { filterState, onFilterItemChange } = useFilter();
  return (
    <>
      <div className="filter-item__container border-left-2px border-gray-5">
        <Accordion
          items={[
            {
              id,
              title,
              className: 'overflow-visible',
              content,
              expanded,
            },
          ]}
        />
      </div>
      <div className="grid-col display-flex flex-column flex-align-start flex-align-self-center margin-left-2px margin-top-2">
        {clearButtons.map((cb, i) => {
          if (!filterState[cb.id]) return null;

          let clearText = '';

          if (typeof filterState[cb.id] === 'string')
            clearText = filterState[cb.id];

          if (typeof filterState[cb.id]?.label === 'string')
            clearText = filterState[cb.id].label;

          if (cb?.text) clearText = cb?.text;

          return (
            <FilterItemClear
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              value={clearText}
              onClear={() => {
                onFilterItemChange({ id: cb?.id, value: cb.clearValue });
              }}
            />
          );
        })}
      </div>
    </>
  );
};

FilterItem.defaultProps = {
  id: 'filter-item',
  title: '',
  content: null,
  clearButtons: [],
  expanded: false,
};

FilterItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.node,
  clearButtons: PropTypes.arrayOf(PropTypes.shape(Object)),
  expanded: PropTypes.bool,
};

export default FilterItem;
