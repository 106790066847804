import { DatePicker } from '@gsa/afp-component-library';
import { DateTime } from 'luxon';
import React from 'react';
import { useFilter } from '../../../components/filter/filter-provider';

const SaleDateFilter = () => {
  const { filterState, onFilterItemChange } = useFilter();

  const defaultValue = filterState?.saleStartDate
    ? DateTime.fromFormat(filterState.saleStartDate, 'D').toISODate()
    : undefined;

  return (
    // key is assigned to the DatePicker wrapper to force render whenever the default value changes.
    <div key={defaultValue}>
      <DatePicker
        id="sale-start-date"
        name="sale-start-date"
        data-testid="sale-start-date"
        readOnly
        defaultValue={defaultValue}
        onChange={(date) => {
          onFilterItemChange({ id: 'saleStartDate', value: date });
        }}
      />
    </div>
  );
};

export default SaleDateFilter;
