import { useLazyQuery } from '@apollo/client';
import { Button } from '@gsa/afp-component-library';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Breadcrumbs from '../../widgets/breadcrumbs';
import WelcomeMessage from '../../widgets/welcome-message';
import ContractLineItemTable from './contract-line-item-table';
import TaskOrderForm from './forms/task-order-form';
import './styles/create-task-order.scss';
import TaskOrderAlert from './task-order-alert';
import { useTaskOrder } from './task-order-provider';
import { GET_TASK_ORDER } from './task-order.gql';

// create,update,duplicate task order
const TaskOrderPage = () => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const {
    setTaskOrderData,
    setTaskOrderMode,
    setTaskOrderSelected,
    setRequestError,
  } = useTaskOrder();

  const updateTaskOrderMode = () => {
    if (location?.pathname.includes('view')) {
      setTaskOrderData('SET_TASK_ORDER_MODE', 'VIEW_TASK_ORDER');
    } else if (location?.pathname.includes('update')) {
      setTaskOrderData('SET_TASK_ORDER_MODE', 'UPDATE_TASK_ORDER');
    } else if (location?.pathname.includes('duplicate')) {
      setTaskOrderData('SET_TASK_ORDER_MODE', 'DUPLICATE_TASK_ORDER');
    }
  };

  // Get task order lazy.
  const [getTaskOrder] = useLazyQuery(GET_TASK_ORDER, {
    fetchPolicy: 'network-only',
    onError: (error) => setRequestError(error),
    onCompleted: (responseData) => {
      if (responseData?.getTaskOrder) {
        setTaskOrderData('SET_SELECTED', responseData?.getTaskOrder?.rows[0]);
        updateTaskOrderMode();
      }
    },
  });

  useEffect(() => {
    // on page refresh populate context
    if (location?.pathname.includes('create')) {
      setTaskOrderData('SET_TASK_ORDER_MODE', 'ADD_TASK_ORDER');
    } else if (!setTaskOrderSelected && setTaskOrderMode !== 'ADD_TASK_ORDER') {
      // get task order by task order number
      getTaskOrder({
        variables: {
          taskOrderNumber: params?.taskOrderNumber,
        },
      });
    }
  }, []);

  const handleCancel = () => {
    setTaskOrderData('RESET_CLIN_DATA', []);
    setTaskOrderData('SET_TASK_ORDER_MODE', '');
    setTaskOrderData('SET_MESSAGE', '');
    setTaskOrderData('SET_ERROR', '');
    history.push('/task-order');
  };
  return (
    <div className="margin-bottom-10" data-testid="task-order-page">
      <Breadcrumbs
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs.home}/home`,
            label: 'Home',
          },
        ]}
        current="Task Order"
      />
      <TaskOrderAlert />
      <h1 className="margin-top-neg-05">
        {setTaskOrderMode === 'VIEW_TASK_ORDER'
          ? `${setTaskOrderSelected?.taskOrderNumber} -
            ${setTaskOrderSelected?.vendorLocation?.name} -
            ${DateTime.fromISO(setTaskOrderSelected?.startDate).toFormat(
              'LL/dd/yyyy',
            )} -
            ${DateTime.fromISO(setTaskOrderSelected?.endDate).toFormat(
              'LL/dd/yyyy',
            )}`
          : 'Task Order'}
      </h1>
      <WelcomeMessage
        message="This page allows the user to create/update the task order level pricing
      for a marshalling or sale location. The user may search for a vendor,
      verify their information, and then update the applicable contract line
      item(s) (CLINs)."
      />
      <TaskOrderForm />
      <ContractLineItemTable />
      <div className="display-flex flex-row">
        <Button
          data-testid="create-task-order-cancel-button"
          className="display-flex margin-top-6"
          variant="unstyled margin-right-2"
          type="button"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        {setTaskOrderMode !== 'VIEW_TASK_ORDER' && (
          <Button
            data-testid="create-task-order-create-button"
            className="display-flex margin-top-4"
            variant="primary"
            type="submit"
            form="task-order-form"
          >
            {setTaskOrderMode !== 'ADD_TASK_ORDER' ? 'Save' : 'Create'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TaskOrderPage;
