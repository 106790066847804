import { useQuery } from '@apollo/client';
import React from 'react';
import PropTypes from 'prop-types';
import { Filter, FilterItem } from '../../../components/filter';
import { useTaskOrder } from '../task-order-provider';
import { GET_TASK_ORDER_FILTERS } from '../task-order.gql';
import EndDateFilter from './end-date-filter';
import ResponsibleSCOFilter from './responsible-sco-filter';
import SaleLocationCodeFilter from './sale-location-code-filter';
import StartDateFilter from './start-date-filter';
import StatusFilter from './status-filter';
import TaskOrderNumberFilter from './task-order-number-filter';
import VendorNameFilter from './vendor-name-filter';
import VendorTypeFilter from './vendor-type-filter';

const TaskOrderFilter = ({ onFilter }) => {
  const { setTaskOrderData } = useTaskOrder();

  useQuery(GET_TASK_ORDER_FILTERS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTaskOrderData('SET_TASK_ORDER_FILTERS', data.getTaskOrderFilters);
    },
  });

  return (
    <Filter onChange={onFilter}>
      <FilterItem
        id="task-order-number-filter"
        title="Task Order Number"
        clearButtons={[{ id: 'taskOrderNumber', clearValue: null }]}
        content={<TaskOrderNumberFilter />}
      />
      <FilterItem
        id="vendor-name-filter"
        title="Vendor Name"
        clearButtons={[{ id: 'vendorLocation', clearValue: null }]}
        content={<VendorNameFilter />}
      />
      <FilterItem
        id="responsible-sco-filter"
        title="Responsible COR"
        clearButtons={[{ id: 'responsibleSCO', clearValue: null }]}
        content={<ResponsibleSCOFilter />}
      />
      <FilterItem
        id="start-date-filter"
        title="Start Date"
        clearButtons={[{ id: 'startDate', clearValue: null }]}
        content={<StartDateFilter />}
      />
      <FilterItem
        id="end-date-filter"
        title="End Date"
        clearButtons={[{ id: 'endDate', clearValue: null }]}
        content={<EndDateFilter />}
      />
      <FilterItem
        id="sale-location-code-filter"
        title="Sale Location Code"
        clearButtons={[{ id: 'saleLocationCode', clearValue: null }]}
        content={<SaleLocationCodeFilter />}
      />
      <FilterItem
        id="status-filter"
        title="Status"
        clearButtons={[
          { text: 'Clear status', id: 'status', clearValue: null },
        ]}
        content={<StatusFilter />}
      />
      <FilterItem
        id="vendor-type-filter"
        title="Vendor Type"
        clearButtons={[
          {
            text: 'Clear vendor type',
            id: 'vendorType',
            clearValue: null,
          },
        ]}
        content={<VendorTypeFilter />}
      />
    </Filter>
  );
};

TaskOrderFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

export default TaskOrderFilter;
