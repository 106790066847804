import {
  AFPTable,
  AFPTableRowAction,
  Pagination,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useDidMountEffect from '../../hooks/use-did-mount';
import { formatDataValue, formatDate } from '../../utils/formatting';
import TaskOrderFilter from './task-order-filter';
import { useTaskOrder } from './task-order-provider';
import TaskOrderNumberCell from './widgets/task-order-number-cell';
import TaskOrderStatusTag from './widgets/task-order-status-tag';

const actionList = [
  {
    icon: 'content_copy',
    label: 'Duplicate Task Order',
    operation: 'create',
  },
  {
    icon: 'edit',
    label: 'Update Task Order',
    operation: 'update',
  },
];

const initFilters = [
  {
    conditions: [],
    operator: '$and',
  },
];

const TaskOrderTable = () => {
  const tableRef = React.createRef();
  const ability = useAppAbility();
  const history = useHistory();
  const [order, setOrder] = useState('taskOrderNumber DESC');

  const [filters, setFilters] = useState(initFilters);

  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const { getTaskOrderList, taskOrderList, setTaskOrderData } = useTaskOrder();

  const getData = () => {
    const { limit, offset } = paginationState;
    getTaskOrderList({
      variables: { filters, limit, offset, order },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useDidMountEffect(() => {
    getData();
  }, [filters, order, paginationState]);

  const formatFilter = useCallback((filterState) => {
    const filterObject = {
      operator: '$and',
      conditions: [],
    };

    // task order number
    if (filterState?.taskOrderNumber) {
      filterObject.conditions.push({
        operator: '$exact',
        key: 'taskOrderNumber',
        value: filterState.taskOrderNumber.taskOrderNumber,
      });
    }

    // vendor location
    if (filterState?.vendorLocation) {
      filterObject.conditions.push({
        operator: '$exact',
        key: 'contractorCode',
        value: filterState?.vendorLocation?.id,
      });
    }

    // responsible sco
    if (filterState?.responsibleSCO) {
      filterObject.conditions.push({
        operator: '$exact',
        key: 'responsibleSCO',
        value: filterState?.responsibleSCO.responsibleSCO,
      });
    }

    // start date
    if (filterState?.startDate) {
      const from = DateTime.fromFormat(filterState?.startDate, 'D')
        .startOf('day')
        .toUTC()
        .toISO();
      const to = DateTime.fromFormat(filterState?.startDate, 'D')
        .endOf('day')
        .toUTC()
        .toISO();

      filterObject.conditions.push({
        operator: '$between',
        key: 'startDate',
        value: [from, to],
      });
    }

    // end date
    if (filterState?.endDate) {
      const from = DateTime.fromFormat(filterState?.endDate, 'D')
        .startOf('day')
        .toUTC()
        .toISO();
      const to = DateTime.fromFormat(filterState?.endDate, 'D')
        .endOf('day')
        .toUTC()
        .toISO();

      filterObject.conditions.push({
        operator: '$between',
        key: 'endDate',
        value: [from, to],
      });
    }

    // sale location code
    if (filterState?.saleLocationCode) {
      filterObject.conditions.push({
        operator: '$exact',
        key: '$contractor.sale_location_code$',
        value: filterState?.saleLocationCode?.contractor?.saleLocationCode,
      });
    }

    // status
    if (filterState?.status) {
      filterObject.conditions.push({
        operator: '$in',
        key: 'status',
        value: filterState.status,
      });
    }

    // vendor type
    if (filterState?.vendorType) {
      filterObject.conditions.push({
        operator: '$in',
        key: '$contractor.vendor_type_code$',
        value: filterState.vendorType,
      });
    }
    setFilters(filterObject);
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return (
      <div className="grid-container sales-disposal-sub-component-wrapper">
        <div className="grid-row grid-gap-6">
          <div className="tablet:grid-col desktop:margin-bottom-4">
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Vendor Type</div>
              <div className="tablet:grid-col text-right">
                {original?.contractor?.vendorType?.title}
              </div>
            </div>
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Sale Location Code</div>
              <div className="tablet:grid-col text-right">
                {original?.contractor?.saleLocationCode}
              </div>
            </div>
          </div>
          <div className="tablet:grid-col">
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Responsible COR</div>
              <div className="tablet:grid-col text-right">
                {original?.responsibleSCO}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const handleSelectedAction = (e, row) => {
    setTaskOrderData('SET_SELECTED', row?.original);

    if (e === 'Update Task Order') {
      setTaskOrderData('SET_TASK_ORDER_MODE', 'UPDATE_TASK_ORDER');
      setTaskOrderData('SET_MESSAGE', '');
      setTaskOrderData('SET_ERROR', '');
      history.push(`/task-order/update/${row?.original?.taskOrderNumber}`);
    } else if (e === 'Duplicate Task Order') {
      setTaskOrderData('SET_MESSAGE', '');
      setTaskOrderData('SET_ERROR', '');
      setTaskOrderData('SET_TASK_ORDER_MODE', 'DUPLICATE_TASK_ORDER');
      history.push(`/task-order/duplicate/${row?.original?.taskOrderNumber}`);
    }
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Task Order Number',
        accessor: 'taskOrderNumber',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value, row: { original } }) => {
          return (
            <TaskOrderNumberCell taskOrderNumber={value} data={original} />
          );
        },
      },
      {
        Header: 'Vendor',
        accessor: 'vendorLocation',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          // eslint-disable-next-line react/prop-types
          const name = value?.name ? value.name : '';
          const state = value?.stateCode ? `,${value.stateCode}` : '';
          return formatDataValue(`${name}${state}`);
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return (
            <TaskOrderStatusTag
              // eslint-disable-next-line react/prop-types
              statusCode={original?.status}
            />
          );
        },
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        Cell: ({ value }) => formatDataValue(formatDate(value, 'LL/dd/yyyy')),
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        Cell: ({ value }) => formatDataValue(formatDate(value, 'LL/dd/yyyy')),
      },
    ];

    const allowedActions = actionList.filter((a) =>
      ability.can(a.operation, 'AHMTaskOrder'),
    );

    if (allowedActions.length > 0) {
      columnList.push({
        Header: 'Actions',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: (props) => (
          <AFPTableRowAction
            actions={allowedActions}
            // eslint-disable-next-line react/prop-types
            onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
            {...props}
          />
        ),
      });
    }

    return columnList;
  }, []);

  return (
    <>
      <div className="grid-row grid-gap-2">
        <div className="tablet:grid-col-3 tablet:padding-top-4 ">
          <TaskOrderFilter onFilter={formatFilter} />
        </div>
        <div className="tablet:grid-col-9">
          <AFPTable
            expandable
            fullWidth
            ref={tableRef}
            onSort={setOrder}
            testId="task-order-table"
            columns={columns}
            data={taskOrderList.rows}
            renderRowSubComponent={renderRowSubComponent}
          />
          <div className="margin-y-6">
            <Pagination
              fullWidth
              variant="advanced"
              itemsPerPageOptions={[10, 25, 50]}
              itemsCount={taskOrderList?.count}
              itemsPerPage={paginationState.limit}
              currentPage={paginationState.currentPage}
              onPageChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskOrderTable;
