import { ComboBox } from '@gsa/afp-component-library';
import React from 'react';
import { useFilter } from '../../../components/filter/filter-provider';
import { useMarshalling } from '../marshalling-provider';

const VehicleFilter = () => {
  const {
    marshallingFilters: { makeFilter, modelFilter },
  } = useMarshalling();
  const { filterState, onFilterItemChange } = useFilter();

  const makeModels =
    modelFilter.filter((m) => m?.makeCode === filterState?.makeCode) ?? [];

  return (
    <>
      {/* key is assigned to the ComboBox wrapper to force render whenever the default value changes. */}
      <div key={`${makeFilter.length}_${filterState?.makeCode ?? 'make_code'}`}>
        <ComboBox
          data-testid="vehicle-filter-make"
          id="vehicle-filter-make"
          name="vehicle-filter-make"
          options={makeFilter}
          defaultValue={filterState?.makeCode}
          onChange={(selected) => {
            onFilterItemChange({ id: 'makeCode', value: selected });
          }}
          inputProps={{ placeholder: 'Make' }}
        />
      </div>

      {/* Note: ComboBox has a div wrapper with key. Each time the value changes, the ComboBox re-render */}
      <div key={filterState?.makeCode}>
        <ComboBox
          data-testid="vehicle-filter-model"
          id="vehicle-filter-model"
          name="vehicle-filter-model"
          options={makeModels}
          onChange={(selected) => {
            onFilterItemChange({ id: 'modelCode', value: selected });
          }}
          inputProps={{ placeholder: 'Model' }}
        />
      </div>
    </>
  );
};

export default VehicleFilter;
