/* eslint-disable react/button-has-type */
import React from 'react';
import { TextInput } from '@gsa/afp-component-library';
import { useFilter } from '../../../components/filter/filter-provider';

const RunNumberFilter = () => {
  const { filterState, onFilterItemChange } = useFilter();

  return (
    <TextInput
      id="runNumberFilterId"
      data-testid="run-number-filter-id"
      name="runNumberFilterId"
      value={filterState?.runNumber || ''}
      onChange={({ target: { value } }) => {
        onFilterItemChange({
          id: 'runNumber',
          value,
        });
      }}
      placeholder="Search Run #"
    />
  );
};

export default RunNumberFilter;
