import { Calendar } from '@gsa/afp-component-library';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import useDidMountEffect from '../../hooks/use-did-mount';
import ScheduleTable from '../marshalling-schedule/schedule-table';
import { useMarshallingSchedule } from '../marshalling-schedule/marshalling-schedule-provider';
import MarshallingInformation from '../marshalling-schedule/marshalling-information';
import DriverInformation from './driver-information';

const DriverAppointmentScheduler = () => {
  const [selectedDate, setSelectedDate] = useState();
  const {
    nonWorkingHours,
    blockedAppointments,
    setMarshallingScheduleData,
    driverBlockedAppointments,
    getDriverScheduledAppointmentList,
    getScheduledAppointmentList,
    selectedWeekStartDate,
    selectedWeekEndDate,
  } = useMarshallingSchedule();

  const getData = (weekStartDate, weekEndDate) => {
    getScheduledAppointmentList({
      variables: {
        fleetVendorNumber: '1069',
        weekStartDate,
        weekEndDate,
      },
    });
  };

  const getDriverAppointmentData = (weekStartDate, weekEndDate) => {
    getDriverScheduledAppointmentList({
      variables: {
        fleetVendorNumber: '1069',
        weekStartDate,
        weekEndDate,
      },
    });
  };

  useEffect(() => {
    const currentWeekStartDate = moment(selectedDate).startOf('week').toDate();
    const currentWeekEndDate = moment(selectedDate).endOf('week').toDate();
    getData(currentWeekStartDate, currentWeekEndDate);
    getDriverAppointmentData(currentWeekStartDate, currentWeekEndDate);
  }, [selectedDate]);

  useDidMountEffect(() => {
    getData(selectedWeekStartDate, selectedWeekEndDate);
  }, [selectedWeekStartDate, selectedWeekEndDate]);

  return (
    <div className="grid-col calendar-test">
      <div className="grid-row row-gap">
        <div className="tablet:grid-col-3">
          <h2 className="text-primary">SCHEDULE APPOINTMENT</h2>
          <Calendar
            handleSelectDate={(dateString) => {
              setMarshallingScheduleData(
                'SET_SELECTED_WEEK_START_DATE',
                moment(dateString).startOf('week').toDate().toString(),
              );
              setMarshallingScheduleData(
                'SET_SELECTED_WEEK_END_1DATE',
                moment(dateString).endOf('week').toDate().toString(),
              );
              return setSelectedDate(dateString);
            }}
            disableWeekends={false}
            onKeyDown={null}
          />
          <h2 className="text-primary padding-top-4">MARSHALLER INFORMATION</h2>
          <MarshallingInformation />
        </div>
        <div className="grid-col-8 margin-left-3">
          <ScheduleTable
            dateSelected={selectedDate}
            blockedHours={driverBlockedAppointments}
            unavailableHours={blockedAppointments}
            bookedHours={nonWorkingHours}
            onDateSelected={(date) => {
              setMarshallingScheduleData('SET_APPOINTMENT_START_TIME', date);
            }}
          />
          <DriverInformation />
        </div>
      </div>
    </div>
  );
};

export default DriverAppointmentScheduler;
