import React from 'react';
import BannerMessage from '../../components/banner-message';
import { useTaskOrder } from './task-order-provider';

const TaskOrderAlert = () => {
  const {
    setTaskOrderMessage: { type, message },
    setTaskOrderData,
  } = useTaskOrder();

  if (!type || !message) return null;

  return (
    <BannerMessage
      data-testid="task-order-alert"
      type={type}
      message={message}
      onClose={() => {
        setTaskOrderData('SET_MESSAGE', { type: 'success', message: '' });
      }}
    />
  );
};

export default TaskOrderAlert;
