import React from 'react';
import BannerMessage from '../../components/banner-message';
import { useVehicleSales } from './vehicle-sales-provider';

const VehicleSalesAlert = () => {
  const {
    vehicleSalesMessage: { type, message },
    setVehicleSalesData,
  } = useVehicleSales();

  if (!type || !message) return null;

  return (
    <BannerMessage
      type={type}
      message={message}
      onClose={() => {
        setVehicleSalesData('SET_MESSAGE', { type: 'success', message: '' });
      }}
    />
  );
};

export default VehicleSalesAlert;
