import React from 'react';
import PropTypes from 'prop-types';
import { StatusTag } from '@gsa/afp-component-library';

const colors = {
  green: 'green',
  cyan: 'cyan',
  red: 'red',
  grey: 'gray-30',
  gold: 'gold',
  purple: 'purple',
};

const statusMap = {
  READYFORSALE: colors.green,
  READYFORDISPOSAL: colors.green,
  ATAUCTIONHOUSE: colors.green,
  INTRANSIT: colors.cyan,
  SECUREDATSALELOCATION: colors.green,
  ONHOLD: colors.gold,
  LOTTED: colors.green,
  AWARD: colors.purple,
  PAID: colors.green,
  SOLD: colors.grey,
  REMOVED: colors.grey,
  STOLEN: colors.red,
  DEFAULT: colors.green,
  CLAIM: colors.gold,
  ACTIVE: colors.green,
  CLOSED: colors.grey,
  CANCEL: colors.gold,
};

const DisposalStatusTag = ({ statusCode, statusDescription, ...restProps }) => {
  if (!statusCode) {
    return null;
  }
  const status = statusMap[statusCode] || {
    color: colors.grey,
    description: statusDescription,
  };
  return (
    <StatusTag color={status} {...restProps}>
      {statusDescription}
    </StatusTag>
  );
};

DisposalStatusTag.defaultProps = {
  statusDescription: undefined,
  statusCode: undefined,
};

DisposalStatusTag.propTypes = {
  statusDescription: PropTypes.string,
  statusCode: PropTypes.string,
};

export default DisposalStatusTag;
