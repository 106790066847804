import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import {
  ADD_VEHICLE_ASSOCIATION,
  ADD_VEHICLE_SALE,
  GET_DISPOSAL_LIST,
  UPDATE_CONDITION_CODE,
} from './sales-disposal.gql';

export const SalesDisposalContext = createContext();

const initialState = {
  salesDisposalList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  salesDisposalSelected: null,
  salesDisposalModalMode: null,
  salesDisposalModalShow: false,
  salesDisposalMessage: { type: '', message: '' }, // type => error || success || info e.g.: => {type: 'error', 'Association already exists.'}
  salesDisposalError: null,
  disposalListFilters: {
    disposalStatusFilter: [],
    conditionCodeFilter: [],
    makeFilter: [],
    modelFilter: [],
    tagCountFilter: [],
    yearFilter: [],
  },
  lookup: {
    transportationType: [],
  },
  vehicleSaleRowsSelected: [],
  selectedRows: {},
  selectDisabledRows: [],
};

const getSelectedRows = (selected = [], rows = []) => {
  const selectableRows = {};
  selected.forEach((s) => {
    const index = rows.findIndex((v) => s?.original?.vin === v?.vin);
    if (index >= 0) selectableRows[index] = true;
  });
  return selectableRows;
};

const getDisabledRows = (rows = []) => {
  const disabledRows = [];
  rows.forEach((r, i) => {
    if (!r?.canBeLotted) disabledRows.push(i);
  });
  return disabledRows;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SALES_DISPOSAL_LIST': {
      const { rows = [], count = 0, ...rest } = action.payload;
      let newCount = count;
      let newRows = rows;

      const previouslySelected = state?.vehicleSaleRowsSelected.map(
        (s) => s?.original,
      );

      if (previouslySelected.length) {
        previouslySelected.forEach((s) => {
          newRows = [s, ...newRows.filter((r) => r.vin !== s.vin)];
          newCount += 1;
        });
      }

      return {
        ...state,
        salesDisposalList: { rows: newRows, count: newCount, ...rest },
        selectedRows: getSelectedRows(state.vehicleSaleRowsSelected, newRows),
        selectDisabledRows: getDisabledRows(newRows),
      };
    }

    case 'SET_SELECTED':
      return { ...state, salesDisposalSelected: action.payload };

    case 'SET_MODAL_MODE': {
      return { ...state, salesDisposalModalMode: action.payload };
    }

    case 'SET_SHOW_MODAL': {
      return { ...state, salesDisposalModalShow: action.payload };
    }

    case 'SET_MESSAGE':
      return { ...state, salesDisposalMessage: action.payload };

    case 'SET_ERROR': {
      return { ...state, salesDisposalError: action.payload };
    }

    case 'SET_DISPOSAL_LIST_FILTERS':
      return {
        ...state,
        disposalListFilters: action.payload,
      };

    case 'SET_LOOKUP_ITEM':
      return {
        ...state,
        lookup: { [action.key]: action.payload },
      };

    case 'SET_VEHICLE_SALE_MODAL_MODE': {
      return { ...state, vehicleSaleModalMode: action.payload };
    }

    case 'SET_VEHICLE_SALE_ROWS_SELECTED': {
      return {
        ...state,
        vehicleSaleRowsSelected: action.payload,
      };
    }

    case 'RESET_ROW_SELECTIONS':
      return {
        ...state,
        vehicleSaleRowsSelected: [],
        selectedRows: {},
        selectDisabledRows: [],
      };

    default:
      throw new Error('Invalid action');
  }
};

function SalesDisposalProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setSalesDisposalError = (type, payload) => {
    dispatch({
      type: 'SET_ERROR',
      payload: { ...state.salesDisposalError, [type]: payload },
    });
  };

  const setSalesDisposalData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const setNestedSalesDisposalData = (type, key, payload) => {
    dispatch({
      type,
      key,
      payload,
    });
  };

  const resetModal = () => {
    setSalesDisposalData('SET_SHOW_MODAL', false);
    setSalesDisposalData('SET_MODAL_MODE', null);
    setSalesDisposalData('SET_SELECTED', null);
  };

  const setRequestError = (requestError) => {
    setSalesDisposalError('SET_ERROR', requestError.message);
    setSalesDisposalData('SET_MESSAGE', {
      type: 'error',
      message: `${requestError.message} `,
    });
  };

  // Get vehicle list lazy.
  const [getDisposalList, { refetch, loading: salesDisposalLoading }] =
    useLazyQuery(GET_DISPOSAL_LIST, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: setRequestError,
      onCompleted: ({ getDisposalList: responseData }) => {
        if (getDisposalList) {
          const vehicle = responseData.rows.map((row) => {
            return {
              ...row,
              rowName:
                row.modelYear && row.makeName && row.modelName
                  ? `${row.modelYear} ${row.makeName} ${row.modelName}`
                  : '',
            };
          });
          const formattedData = { ...responseData, rows: vehicle };
          setSalesDisposalData('SET_SALES_DISPOSAL_LIST', formattedData);
          setSalesDisposalError('SET_SALES_DISPOSAL_LIST', '');
        }
      },
    });

  // Add auction house association.
  const [addAuctionHouse] = useMutation(ADD_VEHICLE_ASSOCIATION, {
    onError: setRequestError,
    onCompleted: () => {
      setSalesDisposalData('SET_SHOW_MODAL', false);

      const vin = state?.salesDisposalSelected?.vin;
      const makeName = state?.salesDisposalSelected?.makeName;
      const modelName = state?.salesDisposalSelected?.modelName;
      const year = state?.salesDisposalSelected?.modelYear;
      const name = state?.salesDisposalSelected?.currentLocation?.name ?? '';
      const stateCode =
        state?.salesDisposalSelected?.currentLocation?.stateCode ?? '';
      const stateValue = stateCode ? `, ${stateCode}` : '';

      const location = `${name}${stateValue}`.length
        ? `to <strong>${name}${stateValue}</strong>`
        : '';

      const successMessage = `You have successfully associated <strong>${year} ${makeName} ${modelName} ${vin}</strong> ${location}`;

      setSalesDisposalData('SET_MESSAGE', {
        type: 'success',
        message: successMessage,
      });

      refetch({
        variables: {
          limit: 10,
          offset: 0,
          order: 'updatedAt DESC',
        },
      });
    },
  });

  const [addVehicleSale] = useMutation(ADD_VEHICLE_SALE, {
    onError: setRequestError,
    onCompleted: (responseData) => {
      setSalesDisposalData('SET_SHOW_MODAL', false);

      const { lotNumber, saleNumber } = responseData?.addVehicleSale;

      const successMessage = `The selected vehicle(s) have been lotted to the Sale Number <strong>${saleNumber}</strong> with Lot Number <strong>${lotNumber}</strong>`;

      setSalesDisposalData('SET_MESSAGE', {
        type: 'success',
        message: successMessage,
      });

      dispatch({ type: 'RESET_ROW_SELECTIONS' });

      refetch({
        variables: {
          limit: 10,
          offset: 0,
          order: 'updatedAt DESC',
        },
      });
    },
  });

  // Update condition code.
  const [updateConditionCode] = useMutation(UPDATE_CONDITION_CODE, {
    onError: setRequestError,
    onCompleted: ({ updateConditionCode: { vin } }) => {
      setSalesDisposalData('SET_SHOW_MODAL', false);

      const successMessage = `The condition code for VIN Number <strong>${vin}</strong> has been successfully updated.`;
      setSalesDisposalData('SET_MESSAGE', {
        type: 'success',
        message: successMessage,
      });

      refetch({
        variables: {
          limit: 10,
          offset: 0,
          order: 'updatedAt DESC',
        },
      });
    },
  });

  return (
    <SalesDisposalContext.Provider
      value={{
        ...state,
        getDisposalList,
        setSalesDisposalData,
        setNestedSalesDisposalData,
        setSalesDisposalError,
        addAuctionHouse,
        addVehicleSale,
        resetModal,
        updateConditionCode,
        salesDisposalLoading,
        ...props,
      }}
    >
      {children}
    </SalesDisposalContext.Provider>
  );
}

export default SalesDisposalProvider;

SalesDisposalProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useSalesDisposal = () => useContext(SalesDisposalContext);
