import { Icon, TextInput } from '@gsa/afp-component-library';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useVehicleSales } from '../vehicle-sales-provider';

const DesiredPriceField = () => {
  const { vehicleSalesSelected } = useVehicleSales();

  const { control, setValue } = useFormContext();

  return (
    <div className="grid-col-3 margin-y-2">
      <Controller
        name="desiredPrice"
        control={control}
        defaultValue={
          vehicleSalesSelected?.desiredPrice
            ? parseFloat(vehicleSalesSelected.desiredPrice).toFixed(2)
            : ''
        }
        render={({ field: { ref, ...fieldProps } }) => (
          <>
            <Icon iconName="attach_money" className="prefix-icon-dollar" />
            <TextInput
              data-testid="desired-price-box"
              className="text-right"
              type="number"
              {...fieldProps}
              onChange={(e) => {
                setValue('desiredPrice', parseFloat(e.target.value));
              }}
            />
          </>
        )}
      />
    </div>
  );
};

export default DesiredPriceField;
