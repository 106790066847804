import { ApolloProvider } from '@apollo/client';
import '@gsa/afp-component-library/dist/css/index.css';
import { AppProvider, PrivateRoute } from '@gsa/afp-shared-ui-utils';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import Unauthorized from './components/error-message/unauthorized';
import Layout from './components/layout/Layout';
import SalesDisposal from './pages/sales-disposal';
import SalesListing from './pages/sales-listing';
import TaskOrder from './pages/task-order';
import TaskOrderPage from './pages/task-order/task-order-page';
import TaskOrderProvider from './pages/task-order/task-order-provider';
import VehicleSales from './pages/vehicle-sales';
import dataStore from './services/data-store';
import Marshalling from './pages/marshalling';
import MarshallingSchedule from './pages/marshalling-schedule';
import DriverVehicleSchedule from './pages/driver-schedule';

function App() {
  return (
    <ApolloProvider client={dataStore}>
      <AppProvider>
        <Layout>
          <Router>
            <Switch>
              <Route exact path="/unauthorized" component={Unauthorized} />
              <PrivateRoute
                exact
                path="/"
                component={SalesDisposal} // Temporary placeholder.
                operation="view"
                subject="VehicleListing"
              />
              <PrivateRoute
                exact
                path="/vehicle-listing"
                component={SalesDisposal}
                operation="view"
                subject="VehicleListing"
              />
              <PrivateRoute
                exact
                path="/sales-listing"
                component={SalesListing}
                operation="view"
                subject="SaleListing"
              />
              <PrivateRoute
                exact
                path="/vehicle-sales/:saleNumber?"
                component={VehicleSales}
                operation="view"
                subject="VehicleSale"
              />
              <PrivateRoute
                exact
                path="/marshalling"
                component={Marshalling}
                operation="view"
                subject="VehicleMarshalling"
              />
              <PrivateRoute
                exact
                path="/marshalling-schedule"
                component={MarshallingSchedule}
                operation="view"
                subject="VehicleMarshalling"
              />
              <PrivateRoute
                exact
                path="/driver-schedule"
                component={DriverVehicleSchedule}
                operation="view"
                subject="VehicleMarshalling"
              />
              <TaskOrderProvider>
                <>
                  <PrivateRoute
                    exact
                    path="/task-order"
                    component={TaskOrder}
                    operation="view"
                    subject="AHMTaskOrder"
                  />
                  <PrivateRoute
                    exact
                    path="/task-order/create"
                    component={TaskOrderPage}
                    operation="create"
                    subject="AHMTaskOrder"
                  />
                  <PrivateRoute
                    exact
                    path="/task-order/update/:taskOrderNumber?"
                    component={TaskOrderPage}
                    operation="update"
                    subject="AHMTaskOrder"
                  />
                  <PrivateRoute
                    exact
                    path="/task-order/view/:taskOrderNumber?"
                    component={TaskOrderPage}
                    operation="view"
                    subject="AHMTaskOrder"
                  />
                  <PrivateRoute
                    exact
                    path="/task-order/duplicate/:taskOrderNumber?"
                    component={TaskOrderPage}
                    operation="create"
                    subject="AHMTaskOrder"
                  />
                </>
              </TaskOrderProvider>
            </Switch>
          </Router>
        </Layout>
      </AppProvider>
    </ApolloProvider>
  );
}

export default App;
