import { TextInput, Label } from '@gsa/afp-component-library';
import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext, Controller } from 'react-hook-form';

const DriverEmailAddress = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="driver-email-address height-10 margin-bottom-3">
      <Label required className="text-bold">
        Email
      </Label>
      <Controller
        name="driverEmail"
        control={control}
        rules={{
          required: 'Email address is required',
        }}
        defaultValue=""
        render={({ field: { ref, ...fieldProps } }) => (
          <TextInput data-testid="driver-email-address" {...fieldProps} />
        )}
      />
      <div className="margin-y-1 text-bold text-secondary">
        <ErrorMessage errors={errors} name="driverEmail" />
      </div>
    </div>
  );
};

export default DriverEmailAddress;
