import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSalesListing } from '../sales-listing-provider';
import PromoDescriptionField from './promo-description-field';

const SalesListingCancel = () => {
  const { cancelSaleListing, salesListingSelected } = useSalesListing();
  const methods = useForm();

  const onCancel = ({ promotionDescription }) => {
    cancelSaleListing({
      variables: {
        saleNumber: salesListingSelected.saleNumber,
        promotionDescription,
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="sale-listing-cancel-form"
        id="sale-listing-cancel-form"
        onSubmit={methods.handleSubmit(onCancel)}
      />
      <div className="display-flex flex-row margin-top-1">
        <div>Are you sure you want to cancel the sale for</div>
        <div className="text-bold margin-left-05">
          {salesListingSelected?.saleNumber}?
        </div>
      </div>
      <PromoDescriptionField />
    </FormProvider>
  );
};

export default SalesListingCancel;
