import { gql } from '@apollo/client';

export const GET_MARSHALLING_VEHICLES_LIST = gql`
  query getMarshallingVehiclesList(
    $filters: [Filter!]
    $order: OrderBy
    $limit: Int!
    $offset: Int
  ) {
    getMarshallingVehiclesList(
      filters: $filters
      order: $order
      limit: $limit
      offset: $offset
    ) {
      rows {
        vin
        makeName
        modelName
        year
        marshallingCustomer {
          code
          title
        }
        vehicleMarshallingStatus {
          code
          title
        }
        receivedDate
        tag
        orderNumber
      }
      count
      hasMore
    }
  }
`;

export const GET_MARSHALLING_LIST_VINS = gql`
  query getMarshallingVehiclesList($limit: Int!, $filters: [Filter!]!) {
    getMarshallingVehiclesList(limit: $limit, filters: $filters) {
      rows {
        vin
      }
    }
  }
`;

export const GET_MARSHALLING_LIST_TAGS = gql`
  query getMarshallingVehiclesList($limit: Int!, $filters: [Filter!]!) {
    getMarshallingVehiclesList(limit: $limit, filters: $filters) {
      rows {
        tag
      }
    }
  }
`;

export const GET_MARSHALLING_FILTERS = gql`
  query GetMarshallingFilters {
    getMarshallingFilters {
      makeFilter
      modelFilter
      yearFilter
      statusFilter
      customerFilter
    }
  }
`;

export default {
  GET_MARSHALLING_VEHICLES_LIST,
  GET_MARSHALLING_LIST_VINS,
  GET_MARSHALLING_FILTERS,
  GET_MARSHALLING_LIST_TAGS,
};
