import PropTypes from 'prop-types';

export const StatusTagType = {
  value: PropTypes.string,
  tagged: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export const MenuItemsType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    icon: PropTypes.string,
  }),
);

export const VehicleRowActionButtonType = {
  vin: PropTypes.string,
  onclick: PropTypes.func,
  menuItems: MenuItemsType,
};

export const vehicleTableCellType = {
  title: PropTypes.string,
  vin: PropTypes.string,
};

export const DetailRowType = {
  label: PropTypes.string,
  value: PropTypes.string,
};

export const VehicleDetailsRowType = {
  vin: PropTypes.string,
  makeColor: PropTypes.string,
  status: PropTypes.string,
};

export const SalesLocationType = {
  location: PropTypes.shape({
    name: PropTypes.string,
    stateCode: PropTypes.string,
  }),
};

export const DisposalConditionType = {
  value: PropTypes.string,
};
