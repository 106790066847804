import { Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTaskOrder } from '../task-order-provider';

const TaskOrderNumberCell = ({ data, taskOrderNumber }) => {
  const { setTaskOrderData } = useTaskOrder();

  const history = useHistory();
  const handleClick = () => {
    setTaskOrderData('SET_SELECTED', data);
    setTaskOrderData('SET_TASK_ORDER_MODE', 'VIEW_TASK_ORDER');
    setTaskOrderData('SET_MESSAGE', '');
    setTaskOrderData('SET_ERROR', '');
    history.push(`/task-order/view/${taskOrderNumber}`);
  };

  return (
    <>
      <Button
        data-testid={`task-order-number-detail-link-${taskOrderNumber}`}
        variant="unstyled"
        onClick={handleClick}
      >
        {taskOrderNumber}
      </Button>
    </>
  );
};

TaskOrderNumberCell.defaultProps = {
  taskOrderNumber: undefined,
  data: undefined,
};

TaskOrderNumberCell.propTypes = {
  taskOrderNumber: PropTypes.string,
  data: PropTypes.shape({
    contractLineItem: PropTypes.arrayOf(
      PropTypes.shape({
        contractLineItemId: PropTypes.string,
        contractLineItemType: PropTypes.string,
        price: PropTypes.number,
        pricingOption: PropTypes.string,
      }),
    ),
    contractor: PropTypes.shape({
      saleLocationCode: PropTypes.string,
    }),
    endDate: PropTypes.string,
    startDate: PropTypes.string,
    status: PropTypes.string,
    taskOrderNumber: PropTypes.string,
    responsibleSCO: PropTypes.string,
    vendorLocation: PropTypes.shape({
      address1: PropTypes.string,
      city: PropTypes.string,
      name: PropTypes.string,
      postalCode: PropTypes.string,
      stateCode: PropTypes.string,
    }),
  }),
};

export default TaskOrderNumberCell;
