import { Button } from '@gsa/afp-component-library';
import React from 'react';
import ModalWrapper from '../../components/modal-wrapper/modal-wrapper';
import { useTaskOrder } from './task-order-provider';
import ContractLineItemEdit from './forms/add-contract-line-item-edit';

const ModalTitle = () => {
  const { setContractLineItemModalMode } = useTaskOrder();

  let title = '';
  switch (setContractLineItemModalMode) {
    case 'EDIT_CONTRACT_LINE_ITEM':
      title = <h2>Edit Contract Line Item</h2>;
      break;
    default:
      break;
  }
  return title;
};

const ModalContent = () => {
  const { setContractLineItemModalMode } = useTaskOrder();

  switch (setContractLineItemModalMode) {
    case 'EDIT_CONTRACT_LINE_ITEM':
      return (
        <>
          <p>
            Use this window to edit CLIN ID, CLIN Type, Pricing options and
            Price.
          </p>
          <ContractLineItemEdit />
        </>
      );
    default:
      return null;
  }
};

const ModalAction = () => {
  const { resetModal, setContractLineItemModalMode } = useTaskOrder();

  switch (setContractLineItemModalMode) {
    case 'EDIT_CONTRACT_LINE_ITEM':
      return (
        <>
          <Button
            variant="unstyled margin-right-2"
            onClick={resetModal}
            data-testid="add-contract-line-item-edit-form-cancel-button"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="add-contract-line-item-edit-form"
            data-testid="add-contract-line-item-edit-form-save-button"
          >
            Save
          </Button>
        </>
      );
    default:
      return null;
  }
};

const ContractLineItemModal = () => {
  const { resetModal, contractLineItemModalShow } = useTaskOrder();

  return (
    <ModalWrapper
      title={<ModalTitle />}
      actions={<ModalAction />}
      show={contractLineItemModalShow}
      onClose={resetModal}
    >
      <ModalContent />
    </ModalWrapper>
  );
};

export default ContractLineItemModal;
