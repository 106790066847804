/* eslint-disable react/button-has-type */
import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import AutoCompleteInput from '../../../components/autocomplete-input';
import { useFilter } from '../../../components/filter/filter-provider';
import { LOCATION_SEARCH } from '../task-order.gql';

const VendorNameFilter = () => {
  const { filterState, onFilterItemChange } = useFilter();
  const [options, setOptions] = useState([]);

  const vendorTypeConverter = (vt) => {
    if (
      vt.find(
        (v) => v.vendorTypeCode === 'SA' || v.vendorTypeCode === 'Auction',
      )
    )
      return 'AHO';
    return 'MSI';
  };

  const [fetchOptions] = useLazyQuery(LOCATION_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted: ({ vendorLocationSearch }) => {
      let filteredOptions = ['Error: 404'];

      if (vendorLocationSearch?.length) {
        filteredOptions = vendorLocationSearch.map((o) => ({
          key: o.id,
          label: `${o.name} - (${vendorTypeConverter(o.vendorTypes)})`,
          ...o,
        }));
      }
      setOptions(filteredOptions);
    },
  });

  const getOptions = (searchQuery) => {
    fetchOptions({
      variables: {
        limit: 25,
        nameQuery: searchQuery,
        subFilters: {
          operator: '$and',
          conditions: [
            {
              operator: '$in',
              key: 'vendor_type_code',
              value: ['SA', 'Auction', 'Marshalling', 'MAR'],
            },
          ],
        },
      },
    });
  };

  return (
    <AutoCompleteInput
      id="vendor-name-filter"
      data-testid="vendor-name-filter"
      value={filterState?.vendorLocation}
      options={options}
      onSelect={(selected) => {
        onFilterItemChange({ id: 'vendorLocation', value: selected });
      }}
      onFilter={(keyword) => {
        getOptions(keyword);
      }}
      inputProps={{
        placeholder: 'Vendor Name',
      }}
    />
  );
};

export default VendorNameFilter;
