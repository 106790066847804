import { DatePicker, Label } from '@gsa/afp-component-library';
import { ErrorMessage } from '@hookform/error-message';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTaskOrder } from '../task-order-provider';

const DateComponent = () => {
  const { setTaskOrderError, setTaskOrderMode, setTaskOrderSelected } =
    useTaskOrder();

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (setTaskOrderSelected?.startDate) {
      setValue('startDate', `${setTaskOrderSelected.startDate}`);
    }
    if (setTaskOrderSelected?.endDate) {
      setValue('endDate', `${setTaskOrderSelected.endDate}`);
    }
  }, [setTaskOrderSelected?.startDate, setTaskOrderSelected?.endDate]);

  return (
    <>
      <div className="display-flex flex-row">
        <div className="margin-right-8">
          {setTaskOrderMode === 'ADD_TASK_ORDER' ||
          setTaskOrderMode === 'DUPLICATE_TASK_ORDER' ? (
            <Label required className="text-bold">
              StartDate
            </Label>
          ) : (
            <Label className="text-bold">Start Date</Label>
          )}

          <div className="usa-hint" id="start-date-hint">
            mm/dd/yyyy
          </div>

          {(setTaskOrderMode === 'UPDATE_TASK_ORDER' ||
            setTaskOrderMode === 'VIEW_TASK_ORDER') && (
            <div className="text-base-darkest margin-y-2">
              {DateTime.fromISO(setTaskOrderSelected?.startDate).toFormat(
                'LL/dd/yyyy',
              )}
            </div>
          )}

          {(setTaskOrderMode === 'ADD_TASK_ORDER' ||
            setTaskOrderMode === 'DUPLICATE_TASK_ORDER') && (
            <Controller
              name="startDate"
              control={control}
              rules={{ required: 'Start Date is required' }}
              render={({ field: { ref, ...fieldProps } }) => (
                <DatePicker
                  id="task-order-start-date"
                  defaultValue={
                    DateTime.fromISO(setTaskOrderSelected?.startDate).toFormat(
                      'yyyy-LL-dd',
                    ) ?? ''
                  }
                  name="startDate"
                  placeholder="Enter Start Date"
                  data-testid="task-order-start-date"
                  {...fieldProps}
                  readOnly
                  onChange={(date) => {
                    fieldProps.onChange(date);
                  }}
                />
              )}
            />
          )}

          <div className="margin-y-1 text-bold text-secondary">
            <ErrorMessage errors={errors} name="startDate" />
          </div>
        </div>
        <div>
          {setTaskOrderMode === 'ADD_TASK_ORDER' ||
          setTaskOrderMode === 'DUPLICATE_TASK_ORDER' ? (
            <Label required className="text-bold">
              EndDate
            </Label>
          ) : (
            <Label className="text-bold">End Date</Label>
          )}

          <div className="usa-hint" id="end-date-hint">
            mm/dd/yyyy
          </div>

          {(setTaskOrderMode === 'UPDATE_TASK_ORDER' ||
            setTaskOrderMode === 'VIEW_TASK_ORDER') && (
            <div className="text-base-darkest margin-y-2">
              {DateTime.fromISO(setTaskOrderSelected?.endDate).toFormat(
                'LL/dd/yyyy',
              )}
            </div>
          )}

          {(setTaskOrderMode === 'ADD_TASK_ORDER' ||
            setTaskOrderMode === 'DUPLICATE_TASK_ORDER') && (
            <Controller
              name="endDate"
              control={control}
              rules={{ required: 'End Date is required' }}
              render={({ field: { ref, ...fieldProps } }) => (
                <DatePicker
                  id="task-order-end-date"
                  name="task-order-end-date"
                  data-testid="task-order-start-date"
                  placeholder="Enter End Date"
                  defaultValue={
                    DateTime.fromISO(setTaskOrderSelected?.endDate).toFormat(
                      'yyyy-LL-dd',
                    ) ?? ''
                  }
                  {...fieldProps}
                  readOnly
                  onChange={(date) => {
                    fieldProps.onChange(date);
                  }}
                />
              )}
            />
          )}

          <div className="margin-y-1 text-bold text-secondary">
            <ErrorMessage errors={errors} name="endDate" />
          </div>
        </div>
      </div>
      {setTaskOrderError === 'date_validation_error' && (
        <div
          data-testid="create-task-order-validation-error"
          className="usa-error-message"
        >
          The End Date is before the Start Date. Please enter a valid date.
        </div>
      )}
    </>
  );
};

export default DateComponent;
