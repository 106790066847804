import { Button, Icon } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import FilterProvider, { useFilter } from './filter-provider';
import './filter.scss';

const FilterTitle = ({ title }) => {
  const { reset, filterState } = useFilter();
  return (
    <div className="grid-row flex-row">
      <div className="grid-col display-flex flex-column flex-align-start flex-align-self-center text-uppercase text-bold ">
        {title}
      </div>
      <div className="grid-col display-flex flex-column flex-align-end flex-align-self-center">
        {Object.values(filterState).some((f) => f) && (
          <Button
            data-testid="reset-filter"
            type="button"
            variant="unstyled"
            className="width-auto"
            onClick={reset}
          >
            <Icon
              iconName="close"
              className="margin-right-1 text-middle text-gray-50"
            />
            Reset All
          </Button>
        )}
      </div>
    </div>
  );
};

FilterTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

const Filter = ({ title = 'filters', children, ...props }) => {
  return (
    <FilterProvider {...props}>
      <div data-testid="afp-filter" className="grid-row margin-top-2">
        <div className="grid-col">
          <FilterTitle title={title} />

          <div className="grid-row padding-y-2 ">
            <div className="grid-col">{children}</div>
          </div>
        </div>
      </div>
    </FilterProvider>
  );
};

Filter.defaultProps = {
  title: 'Filters',
};

Filter.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Filter;
