import { useLazyQuery } from '@apollo/client';
import { Label, Typeahead } from '@gsa/afp-component-library';
import { ErrorMessage } from '@hookform/error-message';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSalesDisposal } from '../sales-disposal-provider';
import { LOCATION_SEARCH } from '../sales-disposal.gql';

const SellingAgentSearchField = () => {
  const userTypedRef = useRef(false);
  const mounted = createRef(true);
  const [options, setOptions] = useState({ values: [], raw: [] });

  const {
    salesDisposalModalShow,
    salesDisposalSelected: { association },
  } = useSalesDisposal();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [fetchOptions] = useLazyQuery(LOCATION_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted: ({ locationSearch }) => {
      let result = { values: ['Error: 404'], raw: [] };

      if (locationSearch?.length) {
        result = {
          values: locationSearch.map((location) => location.name),
          raw: locationSearch,
        };
      }
      setOptions(result);
    },
  });

  const getOptions = (searchQuery) => {
    fetchOptions({
      variables: {
        limit: 25,
        typeCodes: ['AHO'],
        nameQuery: searchQuery,
      },
    });
  };

  useEffect(() => {
    // Fetch location during edit only fi modal is open.
    if (salesDisposalModalShow && association?.sellingLocation?.name) {
      // mount.current warning => prevents can't perform a react state update on an unmounted component
      if (mounted.current) getOptions(association.sellingLocation.name);
    }

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <div className="margin-y-4">
      <Label required className="text-bold">
        Search selling Agent
      </Label>

      <div className="text-base-darkest margin-y-2">
        {association?.sellingLocation?.name}
      </div>

      <Controller
        name="sellingLocation"
        control={control}
        defaultValue={association?.sellingLocation}
        rules={{ required: 'Auction house is required' }}
        render={({ field: { ref, ...fieldProps } }) => {
          return (
            <Typeahead
              {...fieldProps}
              data-testid="selling-location"
              filterValue=""
              typeaheadValues={options?.values}
              onFilterKeyDown={() => {
                userTypedRef.current = true;
              }}
              onOptionEnter={(s) => {
                const selectedData = options?.raw.find((o) => o.name === s);

                userTypedRef.current = false;

                if (selectedData?.id) {
                  fieldProps.onChange(selectedData);
                }
              }}
              fetchTypeaheadValues={(_, search) => {
                const keyword = search.trim();

                if (userTypedRef.current) getOptions(keyword);
                userTypedRef.current = false;
              }}
              debounceDelay={500}
              inputCharNum={3}
            />
          );
        }}
      />

      <div className="margin-y-1 text-bold text-secondary">
        <ErrorMessage errors={errors} name="sellingLocation" />
      </div>
    </div>
  );
};

export default SellingAgentSearchField;
