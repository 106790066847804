import { useFormContext, Controller } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { SelectDropdown, Textbox, Label } from '@gsa/afp-component-library';
import { useTaskOrder } from '../task-order-provider';

const PRICING_OPTIONS = [
  { label: '- Select -', value: '', showPricing: false },
  { value: 'Offered', label: 'Offered', showPricing: true },
  {
    value: 'Not offered',
    label: 'Not offered',
    showPricing: false,
  },
  { value: 'Neg @ Order', label: 'Neg @ Order', showPricing: false },
  { value: 'Cost + $', label: 'Cost + $', showPricing: true },
  { value: 'Cost + %', label: 'Cost + %', showPricing: true },
];

const AddContractLineItemForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [pricingOption, setPricingOption] = useState(true);

  const {
    setContractLineItemModalMode,
    contractLineItemModalShow,
    setContractLineItemRowData,
  } = useTaskOrder();

  useEffect(() => {
    if (
      setContractLineItemRowData.pricingOption === 'Not offered' ||
      setContractLineItemRowData.pricingOption === 'Neg @ Order'
    ) {
      setPricingOption(false);
    }
  }, []);

  return (
    <>
      {setContractLineItemModalMode === 'EDIT_CONTRACT_LINE_ITEM' &&
        contractLineItemModalShow && (
          <div>
            <Label className="text-bold">CLIN ID</Label>
            <Controller
              name="contractLineItemId"
              control={control}
              rules={{ required: 'CLIN ID is required' }}
              defaultValue={setContractLineItemRowData.contractLineItemId}
              render={({ field: { ref, ...fieldProps } }) => (
                <Textbox
                  data-testid="contract-line-item-id"
                  placeholder="Enter CLIN ID"
                  {...fieldProps}
                />
              )}
            />
            <div className="margin-y-2 text-bold text-secondary">
              <ErrorMessage errors={errors} name="contractLineItemId" />
            </div>

            <Label className="text-bold">CLIN Type</Label>
            <Controller
              name="contractLineItemType"
              control={control}
              rules={{ required: 'CLIN Type is required' }}
              defaultValue={setContractLineItemRowData.contractLineItemType}
              render={({ field: { ref, ...fieldProps } }) => (
                <Textbox
                  data-testid="contract-line-item-type"
                  placeholder="Enter CLIN Type"
                  {...fieldProps}
                />
              )}
            />
            <div className="margin-y-2 text-bold text-secondary">
              <ErrorMessage errors={errors} name="contractLineItemType" />
            </div>

            <Label className="text-bold">Pricing Options</Label>
            <Controller
              name="pricingOption"
              control={control}
              rules={{ required: 'Pricing Option is required' }}
              defaultValue={setContractLineItemRowData.pricingOption ?? ''}
              render={({ field: { ref, ...fieldProps } }) => (
                <SelectDropdown
                  {...fieldProps}
                  data-testid="pricing-option"
                  options={PRICING_OPTIONS}
                  onChange={(e) => {
                    setPricingOption(e.target.value);
                    if (
                      e.target.value === 'Not offered' ||
                      e.target.value === 'Neg @ Order'
                    ) {
                      setPricingOption(false);
                    }
                    fieldProps.onChange(e);
                  }}
                />
              )}
            />
            <div className="margin-y-2 text-bold text-secondary">
              <ErrorMessage errors={errors} name="pricingOption" />
            </div>

            {pricingOption && (
              <>
                <Label className="text-bold">Price</Label>
                <Controller
                  name="price"
                  control={control}
                  rules={{ required: 'Price is required' }}
                  defaultValue={setContractLineItemRowData.price ?? ''}
                  render={({ field: { ref, ...fieldProps } }) => (
                    <div className="price-input-group">
                      <div className="price-input-prefix" aria-hidden="true">
                        $
                      </div>
                      <Textbox
                        className="price-input text-right"
                        data-testid="contract-line-item-price"
                        placeholder="Enter Price"
                        {...fieldProps}
                      />
                    </div>
                  )}
                />
              </>
            )}

            <div className="margin-y-2 text-bold text-secondary">
              <ErrorMessage errors={errors} name="price" />
            </div>
          </div>
        )}
    </>
  );
};

export default AddContractLineItemForm;
