import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useVehicleSales } from '../vehicle-sales-provider';

const VehicleSalesRemoveVehicle = () => {
  const { vehicleSalesSelected, removeVehicleFromSale } = useVehicleSales();
  const methods = useForm();
  const onSubmit = () => {
    removeVehicleFromSale({
      variables: {
        vin: vehicleSalesSelected?.vin,
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="vehicle-sales-remove-vehicle-form"
        id="vehicle-sales-remove-vehicle-form"
        className="usa-form usa-form--large"
        onSubmit={methods.handleSubmit(onSubmit)}
      />
      <div className="display-flex flex-row margin-top-1">
        <div>
          You are about to remove vehicle from Sale. Are you sure you want to
          remove
          <strong>
            {` ${vehicleSalesSelected?.vehicleListing?.modelYear || ''} ${
              vehicleSalesSelected?.vehicleListing?.makeName || ''
            } ${vehicleSalesSelected?.vehicleListing?.modelName || ''} `}
          </strong>
          from Sale?
        </div>
      </div>
    </FormProvider>
  );
};

export default VehicleSalesRemoveVehicle;
