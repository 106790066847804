import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import { currencyFormat } from '../../utils/formatting';
import {
  DELETE_VEHICLE_SALE,
  GET_VEHICLE_SALE_LIST,
  UPDATE_RUN_ORDER,
  UPDATE_VEHICLE_SALE_DESIRED_PRICE,
} from './vehicle-sales.gql';

export const VehicleSalesContext = createContext();

export const VEHICLE_SALES_MODAL_MODES = {
  UPDATE_DESIRED_PRICE: 'UPDATE_DESIRED_PRICE',
  UPDATE_RUN_ORDER_NUMBER: 'UPDATE_RUN_ORDER_NUMBER',
  REMOVE_VEHICLE: 'REMOVE_VEHICLE',
};

const initialState = {
  vehicleSalesList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  vehicleSalesSelected: null,
  vehicleSalesModalMode: null,
  vehicleSalesModalShow: false,
  vehicleSalesMessage: { type: '', message: '' }, // type => error || success || info e.g.: => {type: 'error', 'Association already exists.'}
  vehicleSalesError: null,
  VEHICLE_SALES_MODAL_MODES,
  vehicleSalesListFilters: {
    disposalConditionFilter: [],
    makeFilter: [],
    modelFilter: [],
    yearFilter: [],
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_VEHICLE_SALES_LIST':
      return { ...state, vehicleSalesList: action.payload };

    case 'SET_SELECTED':
      return { ...state, vehicleSalesSelected: action.payload };

    case 'SET_MODAL_MODE': {
      return { ...state, vehicleSalesModalMode: action.payload };
    }

    case 'SET_SHOW_MODAL': {
      return { ...state, vehicleSalesModalShow: action.payload };
    }

    case 'SET_MESSAGE':
      return { ...state, vehicleSalesMessage: action.payload };

    case 'SET_ERROR': {
      return { ...state, vehicleSalesError: action.payload };
    }

    case 'SET_VEHICLE_SALES_LIST_FILTERS':
      return {
        ...state,
        vehicleSalesListFilters: action.payload,
      };

    default:
      throw new Error('Invalid action');
  }
};

function VehicleSalesProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const SetVehicleSalesError = (type, payload) => {
    dispatch({
      type: 'SET_ERROR',
      payload: { ...state.vehicleSalesError, [type]: payload },
    });
  };

  const setVehicleSalesData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const resetModal = () => {
    setVehicleSalesData('SET_SHOW_MODAL', false);
    setVehicleSalesData('SET_MODAL_MODE', null);
    setVehicleSalesData('SET_SELECTED', null);
  };

  const setRequestError = (requestError) => {
    SetVehicleSalesError('SET_ERROR', requestError.message);
    setVehicleSalesData('SET_MESSAGE', {
      type: 'error',
      message: `${requestError.message} `,
    });
  };

  // Get vehicle list lazy.
  const [getVehicleSaleList, { refetch, loading: vehicleSaleListLoading }] =
    useLazyQuery(GET_VEHICLE_SALE_LIST, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: setRequestError,
      onCompleted: (responseData) => {
        if (responseData?.getVehicleSaleList) {
          setVehicleSalesData(
            'SET_VEHICLE_SALES_LIST',
            responseData.getVehicleSaleList,
          );
          SetVehicleSalesError('SET_VEHICLE_SALES_LIST', '');
        }
      },
    });

  // Update vehicle desired price.
  const [updateVehicleSaleDesiredPrice] = useMutation(
    UPDATE_VEHICLE_SALE_DESIRED_PRICE,
    {
      onError: setRequestError,
      onCompleted: ({ updateVehicleSaleDesiredPrice: response }) => {
        setVehicleSalesData('SET_SHOW_MODAL', false);

        const vin = response?.vin;
        const makeName = response?.vehicleListing?.makeName;
        const modelName = response?.vehicleListing?.modelName;
        const year = response?.vehicleListing?.modelYear;

        const successMessage = `<strong>${year} ${makeName} ${modelName} ${vin}</strong> desired price is successfully updated to <strong>${currencyFormat(
          response.desiredPrice,
        )}</strong>`;

        setVehicleSalesData('SET_MESSAGE', {
          type: 'success',
          message: successMessage,
        });

        refetch({
          variables: {
            limit: 10,
            offset: 0,
            order: 'runNumber DESC',
          },
        });
      },
    },
  );

  // Remove vehicle from sale.
  const [removeVehicleFromSale] = useMutation(DELETE_VEHICLE_SALE, {
    onError: setRequestError,
    onCompleted: ({ deleteVehicleSale: response }) => {
      setVehicleSalesData('SET_SHOW_MODAL', false);

      const vin = response?.vehicleListing?.vin;
      const makeName = response?.vehicleListing?.makeName;
      const modelName = response?.vehicleListing?.modelName;
      const year = response?.vehicleListing?.modelYear;

      const successMessage = `<strong>${year} ${makeName} ${modelName} ${vin}</strong> has been removed from sale.`;

      setVehicleSalesData('SET_MESSAGE', {
        type: 'success',
        message: successMessage,
      });

      refetch({
        variables: {
          limit: 10,
          offset: 0,
          order: 'runNumber DESC',
        },
      });
    },
  });

  // Update run order number.
  const [updateRunOrder] = useMutation(UPDATE_RUN_ORDER, {
    onError: setRequestError,
    onCompleted: ({ updateRunOrder: response }) => {
      setVehicleSalesData('SET_SHOW_MODAL', false);

      const vin = response?.vin;
      const makeName = response?.vehicleListing?.makeName;
      const modelName = response?.vehicleListing?.modelName;
      const year = response?.vehicleListing?.modelYear;

      const successMessage = `<strong>${year} ${makeName} ${modelName} ${vin}</strong> run order number is successfully updated to <strong>${response.runNumber}</strong>`;

      setVehicleSalesData('SET_MESSAGE', {
        type: 'success',
        message: successMessage,
      });

      refetch({
        variables: {
          limit: 10,
          offset: 0,
          order: 'runNumber DESC',
        },
      });
    },
  });

  return (
    <VehicleSalesContext.Provider
      value={{
        ...state,
        getVehicleSaleList,
        setVehicleSalesData,
        SetVehicleSalesError,
        updateVehicleSaleDesiredPrice,
        updateRunOrder,
        removeVehicleFromSale,
        vehicleSaleListLoading,
        resetModal,
        ...props,
      }}
    >
      {children}
    </VehicleSalesContext.Provider>
  );
}

export default VehicleSalesProvider;

VehicleSalesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useVehicleSales = () => useContext(VehicleSalesContext);
