import { DatePicker, Label, TimePicker } from '@gsa/afp-component-library';
import { ErrorMessage } from '@hookform/error-message';
import { DateTime } from 'luxon';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getLocalTimeZoneIndicator } from '../../../utils/formatting';
import { useSalesListing } from '../sales-listing-provider';

const SaleInspectionDate = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const dateNow = DateTime.now().toFormat('yyyy-LL-dd');

  const { salesListingSelected } = useSalesListing();
  const formatInspectionDate = (dateType) => {
    return DateTime.fromISO(salesListingSelected?.[dateType]).toFormat(
      'yyyy-LL-dd',
    );
  };
  const formatInspectionTime = (dateType) => {
    return DateTime.fromISO(salesListingSelected?.[dateType]).toLocaleString(
      DateTime.TIME_24_SIMPLE,
    );
  };

  return (
    <>
      <div className="display-flex flex-column">
        <div className="display-flex flex-row">
          <div data-testid="inspection-date-1" className="margin-right-2">
            <Label required className="text-bold">
              Inspection Date 1
            </Label>
            <div className="usa-hint" id="inspection-date-1-hint">
              mm/dd/yyyy
            </div>
            <Controller
              name="inspectionDate1"
              control={control}
              rules={{ required: 'Inspection date is required' }}
              render={({ field: { ref, ...fieldProps } }) => (
                <DatePicker
                  id="inspection-date-1"
                  name="inspection-date-1"
                  data-testid="inspection-date-1"
                  defaultValue={formatInspectionDate('inspectionStartDate1')}
                  minDate={dateNow}
                  {...fieldProps}
                  readOnly
                  onChange={(date) => {
                    fieldProps.onChange(date);
                  }}
                />
              )}
            />
            <div className="margin-y-1 text-bold text-secondary">
              <ErrorMessage errors={errors} name="inspectionDate1" />
            </div>
          </div>
          <div data-testid="inspection-start-time-1" className="margin-right-4">
            <Label required className="text-bold">
              Start Time
            </Label>
            <div className="usa-hint" id="inspection-start-time-1-hint">
              hh:mm&nbsp;{getLocalTimeZoneIndicator()}
            </div>

            <Controller
              name="inspectionStartTime1"
              control={control}
              rules={{ required: 'Inspection start time is required' }}
              render={({ field: { ref, ...fieldProps } }) => (
                <TimePicker
                  className="margin-top-neg-2"
                  id="inspection-start-time-1"
                  name="inspection-start-time-1"
                  minTime="03:00"
                  maxTime="22:00"
                  defaultValue={formatInspectionTime('inspectionStartDate1')}
                  {...fieldProps}
                  onChange={(date) => {
                    fieldProps.onChange(date);
                  }}
                />
              )}
            />

            <div className="margin-y-1 text-bold text-secondary">
              <ErrorMessage errors={errors} name="inspectionStartTime1" />
            </div>
          </div>
          <div data-testid="inspection-end-time-1">
            <Label required className="text-bold">
              End Time
            </Label>
            <div className="usa-hint" id="inspection-end-time-1-hint">
              hh:mm&nbsp;{getLocalTimeZoneIndicator()}
            </div>

            <Controller
              name="inspectionEndTime1"
              control={control}
              rules={{ required: 'Inspection end time is required' }}
              render={({ field: { ref, ...fieldProps } }) => (
                <TimePicker
                  className="margin-top-neg-2"
                  id="inspection-end-time-1"
                  name="inspection-end-time-1"
                  minTime="03:00"
                  maxTime="22:00"
                  defaultValue={formatInspectionTime('inspectionFinishDate1')}
                  {...fieldProps}
                  onChange={(date) => {
                    fieldProps.onChange(date);
                  }}
                />
              )}
            />

            <div className="margin-y-1 text-bold text-secondary">
              <ErrorMessage errors={errors} name="inspectionEndTime1" />
            </div>
          </div>
        </div>
        <div className="display-flex flex-row">
          <div data-testid="inspection-date-2" className="margin-right-2">
            <Label className="text-bold">Inspection Date 2</Label>
            <div className="usa-hint" id="inspection-date-2-hint">
              mm/dd/yyyy
            </div>
            <Controller
              name="inspectionDate2"
              control={control}
              render={({ field: { ref, ...fieldProps } }) => (
                <DatePicker
                  id="inspection-date-2"
                  name="inspection-date-2"
                  data-testid="inspection-date-2"
                  defaultValue={formatInspectionDate('inspectionStartDate2')}
                  minDate={dateNow}
                  {...fieldProps}
                  readOnly
                  onChange={(date) => {
                    fieldProps.onChange(date);
                  }}
                />
              )}
            />
            <div className="margin-y-1 text-bold text-secondary">
              <ErrorMessage errors={errors} name="inspectionDate2" />
            </div>
          </div>
          <div data-testid="inspection-start-time-2" className="margin-right-4">
            <Label className="text-bold">Start Time</Label>
            <div className="usa-hint" id="inspection-start-time-2-hint">
              hh:mm&nbsp;{getLocalTimeZoneIndicator()}
            </div>

            <Controller
              name="inspectionStartTime2"
              control={control}
              render={({ field: { ref, ...fieldProps } }) => (
                <TimePicker
                  className="margin-top-neg-2"
                  id="inspection-start-time-2"
                  name="inspection-start-time-2"
                  minTime="03:00"
                  maxTime="22:00"
                  defaultValue={formatInspectionTime('inspectionStartDate2')}
                  {...fieldProps}
                  onChange={(date) => {
                    fieldProps.onChange(date);
                  }}
                />
              )}
            />

            <div className="margin-y-1 text-bold text-secondary">
              <ErrorMessage errors={errors} name="inspectionStartTime2" />
            </div>
          </div>
          <div data-testid="inspection-end-time-2">
            <Label className="text-bold">End Time</Label>
            <div className="usa-hint" id="inspection-end-time-w-hint">
              hh:mm&nbsp;{getLocalTimeZoneIndicator()}
            </div>

            <Controller
              name="inspectionEndTime2"
              control={control}
              render={({ field: { ref, ...fieldProps } }) => (
                <TimePicker
                  className="margin-top-neg-2"
                  id="inspection-end-time-2"
                  name="inspection-end-time-2"
                  minTime="03:00"
                  maxTime="22:00"
                  defaultValue={formatInspectionTime('inspectionFinishDate2')}
                  {...fieldProps}
                  onChange={(date) => {
                    fieldProps.onChange(date);
                  }}
                />
              )}
            />

            <div className="margin-y-1 text-bold text-secondary">
              <ErrorMessage errors={errors} name="inspectionEndTime2" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SaleInspectionDate;
