import React from 'react';
import BannerMessage from '../../components/banner-message';
import { useMarshallingSchedule } from './marshalling-schedule-provider';

const MarshallingScheduleAlert = () => {
  const {
    marshallingScheduleMessage: { type, message },
    setMarshallingScheduleData,
  } = useMarshallingSchedule();

  if (!type || !message) return null;

  return (
    <BannerMessage
      type={type}
      message={message}
      onClose={() => {
        setMarshallingScheduleData('SET_MESSAGE', {
          type: 'success',
          message: '',
        });
      }}
    />
  );
};

export default MarshallingScheduleAlert;
