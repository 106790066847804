import React from 'react';

const MarshallingInformation = () => {
  return (
    <div className="marshalling-information">
      <div className="display-flex flex-row flex-justify">
        <div className="text-bold">Location:</div>
        <div>
          <div className="text-right">Insurance auto action</div>
          <div className="text-right">87 Randolph Ave</div>
          <div className="text-right">Avenel, NJ 07001</div>
        </div>
      </div>
      <hr />
      <div className="display-flex flex-row flex-justify ">
        <div className="text-bold">Phone:</div>
        <div className="text-right">503-298-4300</div>
      </div>
      <hr />
    </div>
  );
};

export default MarshallingInformation;
