import { Button, SelectDropdown, TextInput } from '@gsa/afp-component-library';
import React, { useState } from 'react';
import { useTaskOrder } from './task-order-provider';

const PRICING_OPTIONS = [
  { label: '- Select -', value: '', showPricing: false },
  { value: 'Offered', label: 'Offered', showPricing: true },
  {
    value: 'Not offered',
    label: 'Not offered',
    showPricing: false,
  },
  { value: 'Neg @ Order', label: 'Neg @ Order', showPricing: false },
  { value: 'Cost + $', label: 'Cost + $', showPricing: true },
  { value: 'Cost + %', label: 'Cost + %', showPricing: true },
];

const initialState = {
  contractLineItemId: '',
  contractLineItemType: '',
  pricingOption: '',
  price: null,
  showPricing: false,
  error: '',
};

const AddContractLineItem = () => {
  const [state, setState] = useState(initialState);

  const { clinData, setTaskOrderData, setTaskOrderError } = useTaskOrder();

  const setError = (error) => {
    setState((prev) => ({
      ...prev,
      error,
    }));
  };

  const isValid = () => {
    const {
      contractLineItemId,
      contractLineItemType,
      pricingOption,
      showPricing,
      price,
    } = state;

    const found = clinData?.find(
      (d) => d?.contractLineItemId === contractLineItemId,
    );

    if (found) {
      setError('Duplicate Contract Line Item');
      return false;
    }

    if (!contractLineItemId.length) {
      setError('CLIN ID is required');
      return false;
    }
    if (!contractLineItemType.length) {
      setError('CLIN Type is required');
      return false;
    }
    if (!pricingOption.length) {
      setError('Pricing option is required');
      return false;
    }

    if (showPricing && !price) {
      setError(`Price amount is required for Option: ${pricingOption}`);
      return false;
    }

    return true;
  };

  const handleAddClinItem = () => {
    const { showPricing, error, ...newClinRow } = state;

    if (isValid()) {
      setTaskOrderData('SET_CLIN_DATA', newClinRow);
      setState(initialState);
    }
  };

  const handleOnchange = (id, value) => {
    let showPricing = state?.showPricing;

    if (id === 'pricingOption')
      showPricing = PRICING_OPTIONS.find((o) => o.value === value).showPricing;

    setState((prev) => ({
      ...prev,
      [id]: value,
      showPricing,
    }));
  };

  return (
    <div
      data-testid="add-contract-line-item"
      className="grid-col margin-top-neg-3"
    >
      <div className="grid-row bg-base-lightest border border-base-lighter padding-bottom-1">
        <div className="grid-col-2 margin-right-2 margin-left-1">
          <TextInput
            data-testid="contract-line-item-id"
            id="contractLineItemId"
            name="contractLineItemId"
            value={state.contractLineItemId}
            placeholder="Enter CLIN ID"
            onChange={(e) => handleOnchange(e.target.id, e.target.value)}
          />
        </div>

        <div className="grid-col-2 margin-right-2">
          <TextInput
            data-testid="contract-line-item-type"
            id="contractLineItemType"
            name="contractLineItemType"
            value={state.contractLineItemType}
            placeholder="Enter CLIN Type"
            onChange={(e) => handleOnchange(e.target.id, e.target.value)}
          />
        </div>

        <div className="grid-col-3 margin-right-2">
          <SelectDropdown
            data-testid="pricing-option"
            options={PRICING_OPTIONS}
            id="pricingOption"
            name="pricingOption"
            value={state.pricingOption}
            onChange={(e) => handleOnchange(e.target.id, e.target.value)}
          />
        </div>

        <div className="grid-col-3 margin-right-2">
          {state.showPricing ? (
            <div className="price-input-group">
              <div className="price-input-prefix" aria-hidden="true">
                $
              </div>
              <TextInput
                className="price-input text-right"
                data-testid="price"
                id="price"
                name="price"
                value={state.price ?? ''}
                type="number"
                onChange={(e) => {
                  handleOnchange(e.target.id, parseFloat(e.target.value));
                }}
              />
            </div>
          ) : null}
        </div>

        <div className="grid-col display-flex flex-justify-center margin-right-2 margin-top-1">
          <Button
            data-testid="add-contract-line-item-button"
            type="button"
            onClick={() => handleAddClinItem()}
          >
            Add
          </Button>
        </div>
      </div>

      <div data-testid="clin-error">
        {state.error ? (
          <div className="usa-error-message">{state.error}</div>
        ) : null}
        {setTaskOrderError === 'contract_line_item_table_error' ? (
          <div
            data-testid="contract_line_item_table_error"
            className="usa-error-message"
          >
            Please enter a valid contract line item.
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default AddContractLineItem;
