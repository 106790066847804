import { useQuery } from '@apollo/client';
import { Label } from '@gsa/afp-component-library';
import { ErrorMessage } from '@hookform/error-message';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MARKETPLACE_LOOKUP } from '../../sales-disposal/sales-disposal.gql';
import { useTaskOrder } from '../task-order-provider';

const SelectVendorType = () => {
  const {
    lookup: { vendorType },
    setNestedTaskOrderData,
    setTaskOrderSelected,
    setTaskOrderMode,
  } = useTaskOrder();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  useQuery(MARKETPLACE_LOOKUP, {
    variables: {
      category: 'VendorType',
    },
    onCompleted: ({ getLookupItems }) => {
      if (getLookupItems.length)
        setNestedTaskOrderData('SET_LOOKUP_ITEM', 'vendorType', getLookupItems);
    },
  });

  return (
    <div className="margin-y-4">
      {setTaskOrderMode === 'ADD_TASK_ORDER' ||
      setTaskOrderMode === 'DUPLICATE_TASK_ORDER' ? (
        <Label required className="text-bold">
          Vendor Type
        </Label>
      ) : (
        <Label className="text-bold">Vendor Type</Label>
      )}

      {setTaskOrderMode !== 'ADD_TASK_ORDER' && (
        <div className="text-base-darkest margin-y-2">
          {setTaskOrderSelected?.contractor?.vendorType?.title}
        </div>
      )}

      {setTaskOrderMode !== 'VIEW_TASK_ORDER' && (
        <Controller
          name="vendorType"
          control={control}
          rules={{ required: 'Vendor type is required' }}
          defaultValue={setTaskOrderSelected?.contractor?.vendorType ?? ''}
          render={({ field }) => (
            <>
              {vendorType.map((type) => {
                return (
                  <div key={type.code}>
                    <input
                      {...field}
                      data-testid={`${type.code}-radio`}
                      className="usa-radio__input"
                      id={type.code}
                      type="radio"
                      name={type?.code}
                      value={type?.code}
                      checked={type?.code === field?.value?.code}
                      onChange={(e) => {
                        const checked = vendorType.find(
                          (t) => t.code === e.target.value,
                        );
                        field.onChange(checked);
                      }}
                    />
                    <label
                      className="usa-radio__label"
                      htmlFor={`${type?.code}`}
                    >
                      {type?.title}
                    </label>
                  </div>
                );
              })}
            </>
          )}
        />
      )}
      <div className="margin-y-2 text-bold text-secondary">
        <ErrorMessage errors={errors} name="vendorType" />
      </div>
    </div>
  );
};

SelectVendorType.defaultProps = {
  control: undefined,
  formState: undefined,
};

SelectVendorType.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object,
  formState: PropTypes.shape({
    errors: PropTypes.shape({
      message: PropTypes.string,
    }),
  }),
};

export default SelectVendorType;
