import React from 'react';
import { Button, Icon } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const FilterItemClear = ({ value, onClear }) => {
  return (
    <Button
      data-testid={`${value?.toLowerCase()}`}
      variant="unstyled"
      className="width-auto margin-bottom-2"
      onClick={onClear}
    >
      {value}
      <Icon
        iconName="close"
        className="margin-left-1 text-middle text-gray-50"
      />
    </Button>
  );
};

FilterItemClear.defaultProps = {
  value: null,
  onClear: () => {
    // clear logic
  },
};
FilterItemClear.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(Object),
    PropTypes.array,
  ]),
  onClear: PropTypes.func,
};

export default FilterItemClear;
