import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import { Filter, FilterItem } from '../../../components/filter';
import { useSalesListing } from '../sales-listing-provider';
import { GET_SALE_LIST_FILTERS } from '../sales-listing.gql';
import SaleLocationFilter from './sale-location-filter';
import SaleDateFilter from './sale-date-filter';
import InspectionDateFilter from './inspection-date-filter';
import SaleNumberFilter from './sale-number-filter';
import SaleStatusFilter from './sale-status-filter';
import SaleTypeFilter from './sale-type-filter';

const SalesListingFilter = ({ onFilter }) => {
  const { setSalesListingData } = useSalesListing();

  useQuery(GET_SALE_LIST_FILTERS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setSalesListingData('SET_SALE_LIST_FILTERS', data.getSaleListFilters);
    },
  });

  return (
    <Filter onChange={onFilter}>
      <FilterItem
        id="location-filter"
        title="Sale Location"
        clearButtons={[
          {
            text: 'Clear sale location',
            id: 'saleLocation',
            clearValue: null,
          },
        ]}
        content={<SaleLocationFilter />}
      />
      <FilterItem
        id="sale-type-filter"
        title="Sale Type"
        clearButtons={[
          {
            text: 'Clear sale type',
            id: 'saleType',
            clearValue: null,
          },
        ]}
        content={<SaleTypeFilter />}
      />
      <FilterItem
        id="sale-status-filter"
        title="Sale Status"
        clearButtons={[
          {
            text: 'Clear sale status',
            id: 'saleStatus',
            clearValue: null,
          },
        ]}
        content={<SaleStatusFilter />}
      />
      <FilterItem
        id="sale-date-filter"
        title="Sale Date"
        clearButtons={[{ id: 'saleStartDate', clearValue: null }]}
        content={<SaleDateFilter />}
      />
      <FilterItem
        id="inspection-date-filter"
        title="Inspection Date"
        clearButtons={[{ id: 'inspectionStartDate1', clearValue: null }]}
        content={<InspectionDateFilter />}
      />
      <FilterItem
        id="sale-number-filter"
        title="Sale Number"
        clearButtons={[{ id: 'saleNumber', clearValue: null }]}
        content={<SaleNumberFilter />}
      />
    </Filter>
  );
};

SalesListingFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

export default SalesListingFilter;
