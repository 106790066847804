import { Button } from '@gsa/afp-component-library';
import React from 'react';
import Breadcrumbs from '../../widgets/breadcrumbs';
import WelcomeMessage from '../../widgets/welcome-message';
import MarshallingProvider from './marshalling-provider';
import MarshallingTable from './marshalling-table';

const Marshalling = () => {
  document.title = 'AFP - Marshalling Management';
  return (
    <div className="grid-col sales-disposal-page">
      <MarshallingProvider>
        <>
          <Breadcrumbs
            path={[
              {
                location: `${window.AFP_CONFIG.appURLs.home}/home`,
                label: 'Home',
              },
            ]}
            current="Marshalling Management"
          />
          <h1>Marshalling Management</h1>
          <WelcomeMessage message="This page is where a marshaller can view and take action on the vehicles under their care. From this page the marshaller can see the listing of vehicles destined for, or located at, their location. They can also access such actions as license plate reconciliation, vehicle acceptance, vehicle load, vehicle exchange management, and assignment/termination actions." />
          <Button
            data-testid="task-order-table-create-button"
            className="display-flex margin-left-auto margin-top-4"
            type="button"
          >
            Marshalling actions
          </Button>
          <MarshallingTable />
        </>
      </MarshallingProvider>
    </div>
  );
};

export default Marshalling;
