import { Label, TextInput } from '@gsa/afp-component-library';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useVehicleSales } from '../vehicle-sales-provider';

const RunNumberField = () => {
  const {
    vehicleSalesSelected: { runNumber },
  } = useVehicleSales();

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const wrapperClasses = classNames('grid-col-3 margin-y-2', {
    [`usa-form-group--error`]: !!errors.runNumber,
  });

  const formElementClasses = classNames('height-4', {
    [`usa-input--error`]: !!errors.runNumber,
  });

  return (
    <div className={wrapperClasses}>
      <Label className="text-bold">Edit Run Order Number</Label>

      <Controller
        name="runNumber"
        control={control}
        defaultValue={runNumber ?? ''}
        render={({ field: { ref, ...fieldProps } }) => (
          <TextInput
            {...fieldProps}
            data-testid="run-number"
            className={formElementClasses}
            type="number"
            onChange={(e) => {
              setValue('runNumber', parseInt(e.target.value, 10));
            }}
          />
        )}
      />

      <div className="margin-y-2 text-bold usa-error-message">
        <ErrorMessage errors={errors} name="runNumber" />
      </div>
    </div>
  );
};

export default RunNumberField;
