/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button } from '@gsa/afp-component-library';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import './autocomplete.scss';

const AutoCompleteInput = ({
  id,
  className,
  value,
  delay,
  options,
  onFilter,
  onSelect,
  inputProps,
  ...props
}) => {
  const [userTyped, setUserTyped] = useState(true);
  const [currentValue, setCurrentValue] = useState('');
  const [open, setOpen] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState('');

  useEffect(() => {
    if (options?.length) {
      let resultOptions = options;

      if (
        typeof options[0] === 'string' ||
        typeof options[0] === 'undefined' ||
        !options
      ) {
        setNotFound(true);
        setOpen(false);
        resultOptions = [];
      } else {
        setOpen(true);
      }
      setAutoCompleteOptions(resultOptions);
    }
  }, [options]);

  useEffect(() => {
    if (value.label !== currentValue) setCurrentValue(value.label ?? '');
  }, [value.label]);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Checking userTyped protects onChange from being fired on mount.
      // userTyped is set to true on input's onKeyDown
      if (currentValue?.length && userTyped && currentValue !== selectedLabel) {
        onFilter(currentValue?.trim());
      }
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, [currentValue, userTyped]);

  const classes = classnames(
    'usa-input',
    {
      'usa-input--error': notFound,
    },
    className,
  );

  const optionClassNames = classnames(
    'autocomplete-input-options',
    'position-absolute z-top bg-white border-1px margin-top-neg-1px width-mobile maxh-mobile overflow-auto',
  );

  return (
    <div className="autocomplete-input">
      <div className="grid-col">
        <input
          id={id}
          value={currentValue}
          className={classes}
          onFocus={() => {
            setNotFound(false);
            setCurrentValue('');
            setSelectedLabel('');
            if (autoCompleteOptions?.length) setOpen(true);
          }}
          onKeyDown={() => {
            setNotFound(false);
            setUserTyped(true);
          }}
          onChange={(e) => {
            setCurrentValue(e.target.value);
          }}
          {...inputProps}
          {...props}
        />
        <label htmlFor={id} className="afp-responsive-input">
          {inputProps.placeholder}
        </label>

        <div
          className={optionClassNames}
          style={{ display: open ? 'block' : 'none' }}
        >
          <ul
            data-testid={`${id}-options`}
            className="usa-list usa-list--unstyled"
          >
            {autoCompleteOptions.map((o) => (
              <li
                key={o.key}
                data-testid={`li-${o.key}`}
                className="border-bottom-1px border-base-lightest padding-1"
              >
                <Button
                  data-testid={`button-${o.key}`}
                  variant="unstyled"
                  className="text-no-underline text-base-dark width-full padding-1"
                  onClick={() => {
                    setCurrentValue('');
                    setOpen(false);
                    setSelectedLabel(o.label);
                    onSelect(o);
                  }}
                >
                  {o.label}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

AutoCompleteInput.defaultProps = {
  id: 'auto-complete-input',
  className: '',
  value: {},
  delay: 500,
  options: [],
  inputProps: {},
};

AutoCompleteInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.shape(Object), PropTypes.string]),
  delay: PropTypes.number,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onSelect: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  inputProps: PropTypes.shape(Object),
};

export default AutoCompleteInput;
