import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useVehicleSales } from '../vehicle-sales-provider';
import RunNumberField from './run-number-field';

const UpdateRunNumberForm = () => {
  const {
    vehicleSalesSelected: { vin, saleNumber },
    updateRunOrder,
  } = useVehicleSales();
  const methods = useForm();
  const onSubmit = ({ runNumber }) => {
    updateRunOrder({
      variables: {
        updateInput: {
          vin,
          runNumber: !runNumber || runNumber === '' ? 0 : runNumber,
          saleNumber,
        },
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="update-run-number-form"
        id="update-run-number-form"
        className="usa-form usa-form--large"
        onSubmit={methods.handleSubmit(onSubmit)}
      />
      <RunNumberField />
    </FormProvider>
  );
};

export default UpdateRunNumberForm;
