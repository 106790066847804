import { ComboBox } from '@gsa/afp-component-library';
import React from 'react';
import { useFilter } from '../../../components/filter/filter-provider';
import { useSalesDisposal } from '../sales-disposal-provider';

const YearFilter = () => {
  const {
    disposalListFilters: { yearFilter },
  } = useSalesDisposal();

  const { filterState, onFilterItemChange } = useFilter();

  return (
    // Key for the wrapper is important as the ComboBox doesn't refresh
    // on defaultValue and options change
    <div key={`${yearFilter.length}_${filterState?.modelYear}`}>
      <ComboBox
        id="year-filter"
        name="year-filter"
        options={yearFilter}
        defaultValue={filterState?.modelYear}
        onChange={(selected) => {
          onFilterItemChange({ id: 'modelYear', value: selected });
        }}
        inputProps={{ placeholder: 'Year' }}
      />
    </div>
  );
};

export default YearFilter;
