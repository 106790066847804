import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import React, { createContext, useContext, useReducer } from 'react';
import {
  ADD_TASK_ORDER,
  GET_TASK_ORDER_LIST,
  UPDATE_TASK_ORDER,
} from './task-order.gql';

export const TaskOrderContext = createContext();

export const TASK_ORDER_MODES = {
  ADD_TASK_ORDER: 'ADD_TASK_ORDER',
  UPDATE_TASK_ORDER: 'UPDATE_TASK_ORDER',
  DUPLICATE_TASK_ORDER: 'DUPLICATE_TASK_ORDER',
  VIEW_TASK_ORDER: 'VIEW_TASK_ORDER',
};

export const CONTRACT_LINE_ITEM_MODAL_MODES = {
  EDIT_CONTRACT_LINE_ITEM: 'EDIT_CONTRACT_LINE_ITEM',
};

const initialState = {
  clinData: [],
  taskOrderList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  setTaskOrderMessage: { type: '', message: '' },
  setTaskOrderError: null,
  setTaskOrderMode: '',
  setTaskOrderSelected: null,
  setContractLineItemModalMode: null,
  contractLineItemModalShow: false,
  setContractLineItemRowData: null,
  TASK_ORDER_MODES,
  CONTRACT_LINE_ITEM_MODAL_MODES,
  taskOrderFilters: {
    vendorTypeFilter: [],
    statusFilter: [],
  },
  lookup: {
    vendorType: [],
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TASK_ORDER_LIST':
      return { ...state, taskOrderList: action.payload };
    case 'SET_CLIN_DATA':
      return {
        ...state,
        clinData: [...state.clinData, action.payload],
        setTaskOrderError: null,
      };
    case 'UPDATE_CLIN_DATA':
      return {
        ...state,
        clinData: state.clinData?.filter(
          (c) =>
            c?.contractLineItemId.split(' ').join('').toLowerCase() !==
            action.payload?.original?.contractLineItemId
              .split(' ')
              .join('')
              .toLowerCase(),
        ),
      };
    case 'RESET_CLIN_DATA':
      return { ...state, clinData: action.payload };

    case 'SET_MESSAGE':
      return { ...state, setTaskOrderMessage: action.payload };
    case 'SET_ERROR': {
      return { ...state, setTaskOrderError: action.payload };
    }
    case 'SET_TASK_ORDER_MODE': {
      return { ...state, setTaskOrderMode: action.payload };
    }
    case 'SET_SELECTED': {
      let newClinData = [];
      if (!state?.taskOrderList?.rows.length) {
        newClinData = action.payload?.contractLineItem;
      } else if (state?.taskOrderList?.rows.length) {
        state?.taskOrderList?.rows?.forEach((t) => {
          if (action.payload?.taskOrderNumber === t?.taskOrderNumber) {
            newClinData = t?.contractLineItem;
          }
        });
      }

      return {
        ...state,
        setTaskOrderSelected: action.payload,
        clinData: newClinData,
      };
    }
    case 'SET_CONTRACT_LINE_ITEM_MODAL_MODE': {
      return { ...state, setContractLineItemModalMode: action.payload };
    }
    case 'SET_SHOW_MODAL': {
      return { ...state, contractLineItemModalShow: action.payload };
    }
    case 'SET_CONTRACT_LINE_ITEM_ROW_DATA': {
      return { ...state, setContractLineItemRowData: action.payload };
    }
    case 'DELETE_CONTRACT_LINE_ITEM_ROW_DATA': {
      const clinData = [...state.clinData];
      const newClinData = clinData.filter(
        (contractLineItem) =>
          contractLineItem.contractLineItemId !==
          action.payload.contractLineItemId,
      );
      return { ...state, clinData: newClinData };
    }
    case 'EDIT_CONTRACT_LINE_ITEM_ROW_DATA': {
      const clinData = [...state.clinData];
      let newClinData;
      const isContractlineItemExist = clinData?.find(
        (contractLineItem) =>
          contractLineItem.contractLineItemId ===
          action.payload.contractLineItemId,
      );

      if (!isContractlineItemExist) {
        newClinData = [...clinData, action.payload];
      } else {
        newClinData = clinData.map((contractLineItem) =>
          contractLineItem.contractLineItemId ===
          action.payload.contractLineItemId
            ? { ...contractLineItem, ...action.payload }
            : contractLineItem,
        );
      }

      return {
        ...state,
        clinData: newClinData,
      };
    }

    case 'SET_TASK_ORDER_FILTERS':
      return {
        ...state,
        taskOrderFilters: action.payload,
      };

    case 'SET_LOOKUP_ITEM':
      return {
        ...state,
        lookup: { [action.key]: action.payload },
      };

    default:
      throw new Error('Invalid action');
  }
};

function TaskOrderProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const setTaskOrderData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const resetModal = () => {
    setTaskOrderData('SET_CONTRACT_LINE_ITEM_MODAL_MODE', null);
    setTaskOrderData('SET_SHOW_MODAL', false);
  };

  const setRequestError = (requestError) => {
    setTaskOrderData('SET_MESSAGE', {
      type: 'error',
      message: `${requestError.message} `,
    });
  };

  const setNestedTaskOrderData = (type, key, payload) => {
    dispatch({
      type,
      key,
      payload,
    });
  };

  // Get task order list lazy.
  const [getTaskOrderList] = useLazyQuery(GET_TASK_ORDER_LIST, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: setRequestError,
    onCompleted: (responseData) => {
      if (responseData?.getTaskOrderList) {
        setTaskOrderData('SET_TASK_ORDER_LIST', responseData.getTaskOrderList);
      }
    },
  });

  // Add task order.
  const [addTaskOrder] = useMutation(ADD_TASK_ORDER, {
    onError: setRequestError,
    onCompleted: (responseData) => {
      if (responseData?.addTaskOrder) {
        setTaskOrderData('SET_MESSAGE', {
          type: 'success',
          message: `You have successfully created Task Order <strong>${responseData.addTaskOrder.taskOrderNumber}</strong> `,
        });
        setTaskOrderData('SET_ERROR', '');
      }

      // navigate to task order listing page on success
      history.push('/task-order');
    },
  });

  const [updateTaskOrder] = useMutation(UPDATE_TASK_ORDER, {
    onError: setRequestError,
    onCompleted: (responseData) => {
      if (responseData?.updateTaskOrder) {
        setTaskOrderData('SET_MESSAGE', {
          type: 'success',
          message: `You have successfully updated Task Order <strong>${responseData.updateTaskOrder.taskOrderNumber}</strong> `,
        });
        setTaskOrderData('SET_ERROR', '');
      }

      // navigate to task order listing page on success
      history.push('/task-order');
    },
  });

  return (
    <TaskOrderContext.Provider
      value={{
        ...state,
        addTaskOrder,
        getTaskOrderList,
        setTaskOrderData,
        setNestedTaskOrderData,
        updateTaskOrder,
        resetModal,
        setRequestError,
        ...props,
      }}
    >
      {children}
    </TaskOrderContext.Provider>
  );
}

export default TaskOrderProvider;

TaskOrderProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useTaskOrder = () => useContext(TaskOrderContext);
