import { Tag } from '@gsa/afp-component-library';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { DisposalConditionType } from '../sales-disposal-proptypes';

const COLORS = {
  4: '#A6CE39',
  7: '#73B3E7',
  X: '#07648D',
  S: '#D83933',
};

const TEXT_COLORS = {
  X: 'text-white',
  S: 'text-white',
  4: 'text-ink',
  7: 'text-ink',
};

const DisposalCondition = ({ disposalCondition }) => {
  const cName = `disposal-condition__tag text-center text-middle ${
    TEXT_COLORS[disposalCondition?.code]
  }`;
  return (
    <>
      {disposalCondition?.code ? (
        <Tag
          data-tip={disposalCondition.title}
          className={cName}
          background={COLORS[disposalCondition.code]}
        >
          {disposalCondition.code}
        </Tag>
      ) : (
        <>&ndash;</>
      )}
      <ReactTooltip effect="solid" />
    </>
  );
};
DisposalCondition.propTypes = DisposalConditionType;

export default DisposalCondition;
