import PropTypes from 'prop-types';
import React from 'react';
import { Filter, FilterItem } from '../../../components/filter';
import DisposalStatusFilter from './disposal-status-filter';
import ListingDateFilter from './listing-date-filter';
import LocationFilter from './location-filter';
import TagCountFilter from './tag-count-filter';
import VehicleFilter from './vehicle-filter';
import VinFilter from './vin-filter';
import YearFilter from './year-filter';

const SalesDisposalFilter = ({ onFilter }) => {
  return (
    <Filter onChange={onFilter}>
      <FilterItem
        id="vin-filter"
        title="VIN"
        clearButtons={[{ id: 'vin', clearValue: null }]}
        content={<VinFilter />}
      />

      <FilterItem
        id="vehicle-filter"
        title="Vehicle"
        content={<VehicleFilter />}
      />

      <FilterItem
        id="year-filter"
        title="Year"
        content={<YearFilter />}
        clearButtons={[{ id: 'year', clearValue: null }]}
      />

      <FilterItem
        id="listing-date-filter"
        title="Listing Date"
        clearButtons={[{ id: 'listingDate', clearValue: null }]}
        content={<ListingDateFilter />}
      />

      <FilterItem
        id="disposal-status-filter"
        title="Disposal Status"
        clearButtons={[
          {
            text: 'Clear disposal status',
            id: 'disposalStatus',
            clearValue: null,
          },
        ]}
        content={<DisposalStatusFilter />}
      />

      <FilterItem
        id="location-filter"
        title="Location Name"
        clearButtons={[
          {
            text: 'Clear location',
            id: 'currentLocation',
            clearValue: null,
          },
        ]}
        content={<LocationFilter />}
      />

      <FilterItem
        id="tab-count-filter"
        title="Number of Tags"
        clearButtons={[
          { text: 'Clear tag count', id: 'tagCount', clearValue: null },
        ]}
        content={<TagCountFilter />}
      />
    </Filter>
  );
};

SalesDisposalFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

export default SalesDisposalFilter;
