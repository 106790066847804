import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import AddContractLineItemForm from './add-contract-line-item-form';
import { useTaskOrder } from '../task-order-provider';

const ContractLineItemEdit = () => {
  const methods = useForm();

  const { setTaskOrderData } = useTaskOrder();

  const onSubmit = ({
    contractLineItemId,
    contractLineItemType,
    pricingOption,
    price,
  }) => {
    if (pricingOption === 'Not offered' || pricingOption === 'Neg @ Order') {
      // eslint-disable-next-line no-param-reassign
      price = '-';
    }
    const newClinRow = {
      contractLineItemId,
      contractLineItemType,
      pricingOption,
      price: price === '-' ? '' : parseFloat(price),
    };
    setTaskOrderData('EDIT_CONTRACT_LINE_ITEM_ROW_DATA', newClinRow);
    setTaskOrderData('SET_CONTRACT_LINE_ITEM_MODAL_MODE', null);
    setTaskOrderData('SET_SHOW_MODAL', false);
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="add-contract-line-item-edit-form"
        id="add-contract-line-item-edit-form"
        className="usa-form usa-form--large"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <AddContractLineItemForm />
      </form>
    </FormProvider>
  );
};

export default ContractLineItemEdit;
