import { gql } from '@apollo/client';

export const BLOCK_APPOINTMENT = gql`
  mutation BlockAppointment(
    $fleetVendorNumber: String!
    $appointmentStartTime: DateTime!
    $appointmentEndTime: DateTime!
  ) {
    blockAppointment(
      marshallingSchedule: {
        fleetVendorNumber: $fleetVendorNumber
        appointmentStartTime: $appointmentStartTime
        appointmentEndTime: $appointmentEndTime
      }
    ) {
      fleetVendorNumber
      appointmentStartTime
      appointmentEndTime
    }
  }
`;

export const UN_BLOCK_APPOINTMENT = gql`
  mutation UnBlockAppointment(
    $fleetVendorNumber: String!
    $appointmentStartTime: DateTime!
    $appointmentEndTime: DateTime!
  ) {
    unBlockAppointment(
      marshallingSchedule: {
        fleetVendorNumber: $fleetVendorNumber
        appointmentStartTime: $appointmentStartTime
        appointmentEndTime: $appointmentEndTime
      }
    ) {
      fleetVendorNumber
      appointmentStartTime
      appointmentEndTime
    }
  }
`;

export const GET_SCHEDULED_APPOINTMENT_LIST = gql`
  query getScheduledAppointmentList(
    $fleetVendorNumber: String!
    $weekStartDate: DateTime!
    $weekEndDate: DateTime!
  ) {
    getScheduledAppointmentList(
      fleetVendorNumber: $fleetVendorNumber
      weekStartDate: $weekStartDate
      weekEndDate: $weekEndDate
    ) {
      fleetVendorNumber
      appointmentStartTime
      appointmentEndTime
    }
  }
`;

export const GET_DRIVER_SCHEDULED_APPOINTMENT_LIST = gql`
  query getScheduledDriverAppointmentList(
    $fleetVendorNumber: String!
    $weekStartDate: DateTime!
    $weekEndDate: DateTime!
  ) {
    getScheduledDriverAppointmentList(
      fleetVendorNumber: $fleetVendorNumber
      weekStartDate: $weekStartDate
      weekEndDate: $weekEndDate
    ) {
      fleetVendorNumber
      appointmentStartTime
      appointmentEndTime
      appointmentId
    }
  }
`;

export const UN_BLOCK_DRIVER_APPOINTMENT = gql`
  mutation unBlockDriverAppointment(
    $fleetVendorNumber: String!
    $appointmentStartTime: DateTime!
    $appointmentEndTime: DateTime!
    $appointmentId: String!
    $vin: String!
    $driverName: String
    $driverPhoneNumber: String
    $driverEmail: String
  ) {
    unBlockDriverAppointment(
      driverExchangeSchedule: {
        fleetVendorNumber: $fleetVendorNumber
        appointmentStartTime: $appointmentStartTime
        appointmentEndTime: $appointmentEndTime
        appointmentId: $appointmentId
        vin: $vin
        driverName: $driverName
        driverPhoneNumber: $driverPhoneNumber
        driverEmail: $driverEmail
      }
    ) {
      fleetVendorNumber
      appointmentStartTime
      appointmentEndTime
      appointmentId
    }
  }
`;

export const BLOCK_DRIVER_APPOINTMENT = gql`
  mutation blockDriverAppointment(
    $fleetVendorNumber: String!
    $appointmentStartTime: DateTime!
    $appointmentEndTime: DateTime!
    $appointmentId: String!
    $vin: String!
    $driverName: String
    $driverPhoneNumber: String!
    $driverEmail: String!
  ) {
    blockDriverAppointment(
      driverExchangeSchedule: {
        fleetVendorNumber: $fleetVendorNumber
        appointmentStartTime: $appointmentStartTime
        appointmentEndTime: $appointmentEndTime
        appointmentId: $appointmentId
        vin: $vin
        driverName: $driverName
        driverPhoneNumber: $driverPhoneNumber
        driverEmail: $driverEmail
      }
    ) {
      fleetVendorNumber
      appointmentStartTime
      appointmentEndTime
      appointmentId
    }
  }
`;

export default {
  BLOCK_APPOINTMENT,
  UN_BLOCK_APPOINTMENT,
  GET_SCHEDULED_APPOINTMENT_LIST,
  GET_DRIVER_SCHEDULED_APPOINTMENT_LIST,
  UN_BLOCK_DRIVER_APPOINTMENT,
  BLOCK_DRIVER_APPOINTMENT,
};
