import { Label } from '@gsa/afp-component-library';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSalesDisposal } from '../sales-disposal-provider';

const SelectTransportationField = () => {
  const {
    lookup: { transportationType },
    salesDisposalSelected: { association },
  } = useSalesDisposal();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="margin-y-4">
      <Label required className="text-bold">
        Select the transportation needs for the vehicle
      </Label>

      <Controller
        name="transportationType"
        control={control}
        rules={{ required: 'Transportation type is required' }}
        defaultValue={association?.transportationType}
        render={({ field }) => (
          <>
            {transportationType.map((type) => {
              return (
                <div key={type.code}>
                  <input
                    {...field}
                    data-testid={`${type.code}-radio`}
                    className="usa-radio__input"
                    id={type.code}
                    type="radio"
                    name={type?.category}
                    value={type?.code}
                    checked={type?.code === field?.value?.code}
                    onChange={(e) => {
                      const checked = transportationType.find(
                        (t) => t.code === e.target.value,
                      );
                      field.onChange(checked);
                    }}
                  />
                  <label className="usa-radio__label" htmlFor={`${type?.code}`}>
                    {type?.title}
                  </label>
                </div>
              );
            })}

            <div className="margin-y-2 text-bold text-secondary">
              <ErrorMessage errors={errors} name="transportationType" />
            </div>
          </>
        )}
      />
    </div>
  );
};

export default SelectTransportationField;
