import { TextInput, Label } from '@gsa/afp-component-library';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

const DriverName = () => {
  const { control } = useFormContext();

  return (
    <div className="driver-name">
      <Label className="text-bold">Driver Name</Label>

      <Controller
        name="driverName"
        control={control}
        defaultValue=""
        render={({ field: { ref, ...fieldProps } }) => (
          <>
            <TextInput
              data-testid="driver-name"
              className="text-left width-card-lg"
              {...fieldProps}
            />
          </>
        )}
      />
    </div>
  );
};

export default DriverName;
