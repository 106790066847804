import { Label } from '@gsa/afp-component-library';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSalesListing } from '../sales-listing-provider';

const SelectSaleTypeField = () => {
  const {
    lookup: { saleType },
    salesListingModalMode,
    salesListingSelected,
  } = useSalesListing();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="margin-y-4">
      {salesListingModalMode === 'ADD_SALES_LISTING' ? (
        <Label required className="text-bold">
          Sale Type
        </Label>
      ) : (
        <Label className="text-bold">Sale Type</Label>
      )}
      {salesListingModalMode === 'ADD_SALES_LISTING' && (
        <Controller
          name="saleType"
          control={control}
          rules={{ required: 'Sale type is required' }}
          defaultValue={saleType}
          render={({ field }) => (
            <>
              {saleType.map((type) => {
                return (
                  <div key={type.code}>
                    <input
                      {...field}
                      data-testid={`${type.code}-radio`}
                      className="usa-radio__input"
                      id={type.code}
                      type="radio"
                      name={type?.category}
                      value={type?.code}
                      checked={type?.code === field?.value?.code}
                      onChange={(e) => {
                        const checked = saleType.find(
                          (t) => t.code === e.target.value,
                        );
                        field.onChange(checked);
                      }}
                    />
                    <label
                      className="usa-radio__label"
                      htmlFor={`${type?.code}`}
                    >
                      {type?.title}
                    </label>
                  </div>
                );
              })}
              <div className="margin-y-2 text-bold text-secondary">
                <ErrorMessage errors={errors} name="saleType" />
              </div>
            </>
          )}
        />
      )}
      {!(salesListingModalMode === 'ADD_SALES_LISTING') && (
        <div className=".text-normal margin-top-1 margin-bottom-neg-3">
          {salesListingSelected?.saleType?.title}
        </div>
      )}
    </div>
  );
};

export default SelectSaleTypeField;
