import { Label, TextInput } from '@gsa/afp-component-library';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useSalesDisposal } from '../sales-disposal-provider';

const CommentField = () => {
  const {
    salesDisposalSelected: { association },
  } = useSalesDisposal();

  const { control } = useFormContext();

  return (
    <div className="margin-y-4">
      <Label className="text-bold">Comment</Label>

      <Controller
        name="comment"
        control={control}
        rules={{ maxLength: 300 }}
        defaultValue={association?.comment ?? ''}
        render={({ field: { ref, ...fieldProps } }) => (
          <TextInput
            data-testid="comment-box"
            className="height-15 maxw-full"
            type="textarea"
            maxLength={300}
            {...fieldProps}
          />
        )}
      />

      <div className="text-base padding-top-1">300 characters allowed</div>
    </div>
  );
};

export default CommentField;
