/* eslint-disable react/button-has-type */
import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import AutoCompleteInput from '../../../components/autocomplete-input';
import { useFilter } from '../../../components/filter/filter-provider';
import { GET_MARSHALLING_LIST_TAGS } from '../marshalling.gql';

const LicensePlateFilter = () => {
  const { filterState, onFilterItemChange } = useFilter();
  const [options, setOptions] = useState([]);

  const [fetchOptions] = useLazyQuery(GET_MARSHALLING_LIST_TAGS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getMarshallingVehiclesList }) => {
      let filteredOptions = ['Error: 404'];
      if (
        getMarshallingVehiclesList &&
        getMarshallingVehiclesList?.rows?.length
      ) {
        filteredOptions = getMarshallingVehiclesList.rows.map((o) => ({
          key: o.tag,
          label: o.tag,
          ...o,
        }));
      }
      setOptions(filteredOptions);
    },
  });

  const getOptions = (searchQuery) => {
    fetchOptions({
      variables: {
        limit: 25,
        filters: [
          {
            conditions: [{ operator: '$like', key: 'tag', value: searchQuery }],
            operator: '$and',
          },
        ],
      },
    });
  };

  return (
    <AutoCompleteInput
      id="license-plate-filter"
      data-testid="license-plate-filter"
      value={filterState?.tag}
      options={options}
      onSelect={(selected) => {
        onFilterItemChange({ id: 'tag', value: selected });
      }}
      onFilter={(keyword) => {
        getOptions(keyword);
      }}
      inputProps={{
        placeholder: 'License Plate',
      }}
    />
  );
};

export default LicensePlateFilter;
