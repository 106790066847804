import { Label, TextInput } from '@gsa/afp-component-library';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTaskOrder } from '../task-order-provider';

const SaleLocationField = () => {
  const { setTaskOrderMode, setTaskOrderSelected } = useTaskOrder();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  useEffect(() => {
    if (setTaskOrderSelected?.contractor?.saleLocationCode) {
      setValue(
        'saleLocationCode',
        `${setTaskOrderSelected.contractor?.saleLocationCode}`,
      );
    }
  }, [setTaskOrderSelected?.contractor?.saleLocationCode]);

  return (
    <>
      {setTaskOrderMode === 'ADD_TASK_ORDER' ||
      setTaskOrderMode === 'DUPLICATE_TASK_ORDER' ? (
        <Label required className="text-bold">
          Sale Location Code
        </Label>
      ) : (
        <Label className="text-bold">Sale Location Code</Label>
      )}

      {(setTaskOrderMode === 'UPDATE_TASK_ORDER' ||
        setTaskOrderMode === 'VIEW_TASK_ORDER') && (
        <div className="text-base-darkest margin-y-2">
          {setTaskOrderSelected?.contractor?.saleLocationCode}
        </div>
      )}

      {(setTaskOrderMode === 'ADD_TASK_ORDER' ||
        setTaskOrderMode === 'DUPLICATE_TASK_ORDER') && (
        <Controller
          name="saleLocationCode"
          control={control}
          rules={{
            required: 'Sale Location is required',
            maxLength: {
              value: 3,
              message: 'This input exceed maxLength.',
            },
          }}
          defaultValue={
            setTaskOrderSelected?.contractor?.saleLocationCode ?? ''
          }
          render={({ field: { ref, ...fieldProps } }) => (
            <TextInput
              data-testid="sale-location-code"
              placeholder="Enter Sale Location Code"
              {...fieldProps}
            />
          )}
        />
      )}
      <div className="margin-y-1 text-bold text-secondary">
        <ErrorMessage errors={errors} name="saleLocationCode" />
      </div>
    </>
  );
};

export default SaleLocationField;
