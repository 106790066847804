/* eslint-disable react/button-has-type */
import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import AutoCompleteInput from '../../../components/autocomplete-input';
import { useFilter } from '../../../components/filter/filter-provider';
import { GET_DISPOSAL_LIST_VINS } from '../sales-disposal.gql';

const VinFilter = () => {
  const { filterState, onFilterItemChange } = useFilter();
  const [options, setOptions] = useState([]);

  const [fetchOptions] = useLazyQuery(GET_DISPOSAL_LIST_VINS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getDisposalList }) => {
      let filteredOptions = ['Error: 404'];
      if (getDisposalList && getDisposalList?.rows?.length) {
        filteredOptions = getDisposalList.rows.map((o) => ({
          key: o.vin,
          label: o.vin,
          ...o,
        }));
      }
      setOptions(filteredOptions);
    },
  });

  const getOptions = (searchQuery) => {
    fetchOptions({
      variables: {
        limit: 25,
        filters: [
          {
            conditions: [{ operator: '$like', key: 'vin', value: searchQuery }],
            operator: '$and',
          },
        ],
      },
    });
  };

  return (
    <AutoCompleteInput
      id="vin-filter"
      data-testid="vin-filter"
      value={filterState?.vin}
      options={options}
      onSelect={(selected) => {
        onFilterItemChange({ id: 'vin', value: selected });
      }}
      onFilter={(keyword) => {
        getOptions(keyword);
      }}
      inputProps={{
        placeholder: 'VIN',
      }}
    />
  );
};

export default VinFilter;
