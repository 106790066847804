import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useFilter } from '../../../components/filter/filter-provider';
import AutoCompleteInput from '../../../components/autocomplete-input';
import { GET_SALE_LOCATION_CODE } from '../task-order.gql';

const SaleLocationCodeFilter = () => {
  const { filterState, onFilterItemChange } = useFilter();
  const [options, setOptions] = useState([]);

  const [fetchOptions] = useLazyQuery(GET_SALE_LOCATION_CODE, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getTaskOrderList }) => {
      let filteredOptions = ['Error: 404'];
      if (getTaskOrderList && getTaskOrderList?.rows?.length) {
        filteredOptions = getTaskOrderList?.rows?.map((o) => ({
          key: o.contractor?.saleLocationCode,
          label: o.contractor?.saleLocationCode,
          ...o,
        }));
      }
      setOptions(filteredOptions);
    },
  });

  const getOptions = (searchQuery) => {
    fetchOptions({
      variables: {
        limit: 1,
        filters: [
          {
            conditions: [
              {
                operator: '$exact',
                key: '$contractor.sale_location_code$',
                value: searchQuery,
              },
            ],
            operator: '$and',
          },
        ],
      },
    });
  };

  return (
    <AutoCompleteInput
      id="sale-location-code-filter"
      data-testid="sale-location-code-filter"
      value={filterState?.saleLocationCode?.contractor?.saleLocationCode}
      options={options}
      onSelect={(selected) => {
        onFilterItemChange({ id: 'saleLocationCode', value: selected });
      }}
      onFilter={(keyword) => {
        getOptions(keyword);
      }}
      inputProps={{
        placeholder: 'Sale Location Code',
      }}
    />
  );
};

export default SaleLocationCodeFilter;
