import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import { Filter, FilterItem } from '../../../components/filter';
import { useVehicleSales } from '../vehicle-sales-provider';
import { GET_VEHICLE_SALE_LIST_FILTERS } from '../vehicle-sales.gql';
import VehicleFilter from './vehicle-filter';
import RunNumberFilter from './run-number-filter';
import LotNumberFilter from './lot-number-filter';
import SaleContractNumberFilter from './sale-contract-number-filter';
import VinFilter from './vin-filter';
import YearFilter from './year-filter';
import ConditionCodeFilter from './condition-code-filter';

const VehicleSalesFilter = ({ onFilter }) => {
  const { setVehicleSalesData } = useVehicleSales();

  useQuery(GET_VEHICLE_SALE_LIST_FILTERS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setVehicleSalesData(
        'SET_VEHICLE_SALES_LIST_FILTERS',
        data.getVehicleSaleListFilters,
      );
    },
  });

  return (
    <Filter onChange={onFilter}>
      <FilterItem
        id="vin-filter"
        title="VIN"
        clearButtons={[{ id: 'vin', clearValue: null }]}
        content={<VinFilter />}
      />

      <FilterItem
        id="vehicle-filter"
        title="Vehicle"
        content={<VehicleFilter />}
      />

      <FilterItem
        id="year-filter"
        title="Year"
        content={<YearFilter />}
        clearButtons={[{ id: 'year', clearValue: null }]}
      />

      <FilterItem
        id="condition-code-filter"
        title="Condition Code"
        clearButtons={[
          {
            text: 'Clear condition code',
            id: 'disposalCondition',
            clearValue: null,
          },
        ]}
        content={<ConditionCodeFilter />}
      />

      <FilterItem
        id="run-number-filter"
        title="Run Number"
        clearButtons={[{ id: 'runNumber', clearValue: null }]}
        content={<RunNumberFilter />}
      />

      <FilterItem
        id="lot-number-filter"
        title="Lot Number"
        clearButtons={[{ id: 'lotNumber', clearValue: null }]}
        content={<LotNumberFilter />}
      />

      <FilterItem
        id="sale-contract-number-filter"
        title="Sale Contract Number"
        clearButtons={[{ id: 'saleContractNumber', clearValue: null }]}
        content={<SaleContractNumberFilter />}
      />
    </Filter>
  );
};

VehicleSalesFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

export default VehicleSalesFilter;
