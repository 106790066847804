import { Button } from '@gsa/afp-component-library';
import React from 'react';
import ModalWrapper from '../../components/modal-wrapper/modal-wrapper';
import UpdateRunNumberForm from './forms/update-run-number-form';
import VehicleSalesDesiredPriceEdit from './forms/vehicle-sales-desired-price-edit';
import VehicleSalesRemoveVehicle from './forms/vehicle-sales-remove-vehicle';
import VehicleSalesAlert from './vehicle-sales-alert';
import { useVehicleSales } from './vehicle-sales-provider';

const ModalTitle = () => {
  const { vehicleSalesModalMode } = useVehicleSales();

  let title = '';
  switch (vehicleSalesModalMode) {
    case 'UPDATE_DESIRED_PRICE':
      title = <h2>Edit Desired Price</h2>;
      break;
    case 'UPDATE_RUN_ORDER_NUMBER':
      title = <h2>Update Run Order Number</h2>;
      break;
    case 'REMOVE_VEHICLE':
      title = <h2>Remove from Sale</h2>;
      break;
    default:
      break;
  }
  return title;
};

const ModalContent = () => {
  const { vehicleSalesModalMode, vehicleSalesSelected } = useVehicleSales();

  switch (vehicleSalesModalMode) {
    case 'UPDATE_DESIRED_PRICE':
      return (
        <>
          {vehicleSalesModalMode && <VehicleSalesAlert />}
          <div className="display-flex flex-row flex-justify margin-top-3">
            <div>
              <strong>
                {`${vehicleSalesSelected?.vehicleListing?.year || ''} ${
                  vehicleSalesSelected?.vehicleListing?.makeName || ''
                } ${vehicleSalesSelected?.vehicleListing?.modelName || ''}`}
              </strong>
              <div>{vehicleSalesSelected?.vin}</div>
            </div>
          </div>
          <VehicleSalesDesiredPriceEdit />
        </>
      );
    case 'UPDATE_RUN_ORDER_NUMBER':
      return (
        <>
          {vehicleSalesModalMode && <VehicleSalesAlert />}
          <p>
            Please use this window to update the current Run Order Number or add
            a new.
          </p>
          <UpdateRunNumberForm />
        </>
      );
    case 'REMOVE_VEHICLE':
      return (
        <>
          {vehicleSalesModalMode && <VehicleSalesAlert />}
          <VehicleSalesRemoveVehicle />
        </>
      );
    default:
      return null;
  }
};

const ModalAction = () => {
  const { resetModal, vehicleSalesModalMode } = useVehicleSales();

  switch (vehicleSalesModalMode) {
    case 'UPDATE_DESIRED_PRICE':
      return (
        <>
          <Button
            variant="unstyled margin-right-2"
            onClick={resetModal}
            data-testid="vehicle-sales-update-cancel-button"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="vehicle-sales-update-form"
            data-testid="vehicle-sales-update-save-button"
          >
            Save
          </Button>
        </>
      );
    case 'UPDATE_RUN_ORDER_NUMBER':
      return (
        <>
          <Button
            variant="unstyled margin-right-2"
            onClick={resetModal}
            data-testid="run-number-update-cancel-button"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="update-run-number-form"
            data-testid="run-number-update-save-button"
          >
            Save
          </Button>
        </>
      );
    case 'REMOVE_VEHICLE':
      return (
        <>
          <Button
            variant="unstyled margin-right-2"
            onClick={resetModal}
            data-testid="remove-vehicle-cancel-button"
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            data-testid="remove-vehicle-remove-button"
            form="vehicle-sales-remove-vehicle-form"
          >
            Remove
          </Button>
        </>
      );
    default:
      return null;
  }
};

const VehicleSalesModal = () => {
  const { resetModal, vehicleSalesModalShow } = useVehicleSales();

  return (
    <ModalWrapper
      title={<ModalTitle />}
      actions={<ModalAction />}
      show={vehicleSalesModalShow}
      onClose={resetModal}
    >
      <ModalContent />
    </ModalWrapper>
  );
};

export default VehicleSalesModal;
