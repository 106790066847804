import { Button, Icon } from '@gsa/afp-component-library';
import { Can } from '@gsa/afp-shared-ui-utils';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '../../widgets/breadcrumbs';
import WelcomeMessage from '../../widgets/welcome-message';
import TaskOrderAlert from './task-order-alert';
import { useTaskOrder } from './task-order-provider';
import TaskOrderTable from './task-order-table';

const TaskOrder = () => {
  document.title = 'AFP - Task Order Listing';
  const { setTaskOrderData, setTaskOrderSelected } = useTaskOrder();
  const history = useHistory();

  const handleCreateTaskOrder = () => {
    setTaskOrderData('SET_TASK_ORDER_MODE', 'ADD_TASK_ORDER');
    setTaskOrderData('RESET_CLIN_DATA', []);
    if (setTaskOrderSelected) {
      setTaskOrderData('SET_SELECTED', '');
    }
    setTaskOrderData('SET_MESSAGE', '');
    setTaskOrderData('SET_ERROR', '');
    history.push('/task-order/create');
  };
  return (
    <div data-testid="task-order-listing">
      <Breadcrumbs
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs.home}/home`,
            label: 'Home',
          },
        ]}
        current="Task Order Listing"
      />
      <TaskOrderAlert />
      <h1>Task Order Listing</h1>
      <WelcomeMessage message="This page will display a listing of task orders that are currently awarded to GSA Fleet selling agent and marshalling vendors. This page will also show historical (past year) task order awards for those vendors. The user can search and or filter this information using the available tools." />
      <Can I="create" a="AHMTaskOrder">
        <Button
          data-testid="task-order-table-create-button"
          className="display-flex margin-left-auto margin-top-4"
          type="button"
          onClick={handleCreateTaskOrder}
        >
          <Icon className="text-bottom margin-right-1" iconName="add" />
          Create a Task Order
        </Button>
      </Can>
      <TaskOrderTable />
    </div>
  );
};

export default TaskOrder;
