import { Label, TextInput } from '@gsa/afp-component-library';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTaskOrder } from '../task-order-provider';

const TaskOrderNumberField = () => {
  const { setTaskOrderMode, setTaskOrderSelected } = useTaskOrder();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (setTaskOrderSelected?.taskOrderNumber) {
      setValue('taskOrderNumber', `${setTaskOrderSelected.taskOrderNumber}`);
    }
  }, [setTaskOrderSelected?.taskOrderNumber]);

  return (
    <>
      {setTaskOrderMode === 'ADD_TASK_ORDER' ||
      setTaskOrderMode === 'DUPLICATE_TASK_ORDER' ? (
        <Label required className="text-bold">
          Task Order Number
        </Label>
      ) : (
        <Label className="text-bold">Task Order Number</Label>
      )}

      {(setTaskOrderMode === 'UPDATE_TASK_ORDER' ||
        setTaskOrderMode === 'VIEW_TASK_ORDER') && (
        <div
          data-testid="display-task-order-number"
          className="text-base-darkest margin-y-2"
        >
          {setTaskOrderSelected?.taskOrderNumber}
        </div>
      )}

      {(setTaskOrderMode === 'ADD_TASK_ORDER' ||
        setTaskOrderMode === 'DUPLICATE_TASK_ORDER') && (
        <Controller
          name="taskOrderNumber"
          control={control}
          defaultValue={setTaskOrderSelected?.taskOrderNumber ?? ''}
          render={({ field: { ref, ...fieldProps } }) => (
            <TextInput
              data-testid="task-order-number"
              placeholder="Enter Task Order Number"
              {...fieldProps}
            />
          )}
        />
      )}
      <div className="margin-y-1 text-bold text-secondary">
        <ErrorMessage errors={errors} name="taskOrderNumber" />
      </div>
    </>
  );
};

export default TaskOrderNumberField;
