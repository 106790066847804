import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSalesListing } from '../sales-listing-provider';
import PromoDescriptionField from './promo-description-field';
import SaleInspectionDate from './sale-inspection-date';
import SelectSalesDate from './sale-listing-date';
import SaleLocationSearchField from './sale-location-search-field';
import SelectSaleTypeField from './select-sale-type-field';
import { formatInputDateToUTC } from '../../../utils/formatting';

const saleListingDateFormat = 'LL/dd/yyyy hh:mm';
const getSaleLocation = (formValue, contextValue) => {
  return {
    saleLocationCode: formValue?.id || contextValue?.saleLocationCode,
    saleLocationType:
      formValue?.subsectionTypeCode || contextValue?.saleLocationType,
  };
};

const getInspectionDates = (
  inspectionDate1,
  inspectionStartTime1,
  inspectionEndTime1,
  inspectionDate2,
  inspectionStartTime2,
  inspectionEndTime2,
) => {
  return {
    inspectionStartDate1: formatInputDateToUTC(
      `${inspectionDate1} ${inspectionStartTime1}`,
      saleListingDateFormat,
    ),
    inspectionFinishDate1: formatInputDateToUTC(
      `${inspectionDate1} ${inspectionEndTime1}`,
      saleListingDateFormat,
    ),
    inspectionStartDate2: inspectionDate2
      ? formatInputDateToUTC(
          `${inspectionDate2} ${inspectionStartTime2}`,
          saleListingDateFormat,
        )
      : undefined,
    inspectionFinishDate2: inspectionDate2
      ? formatInputDateToUTC(
          `${inspectionDate2} ${inspectionEndTime2}`,
          saleListingDateFormat,
        )
      : undefined,
  };
};

const SalesListingEdit = () => {
  const { updateSaleListing, salesListingSelected } = useSalesListing();
  const methods = useForm();
  const onSubmit = ({
    saleLocation,
    saleType,
    saleStartDate,
    saleStartTime,
    saleFinishDate,
    saleEndTime,
    promotionDescription,
    inspectionDate1,
    inspectionStartTime1,
    inspectionEndTime1,
    inspectionDate2,
    inspectionStartTime2,
    inspectionEndTime2,
  }) => {
    updateSaleListing({
      variables: {
        saleNumber: salesListingSelected.saleNumber,
        saleTypeCode: salesListingSelected?.saleType?.code
          ? salesListingSelected.saleType.code
          : saleType?.code,
        ...getSaleLocation(saleLocation, salesListingSelected),
        saleStartDate: formatInputDateToUTC(
          `${saleStartDate} ${saleStartTime}`,
          saleListingDateFormat,
        ),
        saleFinishDate: formatInputDateToUTC(
          `${saleFinishDate} ${saleEndTime}`,
          saleListingDateFormat,
        ),
        ...getInspectionDates(
          inspectionDate1,
          inspectionStartTime1,
          inspectionEndTime1,
          inspectionDate2,
          inspectionStartTime2,
          inspectionEndTime2,
        ),
        promotionDescription,
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="sale-listing-edit-form"
        id="sale-listing-edit-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <SaleLocationSearchField />

        <SelectSaleTypeField />

        <SelectSalesDate />

        <SaleInspectionDate />

        <PromoDescriptionField />
      </form>
    </FormProvider>
  );
};

export default SalesListingEdit;
