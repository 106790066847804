import { useQuery } from '@apollo/client';
import { Button } from '@gsa/afp-component-library';
import React from 'react';
import ModalWrapper from '../../components/modal-wrapper/modal-wrapper';
import VehicleAssociationForm from './forms/vehicle-association-form';
import VehicleSaleForm from './forms/vehicle-sale-form';
import ModifyConditionCode from './modify-condition-code';
import { useSalesDisposal } from './sales-disposal-provider';
import { MARKETPLACE_LOOKUP } from './sales-disposal.gql';

const ModalTitle = () => {
  const { salesDisposalModalMode } = useSalesDisposal();

  let title = '';
  switch (salesDisposalModalMode) {
    case 'ADD_AUCTION_HOUSE_ASSOCIATION':
      title = <h2>Associate a Vehicle to Selling Agent</h2>;
      break;
    case 'ADD_VEHICLE_SALE':
      title = <h2>Lot to Sale</h2>;
      break;
    case 'MODIFY_CONDITION_CODE':
      title = <h2>Modify Condition Code</h2>;
      break;
    default:
      break;
  }

  return title;
};

const ModalContent = () => {
  const { salesDisposalModalMode, salesDisposalSelected } = useSalesDisposal();

  switch (salesDisposalModalMode) {
    case 'ADD_AUCTION_HOUSE_ASSOCIATION':
      return (
        <>
          <p>
            Use this window to associate the vehicle to Selling Agent and
            transportation.
          </p>
          <div className="display-flex flex-row flex-justify">
            <div>
              <div className="text-primary text-bold">VEHICLE</div>
              <div>
                <strong>
                  {`${salesDisposalSelected?.year || ''} ${
                    salesDisposalSelected?.makeName || ''
                  } ${salesDisposalSelected?.modelName || ''}`}
                </strong>
                <div>{salesDisposalSelected?.vin}</div>
              </div>
            </div>
            <div>
              <div className="text-primary text-bold">CURRENT LOCATION</div>
              <div data-testid="current-location">
                {salesDisposalSelected?.currentLocation?.name ? (
                  `${salesDisposalSelected?.currentLocation?.name} ${salesDisposalSelected?.currentLocation?.stateCode}`
                ) : (
                  <>&ndash;</>
                )}
              </div>
            </div>
          </div>
          <div className="margin-top-6">
            <VehicleAssociationForm />
          </div>
        </>
      );

    case 'ADD_VEHICLE_SALE':
      return <VehicleSaleForm />;
    case 'MODIFY_CONDITION_CODE':
      return <ModifyConditionCode />;
    default:
      return null;
  }
};

const ModalAction = () => {
  const { resetModal, salesDisposalModalMode, clearLottedVehicles } =
    useSalesDisposal();

  switch (salesDisposalModalMode) {
    case 'ADD_AUCTION_HOUSE_ASSOCIATION':
      return (
        <>
          <Button
            variant="unstyled margin-right-2"
            onClick={resetModal}
            data-testid="vehicle-association-cancel-button"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="vehicle-association-add-form"
            data-testid="vehicle-association-save-button"
          >
            Save
          </Button>
        </>
      );

    case 'ADD_VEHICLE_SALE':
      return (
        <>
          <Button
            variant="unstyled margin-right-2"
            onClick={() => {
              resetModal();
              clearLottedVehicles();
            }}
            data-testid="vehicle-sale-cancel-button"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="vehicle-sale-add-form"
            data-testid="vehicle-sale-save-button"
          >
            Save
          </Button>
        </>
      );
    case 'MODIFY_CONDITION_CODE':
      return (
        <>
          <Button
            variant="unstyled margin-right-2"
            onClick={resetModal}
            data-testid="modify-condition-code-cancel-button"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="modify-condition-code-form"
            data-testid="modify-condition-code-save-button"
          >
            Save
          </Button>
        </>
      );

    default:
      return null;
  }
};

const SalesDisposalModal = () => {
  const { resetModal, salesDisposalModalShow, setNestedSalesDisposalData } =
    useSalesDisposal();

  useQuery(MARKETPLACE_LOOKUP, {
    variables: {
      category: 'TransportationType',
    },
    onCompleted: ({ getLookupItems }) => {
      if (getLookupItems.length)
        setNestedSalesDisposalData(
          'SET_LOOKUP_ITEM',
          'transportationType',
          getLookupItems,
        );
    },
  });

  return (
    <ModalWrapper
      title={<ModalTitle />}
      actions={<ModalAction />}
      show={salesDisposalModalShow}
      onClose={resetModal}
    >
      <ModalContent />
    </ModalWrapper>
  );
};

export default SalesDisposalModal;
