import { gql } from '@apollo/client';

export const GET_CONTRACTOR_NAME = gql`
  query ($nameQuery: String!, $limit: Int!, $subFilters: [Filter!]!) {
    vendorLocationSearch(
      name: $nameQuery
      limit: $limit
      subFilters: $subFilters
    ) {
      id: fleetVendorNumber
      name: vendorName
      address1
      address2
      city
      stateCode
      stateName
      postalCode
      vendorSam {
        uniqueEntityId
        dunsNumber
      }
      vendorTypes {
        vendorTypeCode
      }
    }
  }
`;

export const LOCATION_SEARCH = gql`
  query ($nameQuery: String!, $limit: Int!, $subFilters: [Filter!]!) {
    vendorLocationSearch(
      name: $nameQuery
      limit: $limit
      subFilters: $subFilters
    ) {
      id: fleetVendorNumber
      name: vendorName
      vendorTypes {
        vendorTypeCode
      }
    }
  }
`;

export const GET_TASK_ORDER_LIST = gql`
  query getTaskOrderList(
    $filters: [Filter!]
    $order: OrderBy
    $limit: Int!
    $offset: Int
  ) {
    getTaskOrderList(
      filters: $filters
      order: $order
      limit: $limit
      offset: $offset
    ) {
      rows {
        taskOrderNumber
        startDate
        endDate
        responsibleSCO
        vendorLocation {
          name
          stateCode
          city
          address1
          postalCode
        }
        contractor {
          saleLocationCode
          contractorCode
          contractorType
          vendorType {
            code
            title
          }
        }
        status
        contractLineItem {
          price
          pricingOption
          contractLineItemId
          contractLineItemType
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_TASK_ORDER_NUMBERS = gql`
  query getTaskOrderList($limit: Int!, $filters: [Filter!]!) {
    getTaskOrderList(limit: $limit, filters: $filters) {
      rows {
        taskOrderNumber
      }
    }
  }
`;

export const GET_RESPONSIBLE_SCO = gql`
  query getTaskOrderList($limit: Int!, $filters: [Filter!]!) {
    getTaskOrderList(limit: $limit, filters: $filters) {
      rows {
        responsibleSCO
      }
    }
  }
`;

export const GET_SALE_LOCATION_CODE = gql`
  query getTaskOrderList($limit: Int!, $filters: [Filter!]!) {
    getTaskOrderList(limit: $limit, filters: $filters) {
      rows {
        contractor {
          saleLocationCode
        }
      }
    }
  }
`;

export const GET_TASK_ORDER_FILTERS = gql`
  query GetTaskOrderFilters {
    getTaskOrderFilters {
      statusFilter
      vendorTypeFilter
    }
  }
`;

export const ADD_TASK_ORDER = gql`
  mutation AddTaskOrder(
    $taskOrderNumber: String!
    $contractorCode: String!
    $contractorType: String!
    $saleLocationCode: String!
    $startDate: DateTime!
    $endDate: DateTime!
    $responsibleSCO: String!
    $vendorTypeCode: String!
    $contractLineItem: [ContractLineItemType!]!
  ) {
    addTaskOrder(
      taskOrder: {
        taskOrderNumber: $taskOrderNumber
        contractorCode: $contractorCode
        contractorType: $contractorType
        saleLocationCode: $saleLocationCode
        startDate: $startDate
        endDate: $endDate
        responsibleSCO: $responsibleSCO
        vendorTypeCode: $vendorTypeCode
        contractLineItem: $contractLineItem
      }
    ) {
      taskOrderNumber
    }
  }
`;

export const UPDATE_TASK_ORDER = gql`
  mutation UpdateTaskOrder(
    $taskOrderNumber: String!
    $vendorTypeCode: String!
    $contractLineItem: [ContractLineItemType!]!
  ) {
    updateTaskOrder(
      taskOrder: {
        taskOrderNumber: $taskOrderNumber
        vendorTypeCode: $vendorTypeCode
        contractLineItem: $contractLineItem
      }
    ) {
      taskOrderNumber
    }
  }
`;

export const GET_TASK_ORDER = gql`
  query getTaskOrder($taskOrderNumber: String!) {
    getTaskOrder(taskOrderNumber: $taskOrderNumber) {
      rows {
        taskOrderNumber
        startDate
        endDate
        responsibleSCO
        vendorLocation {
          name
          stateCode
          city
          address1
          postalCode
        }
        contractor {
          saleLocationCode
          contractorCode
          contractorType
          vendorType {
            code
            title
          }
        }
        status
        contractLineItem {
          price
          pricingOption
          contractLineItemId
          contractLineItemType
        }
      }
    }
  }
`;

export default {
  GET_CONTRACTOR_NAME,
  ADD_TASK_ORDER,
  GET_TASK_ORDER_LIST,
  UPDATE_TASK_ORDER,
  GET_TASK_ORDER_NUMBERS,
  GET_RESPONSIBLE_SCO,
  GET_TASK_ORDER_FILTERS,
  GET_TASK_ORDER,
};
