import { Checkbox } from '@gsa/afp-component-library';
import { snakeCase } from 'lodash';
import React, { useMemo } from 'react';
import { useFilter } from '../../../components/filter/filter-provider';
import { arrayToggle } from '../../../utils/list';
import { useSalesListing } from '../sales-listing-provider';

const SaleStatusFilter = () => {
  const {
    saleListFilters: { saleStatusFilter },
  } = useSalesListing();

  const { filterState, onFilterItemChange } = useFilter();

  const value = useMemo(() => {
    return filterState.saleStatus ?? [];
  }, [filterState.saleStatus]);

  return (
    <>
      {saleStatusFilter.map((option) => (
        <Checkbox
          id={snakeCase(option.label)}
          key={option.label}
          name={option.label}
          label={option.label}
          checked={value.includes(option.value)}
          onChange={() => {
            const selected = arrayToggle(value, option.value);
            let newValue = null;

            if (selected.length) newValue = selected;

            onFilterItemChange({
              id: 'saleStatus',
              value: newValue,
            });
          }}
        />
      ))}
    </>
  );
};

export default SaleStatusFilter;
