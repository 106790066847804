import { DateRangePicker, Label, TimePicker } from '@gsa/afp-component-library';
import { ErrorMessage } from '@hookform/error-message';
import { DateTime } from 'luxon';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getLocalTimeZoneIndicator } from '../../../utils/formatting';
import { useSalesListing } from '../sales-listing-provider';

const SelectSalesDate = () => {
  const {
    control,
    formState: { errors },
    setValue,
    register,
  } = useFormContext();

  const { salesListingSelected } = useSalesListing();

  const dateNow = DateTime.now().toFormat('yyyy-LL-dd');

  const formatSaleDate = (dateType) => {
    return DateTime.fromISO(salesListingSelected?.[dateType]).toFormat(
      'yyyy-LL-dd',
    );
  };
  const formatSaleTime = (dateType) => {
    return DateTime.fromISO(salesListingSelected?.[dateType]).toLocaleString(
      DateTime.TIME_24_SIMPLE,
    );
  };
  return (
    <>
      <div className="display-flex flex-column margin-top-neg-2">
        <div className="display-flex flex-row">
          <div data-testid="sale-date-picker" className="margin-right-2">
            <Label required className="text-bold">
              Sale Date
            </Label>
            <div className="usa-hint" id="sale-start-date-hint">
              mm/dd/yyyy
            </div>
            <input
              hidden
              {...register('saleStartDate', {
                required: 'Sale start date is required',
              })}
            />
            <input
              hidden
              {...register('saleFinishDate', {
                required: true,
              })}
            />
            <DateRangePicker
              startDatePickerProps={{
                id: 'saleStartDate',
                name: 'saleStartDate',
                defaultValue: formatSaleDate('saleStartDate'),
                minDate: dateNow,
                readOnly: true,
                onChange: (date) => {
                  setValue('saleStartDate', date);
                },
              }}
              endDateLabel={
                <Label required className="text-bold">
                  End Date
                </Label>
              }
              endDateHint="mm/dd/yyyy"
              endDatePickerProps={{
                id: 'saleFinishDate',
                name: 'saleFinishDate',
                defaultValue: formatSaleDate('saleFinishDate'),
                minDate: dateNow,
                readOnly: true,
                onChange: (date) => {
                  setValue('saleFinishDate', date);
                },
              }}
            />
            <div className="margin-y-1 text-bold text-secondary">
              <ErrorMessage errors={errors} name="selectSaleDate" />
            </div>
          </div>
          <div>
            <div data-testid="sale-start-time" className="margin-right-2">
              <Label required className="text-bold">
                Time
              </Label>
              <div className="usa-hint" id="sale-start-time-hint">
                hh:mm&nbsp;{getLocalTimeZoneIndicator()}
              </div>
              <Controller
                name="saleStartTime"
                control={control}
                rules={{ required: 'Sale start time is required' }}
                render={({ field: { ref, ...fieldProps } }) => (
                  <TimePicker
                    className="margin-top-neg-2"
                    id="sale-start-time"
                    name="sale-start-time"
                    minTime="03:00"
                    maxTime="22:00"
                    defaultValue={formatSaleTime('saleStartDate')}
                    {...fieldProps}
                    onChange={(date) => {
                      fieldProps.onChange(date);
                    }}
                  />
                )}
              />

              <div className="margin-y-1 text-bold text-secondary">
                <ErrorMessage errors={errors} name="saleStartTime" />
              </div>
            </div>
            <div data-testid="sale-end-time">
              <Label required className="text-bold">
                Time
              </Label>
              <div className="usa-hint" id="sale-end-time-hint">
                hh:mm&nbsp;{getLocalTimeZoneIndicator()}
              </div>

              <Controller
                name="saleEndTime"
                control={control}
                rules={{ required: 'Sale end time is required' }}
                render={({ field: { ref, ...fieldProps } }) => (
                  <TimePicker
                    className="margin-top-neg-2"
                    id="sale-end-time"
                    name="sale-end-time"
                    minTime="03:00"
                    maxTime="22:00"
                    defaultValue={formatSaleTime('saleFinishDate')}
                    {...fieldProps}
                    onChange={(date) => {
                      fieldProps.onChange(date);
                    }}
                  />
                )}
              />
              <div className="margin-y-1 text-bold text-secondary">
                <ErrorMessage errors={errors} name="saleEndTime" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectSalesDate;
