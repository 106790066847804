import { useQuery } from '@apollo/client';
import { Alert, Button } from '@gsa/afp-component-library';
import React from 'react';
import ModalWrapper from '../../../components/modal-wrapper/modal-wrapper';
import SalesListingAdd from './sales-listing-add';
import SalesListingCancel from './sales-listing-cancel';
import SalesListingEdit from './sales-listing-edit';
import { useSalesListing } from '../sales-listing-provider';
import { MARKETPLACE_LOOKUP } from '../../sales-disposal/sales-disposal.gql';

const ModalTitle = () => {
  const { salesListingModalMode } = useSalesListing();

  let title = '';
  switch (salesListingModalMode) {
    case 'ADD_SALES_LISTING':
      title = <h2>Create a new sale</h2>;
      break;
    case 'DUPLICATE_SALES_LISTING':
      title = <h2>Duplicate a sale</h2>;
      break;
    case 'UPDATE_SALES_LISTING':
      title = <h2>Update a sale</h2>;
      break;
    case 'CANCEL_SALES_LISTING':
      title = <h2>Confirm cancel of sale</h2>;
      break;
    default:
      break;
  }

  return title;
};

const ModalContent = () => {
  const { salesListingModalMode } = useSalesListing();

  switch (salesListingModalMode) {
    case 'ADD_SALES_LISTING':
      return (
        <>
          <p>Please use this window to create a new sale.</p>
          <div>
            <SalesListingAdd />
            <div className="margin-top-2">
              <Alert actions={false} className="usa-alert--slim" type="info">
                Sale number will be generated after creating a sale.
              </Alert>
            </div>
          </div>
        </>
      );
    case 'DUPLICATE_SALES_LISTING':
      return (
        <>
          <p>Please use this window to duplicate a sale.</p>
          <div>
            <SalesListingAdd />
          </div>
        </>
      );
    case 'UPDATE_SALES_LISTING':
      return (
        <>
          <p>Please use this window to update an existing sale.</p>
          <div>
            <SalesListingEdit />
          </div>
        </>
      );
    case 'CANCEL_SALES_LISTING':
      return (
        <div>
          <SalesListingCancel />
        </div>
      );
    default:
      return null;
  }
};

const ModalAction = () => {
  const { resetModal, salesListingModalMode } = useSalesListing();

  switch (salesListingModalMode) {
    case 'ADD_SALES_LISTING':
      return (
        <>
          <Button
            variant="unstyled margin-right-2"
            onClick={resetModal}
            data-testid="sale-listing-add-cancel-button"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="sale-listing-add-form"
            data-testid="sale-listing-add-save-button"
          >
            Save
          </Button>
        </>
      );
    case 'DUPLICATE_SALES_LISTING':
      return (
        <>
          <Button
            variant="unstyled margin-right-2"
            onClick={resetModal}
            data-testid="sale-listing-duplicate-cancel-button"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="sale-listing-add-form"
            data-testid="sale-listing-duplicate-save-button"
          >
            Save
          </Button>
        </>
      );
    case 'UPDATE_SALES_LISTING':
      return (
        <>
          <Button
            variant="unstyled margin-right-2"
            onClick={resetModal}
            data-testid="sale-listing-edit-cancel-button"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="sale-listing-edit-form"
            data-testid="sale-listing-edit-save-button"
          >
            Save
          </Button>
        </>
      );
    case 'CANCEL_SALES_LISTING':
      return (
        <>
          <Button
            variant="unstyled margin-right-2"
            onClick={resetModal}
            data-testid="sale-listing-cancel-button"
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            data-testid="sale-listing-cancel-sale-button"
            form="sale-listing-cancel-form"
          >
            Cancel Sale
          </Button>
        </>
      );
    default:
      return null;
  }
};

const SalesListingModal = () => {
  const { resetModal, salesListingModalShow, setNestedSalesListingData } =
    useSalesListing();

  useQuery(MARKETPLACE_LOOKUP, {
    variables: {
      category: 'SaleType',
    },
    onCompleted: ({ getLookupItems }) => {
      if (getLookupItems.length)
        setNestedSalesListingData(
          'SET_LOOKUP_ITEM',
          'saleType',
          getLookupItems,
        );
    },
  });

  return (
    <ModalWrapper
      title={<ModalTitle />}
      actions={<ModalAction />}
      show={salesListingModalShow}
      onClose={resetModal}
    >
      <ModalContent />
    </ModalWrapper>
  );
};

export default SalesListingModal;
