import { Alert, Button, Icon } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const ALERT_TYPES = ['success', 'error', 'info', 'warning'];

const BannerMessage = ({ type, message, onClose, showClose, ...props }) => {
  if (!ALERT_TYPES.includes(type) || !message) return null;

  React.useEffect(() => {
    if (message?.length) window.scrollTo(0, 0);
  }, [message]);

  return (
    <Alert
      role="alert"
      focused
      slim
      actions={
        <div data-testid="close-wrapper">
          {showClose ? (
            <div
              data-testid="banner-message-close"
              className="position-relative"
            >
              <div className="position-static">
                <div className="position-absolute bottom-1 right-0">
                  <Button
                    data-testid={`${type}-close`}
                    variant="unstyled"
                    className="width-auto margin-top-2 text-middle"
                    onClick={onClose}
                  >
                    <Icon
                      iconName="close"
                      className="margin-x-1 text-middle text-gray-50"
                    />
                    Close
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      }
      type={type}
      {...props}
    >
      {/* Using ReactHtmlParser as it is safer than using the native dangerouslySetInnerHTML */}
      {ReactHtmlParser(message)}
    </Alert>
  );
};

BannerMessage.defaultProps = {
  type: null,
  message: null,
  showClose: true,
  onClose: () => {
    // logic here...
  },
};

BannerMessage.propTypes = {
  type: PropTypes.oneOf(ALERT_TYPES),
  message: PropTypes.string,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
};

export default BannerMessage;
