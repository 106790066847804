import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTaskOrder } from '../task-order-provider';
import ContractorNameField from './contractor-name-field';
import DateComponent from './date-component';
import ResponsibleSCO from './responsible-sco-field';
import SaleLocationField from './sale-location-code-field';
import SelectVendorType from './select-vendor-type';
import TaskOrderNumberField from './task-order-number-field';

const taskOrderSchema = yup.object().shape({
  taskOrderNumber: yup
    .string()
    .max(11, 'This input exceed maxLength.')
    .required('Task Order Number is required'),
  contractorName: yup
    .object()
    .shape({
      id: yup.string().required('Contractor Name is required'),
      name: yup.string().nullable(),
      subsectionTypeCode: yup.string().nullable(),
      address1: yup.string().nullable(),
      address2: yup.string().nullable(),
      city: yup.string().nullable(),
      stateCode: yup.string().nullable(),
      postalCode: yup.string().nullable(),
    })
    .nullable()
    .required('Contractor Name is required'),
  startDate: yup.string().required('Start Date is required'),
  endDate: yup.string().required('End Date is required'),
  vendorType: yup
    .object()
    .shape({
      code: yup.string().required('Vendor Type is required'),
      category: yup.string().nullable(),
      title: yup.string().nullable(),
    })
    .nullable()
    .required('Vendor Type is required'),

  saleLocationCode: yup
    .string()
    .max(3, 'This input exceed maxLength.')
    .required('Sale Location Code is required'),

  responsibleSCO: yup
    .string()
    .email('Please enter a valid email address')
    .required('Responsible SCO is required'),
});

const TaskOrderForm = () => {
  const methods = useForm({
    resolver: yupResolver(taskOrderSchema),
  });

  const {
    clinData,
    addTaskOrder,
    setTaskOrderData,
    setTaskOrderMode,
    setTaskOrderSelected,
    updateTaskOrder,
  } = useTaskOrder();

  const onSubmit = ({
    taskOrderNumber,
    contractorName,
    startDate,
    endDate,
    saleLocationCode,
    responsibleSCO,
    vendorType,
  }) => {
    clinData.map((c) => {
      if (c.price === '-' || c.price === '') {
        // eslint-disable-next-line no-param-reassign
        c.price = null;
      }
      return c;
    });
    if (setTaskOrderMode === 'UPDATE_TASK_ORDER') {
      const updatedClinData = [];
      clinData.map(({ __typename, ...rest }) => updatedClinData.push(rest));
      updateTaskOrder({
        variables: {
          taskOrderNumber: setTaskOrderSelected?.taskOrderNumber,
          vendorTypeCode: vendorType.code,
          contractLineItem: updatedClinData,
        },
      });
    } else {
      const updatedClinData = [];
      clinData.map(({ __typename, ...rest }) => updatedClinData.push(rest));
      if (setTaskOrderMode === 'DUPLICATE_TASK_ORDER') {
        if (
          setTaskOrderSelected?.taskOrderNumber === taskOrderNumber ||
          DateTime.fromISO(setTaskOrderSelected?.startDate).toFormat(
            'LL/dd/yyyy',
          ) === startDate ||
          DateTime.fromISO(setTaskOrderSelected?.endDate).toFormat(
            'LL/dd/yyyy',
          ) === endDate
        ) {
          setTaskOrderData('SET_MESSAGE', {
            type: 'error',
            message:
              'A Task Order with the same details already exists. Please review the details and re-submit.',
          });
          return;
        }
      }
      // startDate should be before endDate
      const dateValidation =
        DateTime.fromFormat(startDate, 'D').startOf('day') <
        DateTime.fromFormat(endDate, 'D').startOf('day');

      if (clinData?.length === 0) {
        setTaskOrderData('SET_ERROR', 'contract_line_item_table_error');
        return;
      }

      if (!dateValidation) {
        setTaskOrderData('SET_ERROR', 'date_validation_error');
        return;
      }

      // Clear previously set messages.
      setTaskOrderData('SET_MESSAGE', {
        type: '',
        message: '',
      });
      addTaskOrder({
        variables: {
          taskOrderNumber,
          contractorCode: contractorName?.id,
          contractorType: contractorName?.subsectionTypeCode,
          saleLocationCode,
          startDate: DateTime.fromFormat(startDate, 'D')
            .startOf('day')
            .toUTC()
            .toISO(),
          endDate: DateTime.fromFormat(endDate, 'D')
            .startOf('day')
            .toUTC()
            .toISO(),
          responsibleSCO,
          vendorTypeCode: vendorType.code,
          contractLineItem:
            setTaskOrderMode === 'DUPLICATE_TASK_ORDER'
              ? updatedClinData
              : clinData,
        },
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        key="task-order-form"
        id="task-order-form"
        data-testid="task-order-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <TaskOrderNumberField />
        <ContractorNameField />
        <ResponsibleSCO />
        <DateComponent />
        <SelectVendorType />
        <SaleLocationField />
      </form>
    </FormProvider>
  );
};

export default TaskOrderForm;
