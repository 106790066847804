import { Label, TextInput } from '@gsa/afp-component-library';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTaskOrder } from '../task-order-provider';

const ResponsibleSCO = () => {
  const { setTaskOrderMode, setTaskOrderSelected } = useTaskOrder();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  useEffect(() => {
    if (setTaskOrderSelected?.responsibleSCO) {
      setValue('responsibleSCO', `${setTaskOrderSelected.responsibleSCO}`);
    }
  }, [setTaskOrderSelected?.responsibleSCO]);

  return (
    <>
      {setTaskOrderMode === 'ADD_TASK_ORDER' ||
      setTaskOrderMode === 'DUPLICATE_TASK_ORDER' ? (
        <Label required className="text-bold">
          Responsible COR Name
        </Label>
      ) : (
        <Label className="text-bold">Responsible COR Name</Label>
      )}

      {(setTaskOrderMode === 'UPDATE_TASK_ORDER' ||
        setTaskOrderMode === 'VIEW_TASK_ORDER') && (
        <div className="text-base-darkest margin-y-2">
          {setTaskOrderSelected?.responsibleSCO}
        </div>
      )}
      {(setTaskOrderMode === 'ADD_TASK_ORDER' ||
        setTaskOrderMode === 'DUPLICATE_TASK_ORDER') && (
        <Controller
          name="responsibleSCO"
          control={control}
          rules={{
            required: 'Responsible COR Name is required',
          }}
          defaultValue={setTaskOrderSelected?.responsibleSCO ?? ''}
          render={({ field: { ref, ...fieldProps } }) => (
            <TextInput
              data-testid="responsible-sco-name"
              placeholder="Enter Responsible COR Name"
              {...fieldProps}
            />
          )}
        />
      )}
      <div className="margin-y-1 text-bold text-secondary">
        <ErrorMessage errors={errors} name="responsibleSCO" />
      </div>
    </>
  );
};

export default ResponsibleSCO;
