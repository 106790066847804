import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '../../widgets/breadcrumbs';
import './styles/vehicle-sales.scss';
import VehicleSalesProvider from './vehicle-sales-provider';
import VehicleSalesTable from './vehicle-sales-table';

const VehicleSales = () => {
  document.title = 'AFP - Sales';
  const history = useHistory();

  const [passedData, setPassedData] = useState(history.location.state);
  useEffect(() => {
    setPassedData(history.location.state);
  }, [history.location.state]);

  const title = passedData
    ? `Sale - ${passedData?.saleNumber} - ${passedData?.auctionHouseName} - ${passedData?.saleDate} - ${passedData?.saleType}`
    : null;

  return (
    <div className="grid-col vehicle-sales-page">
      <VehicleSalesProvider>
        <>
          <Breadcrumbs
            path={[
              {
                location: `${window.AFP_CONFIG.appURLs.home}/home`,
                label: 'Home',
              },
            ]}
            current="Sales"
          />
          <VehicleSalesTable
            title={title}
            welcomeMessage="On this page, users will have the ability to view the list of vehicles lotted to a sale. The user may also update the sale to remove lotted vehicles. The user can add more vehicles to this sale from the Sale and Disposal Inventory Management page."
          />
        </>
      </VehicleSalesProvider>
    </div>
  );
};

export default VehicleSales;
