import { AFPTable, AFPTableRowAction } from '@gsa/afp-component-library';
import React, { useMemo } from 'react';
import { formatDataValue } from '../../utils/formatting';
import AddContractLineItem from './add-contract-line-item';
import { useTaskOrder } from './task-order-provider';
import ContractLineItemModal from './contract-line-item-modal';

const actionList = [
  {
    icon: 'edit',
    label: 'Edit',
  },
  {
    icon: 'delete',
    label: 'Delete',
  },
];

const ContractLineItemTable = () => {
  const tableRef = React.createRef();
  const { setTaskOrderData, setTaskOrderMode, clinData } = useTaskOrder();

  const handleSelectedRow = (e, row) => {
    if (e === 'Delete') {
      setTaskOrderData('DELETE_CONTRACT_LINE_ITEM_ROW_DATA', row?.original);
    } else {
      setTaskOrderData(
        'SET_CONTRACT_LINE_ITEM_MODAL_MODE',
        'EDIT_CONTRACT_LINE_ITEM',
      );
      setTaskOrderData('SET_CONTRACT_LINE_ITEM_ROW_DATA', row?.original);
      setTaskOrderData('SET_SHOW_MODAL', true);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'CLIN ID',
        accessor: 'contractLineItemId',
      },
      {
        Header: 'CLIN Type',
        accessor: 'contractLineItemType',
      },
      {
        Header: 'Pricing Options',
        accessor: 'pricingOption',
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ value }) => {
          return formatDataValue(value, true);
        },
      },
      {
        Header: 'Actions',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: (props) => (
          <AFPTableRowAction
            actions={actionList}
            // eslint-disable-next-line react/prop-types
            onSelectAction={(evt) => {
              // eslint-disable-next-line react/prop-types
              handleSelectedRow(evt, props.row);
            }}
            {...props}
          />
        ),
      },
    ],
    [],
  );

  return (
    <div className="grid-col-9">
      <div className="contract-line-item-table">
        {setTaskOrderMode && (
          <AFPTable
            fullWidth
            ref={tableRef}
            testId="contract-line-item-table"
            columns={columns}
            // eslint-disable-next-line react/destructuring-assignment
            data={clinData}
            hiddenColumns={
              setTaskOrderMode === 'VIEW_TASK_ORDER' ? ['Actions'] : ''
            }
          />
        )}
      </div>
      {setTaskOrderMode !== 'VIEW_TASK_ORDER' && <AddContractLineItem />}
      <ContractLineItemModal />
    </div>
  );
};

export default ContractLineItemTable;
