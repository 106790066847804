import React from 'react';
import { Button } from '@gsa/afp-component-library';
import Breadcrumbs from '../../widgets/breadcrumbs';
import WelcomeMessage from '../../widgets/welcome-message';
import AppointmentScheduler from './AppointmentScheduler';
import MarshallingScheduleProvider from './marshalling-schedule-provider';
import MarshallingScheduleAlert from './marshalling-schedule-alert';

const MarshallingSchedule = () => {
  document.title = "AFP - Marshaller's Vehicle Exchange Scheduling";
  return (
    <div className="grid-col sales-disposal-page">
      <MarshallingScheduleProvider>
        <>
          <Breadcrumbs
            path={[
              {
                location: `${window.AFP_CONFIG.appURLs.home}/home`,
                label: 'Home',
              },
            ]}
            current="Marshalling Management"
          />
          <MarshallingScheduleAlert />
          <h1>Marshaller&apos;s Vehicle Exchange Scheduling</h1>
          <WelcomeMessage message="This Vehicle Exchange Scheduling page allows a marshalling location to establish their hours of operation and to denote the periods of time, on a day-by-day basis, when vehicle exchanges may be scheduled." />
          <div className="display-flex flex-column ">
            <div className="margin-right-10">
              <Button
                data-testid="view-scheduled-vehicle-exchange-button"
                className="display-flex margin-left-auto margin-top-4"
                type="button"
              >
                View scheduled vehicle exchange
              </Button>
            </div>
            <AppointmentScheduler />
          </div>
        </>
      </MarshallingScheduleProvider>
    </div>
  );
};

export default MarshallingSchedule;
