/* eslint-disable react/button-has-type */
import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import AutoCompleteInput from '../../../components/autocomplete-input';
import { useFilter } from '../../../components/filter/filter-provider';
import { GET_SALES_LIST_SALE_NUMBERS } from '../sales-listing.gql';

const SaleNumberFilter = () => {
  const { filterState, onFilterItemChange } = useFilter();
  const [options, setOptions] = useState([]);

  const [fetchOptions] = useLazyQuery(GET_SALES_LIST_SALE_NUMBERS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getSalesList }) => {
      let filteredOptions = ['Error: 404'];
      if (getSalesList && getSalesList?.rows?.length) {
        filteredOptions = getSalesList.rows.map((o) => ({
          key: o.saleNumber,
          label: o.saleNumber,
          ...o,
        }));
      }
      setOptions(filteredOptions);
    },
  });

  const getOptions = (searchQuery) => {
    fetchOptions({
      variables: {
        limit: 25,
        filters: [
          {
            conditions: [
              { operator: '$like', key: 'saleNumber', value: searchQuery },
            ],
            operator: '$and',
          },
        ],
      },
    });
  };

  return (
    <AutoCompleteInput
      id="sale-number-filter"
      data-testid="sale-number-filter"
      value={filterState?.saleNumber}
      options={options}
      onSelect={(selected) => {
        onFilterItemChange({ id: 'saleNumber', value: selected });
      }}
      onFilter={(keyword) => {
        getOptions(keyword);
      }}
      inputProps={{
        placeholder: 'Sale Number',
      }}
    />
  );
};

export default SaleNumberFilter;
