import React from 'react';
import PropTypes from 'prop-types';
import { StatusTag } from '@gsa/afp-component-library';

const colors = {
  green: 'green',
  grey: 'gray-30',
};

const statusMap = {
  Active: colors.green,
  Inactive: colors.grey,
};

const TaskOrderStatusTag = ({ statusCode }) => {
  if (!statusCode) {
    return null;
  }
  const status = statusMap[statusCode];
  return <StatusTag color={status}>{statusCode}</StatusTag>;
};

TaskOrderStatusTag.defaultProps = {
  statusCode: undefined,
};

TaskOrderStatusTag.propTypes = {
  statusCode: PropTypes.string,
};

export default TaskOrderStatusTag;
