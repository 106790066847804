import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import DesiredPriceField from './desired-price-field';
import { useVehicleSales } from '../vehicle-sales-provider';

const VehicleSalesDesiredPriceEdit = () => {
  const { vehicleSalesSelected, updateVehicleSaleDesiredPrice } =
    useVehicleSales();
  const methods = useForm();
  const onSubmit = ({ desiredPrice }) => {
    updateVehicleSaleDesiredPrice({
      variables: {
        vin: vehicleSalesSelected?.vin,
        desiredPrice: !desiredPrice || desiredPrice === '' ? 0 : desiredPrice,
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="vehicle-sales-update-form"
        id="vehicle-sales-update-form"
        className="usa-form usa-form--large"
        onSubmit={methods.handleSubmit(onSubmit)}
      />
      <DesiredPriceField />
    </FormProvider>
  );
};

export default VehicleSalesDesiredPriceEdit;
