import React from 'react';
import { DateTime } from 'luxon';

// eslint-disable-next-line import/prefer-default-export
export const currencyFormat = (num = 0, decimalPlace = 2) => {
  return `$${num
    .toFixed(decimalPlace)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const formatDataValue = (value, currency = false) => {
  if (typeof value === 'string' && value?.length) return value;

  if (typeof value === 'number') {
    return currency ? currencyFormat(value) : value;
  }

  return <>&ndash;</>;
};

export const formatInputDateToUTC = (dateTime, format) => {
  return DateTime.fromFormat(dateTime, format).toUTC().toISO();
};

export const getLocalTimeZoneIndicator = () => {
  return DateTime.now().toFormat('- (ZZZZ)');
};

export const formatDate = (value, format = 'LL/dd/yyyy • ccc') => {
  if (!value) return null;
  return DateTime.fromISO(value).toFormat(format);
};
