import { Button, CounterTag, Icon } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { useSalesDisposal } from '../sales-disposal-provider';

const LotToSaleAction = ({ onClearSelection }) => {
  const {
    vehicleSaleRowsSelected,
    salesDisposalList: { count },
    setSalesDisposalData,
  } = useSalesDisposal();

  const handleLotToSale = () => {
    setSalesDisposalData('SET_SHOW_MODAL', true);
    setSalesDisposalData('SET_MODAL_MODE', 'ADD_VEHICLE_SALE');
  };

  return (
    <div className="grid-col margin-top-10 margin-bottom-2">
      <div className="grid-row">
        <div className="grid-col-12 grid-offset-2">
          <div className="grid-row minh-6">
            <div className="tablet:grid-col-6  display-flex ">
              <div className="grid-row grid-gap flex-row">
                <div className="grid-col-auto text-bold flex-align-self-center">
                  Total vehicles: {count}
                </div>
                <div className="grid-col-auto text-bold flex-align-self-center">
                  Current selections:
                  <CounterTag
                    count={vehicleSaleRowsSelected?.length}
                    color="primary"
                  />
                </div>
                <div className="grid-col-auto">
                  {vehicleSaleRowsSelected.length ? (
                    <Button
                      variant="unstyled"
                      className="width-auto margin-top-2 text-middle"
                      onClick={onClearSelection}
                    >
                      Unselect
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="tablet:grid-col-6 display-flex flex-column flex-align-end">
              {vehicleSaleRowsSelected?.length ? (
                <Button
                  data-testid="lot-to-sale"
                  onClick={handleLotToSale}
                  className="margin-x-0"
                >
                  <Icon className="text-bottom margin-right-1" iconName="add" />
                  Lot to Sale
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LotToSaleAction.propTypes = {
  onClearSelection: PropTypes.func.isRequired,
};

export default LotToSaleAction;
