/* eslint-disable react/prop-types */
import {
  AFPTable,
  AFPTableRowAction,
  Button,
  Icon,
  Pagination,
  Tag,
} from '@gsa/afp-component-library';
import { Can, useAppAbility } from '@gsa/afp-shared-ui-utils';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import DisposalStatusTag from '../../components/disposal-status-tag';
import useDidMountEffect from '../../hooks/use-did-mount';
import { formatDataValue, formatDate } from '../../utils/formatting';
import { SalesLocationType } from '../sales-disposal/sales-disposal-proptypes';
import SalesListingModal from './forms/sales-listing-modal';
import SalesListingFilter from './sales-listing-filter';
import { SaleType } from './sales-listing-proptypes';
import { useSalesListing } from './sales-listing-provider';
import SaleNumberCell from './widgets/sale-number-cell';

const actionList = [
  {
    icon: 'content_copy',
    label: 'Duplicate Sale',
    canShowIndicator: 'canDuplicateSale',
    operation: 'create',
  },
  {
    icon: 'edit',
    label: 'Update',
    canShowIndicator: 'canUpdateSale',
    operation: 'update',
  },
  {
    icon: 'delete',
    label: 'Cancel',
    canShowIndicator: 'canCancelSale',
    operation: 'update',
  },
];

const initFilters = [
  {
    conditions: [],
    operator: '$and',
  },
];

const BG_COLORS = {
  LIVE: 'bg-green',
  INTERNET: 'bg-green',
  SALVAGE: 'bg-gold',
  FIXEDPRICE: 'bg-primary-light',
};

const TEXT_COLORS = {
  LIVE: 'text-white',
  INTERNET: 'text-white',
  SALVAGE: 'text-ink',
  FIXEDPRICE: 'text-ink',
};

const SaleLocation = ({ location }) => {
  const name = location?.name ? location.name : '';
  const state = location?.stateCode ? `,${location.stateCode}` : '';

  if (!name.length) return <>&ndash;</>;

  return <>{`${name} ${state}`}</>;
};
SaleLocation.propTypes = SalesLocationType;

const SaleTypeType = ({ data, value }) => {
  const cName = `sales-type__tag text-center text-middle text-no-wrap ${
    TEXT_COLORS[data.code]
  } ${BG_COLORS[data.code]}`;

  return (
    <>
      {value ? (
        <Tag data-tip={data.title} className={cName}>
          {data.title}
        </Tag>
      ) : (
        <>&ndash;</>
      )}
      <ReactTooltip effect="solid" />
    </>
  );
};
SaleTypeType.propTypes = SaleType;

const GetFormattedInspectionDate = ({ startDate, finishDate }) => {
  // 04/21/2021 • Wed.  10:00 a.m. - 5:30 p.m.
  const parseInspectionDate = DateTime.fromISO(startDate).toFormat(
    'LL/dd/yyyy • ccc. hh:mm a',
  );
  const endTime = DateTime.fromISO(finishDate).toLocaleString(
    DateTime.TIME_SIMPLE,
  );

  const timeZoneIndicator = DateTime.fromISO(startDate).toFormat('(ZZZZ)');
  return `${parseInspectionDate} - ${endTime} ${timeZoneIndicator}`;
};

const FormatInspectionDates = ({ startDate, finishDate }) => {
  return (
    <>
      {startDate ? (
        <div>{GetFormattedInspectionDate({ startDate, finishDate })}</div>
      ) : (
        <>&ndash;</>
      )}
    </>
  );
};

const SalesListingTable = () => {
  const [filters, setFilters] = useState(initFilters);
  const [order, setOrder] = useState('saleStartDate DESC');

  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const { getSalesList, salesList, setSalesListingData } = useSalesListing();

  const tableRef = React.createRef();
  const ability = useAppAbility();
  const getData = () => {
    const { limit, offset } = paginationState;
    getSalesList({
      variables: { filters, limit, offset, order },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useDidMountEffect(() => {
    getData();
  }, [filters, order, paginationState]);

  const handleSelectedAction = (e, row) => {
    setSalesListingData('SET_SELECTED', row?.original);

    if (e === 'Duplicate Sale')
      setSalesListingData('SET_MODAL_MODE', 'DUPLICATE_SALES_LISTING');
    else if (e === 'Update') {
      setSalesListingData('SET_MODAL_MODE', 'UPDATE_SALES_LISTING');
    } else {
      setSalesListingData('SET_MODAL_MODE', 'CANCEL_SALES_LISTING');
    }
    setSalesListingData('SET_SHOW_MODAL', true);
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Sale Location',
        accessor: 'saleLocation.name',
        sortable: false,
        Cell: ({ row: { original } }) => {
          const { saleLocation } = original;
          const name = saleLocation?.name ? saleLocation.name : '';
          const state = saleLocation?.stateCode
            ? `,${saleLocation.stateCode}`
            : '';
          return formatDataValue(`${name}${state}`);
        },
      },
      {
        Header: 'Sale Type',
        accessor: 'saleType.code',
        Cell: ({ value, row: { original } }) => (
          <SaleTypeType data={original?.saleType} value={value} />
        ),
      },
      {
        Header: 'Sale Status',
        accessor: 'saleStatus.code',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return (
            <DisposalStatusTag
              // eslint-disable-next-line react/prop-types
              statusCode={original?.saleStatus?.code}
              // eslint-disable-next-line react/prop-types
              statusDescription={original?.saleStatus?.title}
            />
          );
        },
      },
      {
        Header: 'Start Date',
        accessor: 'saleStartDate',
        Cell: ({ value }) => formatDataValue(formatDate(value)),
      },
      {
        Header: 'End Date',
        accessor: 'saleFinishDate',
        Cell: ({ value }) => formatDataValue(formatDate(value)),
      },
    ];

    const allowedActions = actionList.filter((a) =>
      ability.can(a.operation, 'SaleListing'),
    );

    if (allowedActions.length > 0) {
      columnList.push({
        Header: 'Actions',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: (props) => (
          <AFPTableRowAction
            actions={allowedActions}
            // eslint-disable-next-line react/prop-types
            onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
            {...props}
          />
        ),
      });
    }

    return columnList;
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return (
      <div className="grid-container sales-disposal-sub-component-wrapper">
        <div className="grid-row grid-gap-6">
          <div className="tablet:grid-col desktop:margin-bottom-4">
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Sale Number</div>
              <div className="tablet:grid-col text-right">
                <SaleNumberCell data={original} />
              </div>
            </div>
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Inspection Date and Time 1</div>
              <div className="tablet:grid-col text-right">
                <FormatInspectionDates
                  startDate={original?.inspectionStartDate1}
                  finishDate={original?.inspectionFinishDate1}
                />
              </div>
            </div>
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Inspection Date and Time 2</div>
              <div className="tablet:grid-col text-right">
                <FormatInspectionDates
                  startDate={original?.inspectionStartDate2}
                  finishDate={original?.inspectionFinishDate2}
                />
              </div>
            </div>
          </div>
          <div className="tablet:grid-col">
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Attachment</div>
              <div className="tablet:grid-col text-right">&ndash;</div>
            </div>
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Edited by</div>
              <div className="tablet:grid-col text-right">
                {original?.createdByUser ?? <>&ndash;</>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const formatFilter = useCallback((filterState) => {
    const filterObject = {
      operator: '$and',
      conditions: [],
    };

    // Sale Number filter
    if (filterState?.saleNumber)
      filterObject.conditions.push({
        operator: '$exact',
        key: 'saleNumber',
        value: filterState.saleNumber.saleNumber,
      });

    // Sale status
    if (filterState?.saleStatus) {
      filterObject.conditions.push({
        operator: '$in',
        key: 'saleStatusCode',
        value: filterState.saleStatus,
      });
    }

    // Sale type
    if (filterState?.saleType) {
      filterObject.conditions.push({
        operator: '$in',
        key: 'saleTypeCode',
        value: filterState.saleType,
      });
    }

    // Sale date
    if (filterState?.saleStartDate) {
      const from = DateTime.fromFormat(filterState?.saleStartDate, 'D')
        .startOf('day')
        .toUTC()
        .toISO();
      const to = DateTime.fromFormat(filterState?.saleStartDate, 'D')
        .endOf('day')
        .toUTC()
        .toISO();

      filterObject.conditions.push({
        operator: '$between',
        key: 'saleStartDate',
        value: [from, to],
      });
    }

    // Inspection date
    if (filterState?.inspectionStartDate1) {
      const from = DateTime.fromFormat(filterState?.inspectionStartDate1, 'D')
        .startOf('day')
        .toUTC()
        .toISO();
      const to = DateTime.fromFormat(filterState?.inspectionStartDate1, 'D')
        .endOf('day')
        .toUTC()
        .toISO();

      filterObject.conditions.push({
        operator: '$between',
        key: 'inspectionStartDate1',
        value: [from, to],
      });
    }

    // Location name
    if (filterState?.saleLocation) {
      // saleLocationCode
      filterObject.conditions.push({
        operator: '$exact',
        key: 'saleLocationCode',
        value: filterState?.saleLocation?.id,
      });

      // saleLocationType
      filterObject.conditions.push({
        operator: '$exact',
        key: 'saleLocationType',
        value: filterState?.saleLocation?.subsectionTypeCode,
      });
    }

    setFilters(filterObject);
  }, []);

  return (
    <>
      <Can I="create" a="SaleListing">
        <div className="grid-row margin-bottom-2">
          <div className="grid-col">
            <div className="float-right">
              <Button
                onClick={() => {
                  setSalesListingData('SET_SHOW_MODAL', true);
                  setSalesListingData('SET_MODAL_MODE', 'ADD_SALES_LISTING');
                }}
              >
                <Icon className="text-bottom margin-right-1" iconName="add" />
                Create a new sale
              </Button>
            </div>
          </div>
        </div>
      </Can>
      <div className="sales-listing-table grid-row grid-gap-2">
        <div className="tablet:grid-col-2 tablet:padding-top-4 ">
          <SalesListingFilter onFilter={formatFilter} />
        </div>
        <div className="tablet:grid-col-10">
          <AFPTable
            expandable
            fullWidth
            ref={tableRef}
            testId="sales-listing-table"
            columns={columns}
            data={salesList.rows}
            renderRowSubComponent={renderRowSubComponent}
            onSort={setOrder}
            defaultSort={order}
          />
          <div className="margin-y-6">
            <Pagination
              fullWidth
              variant="advanced"
              itemsPerPageOptions={[10, 25, 50]}
              itemsCount={salesList?.count}
              itemsPerPage={paginationState.limit}
              currentPage={paginationState.currentPage}
              onPageChange={handlePaginationChange}
            />
          </div>
          <SalesListingModal />
        </div>
      </div>
    </>
  );
};

export default SalesListingTable;
