import React from 'react';
import BannerMessage from '../../components/banner-message';
import { useSalesDisposal } from './sales-disposal-provider';

const SalesDisposalAlert = () => {
  const {
    salesDisposalMessage: { type, message },
    setSalesDisposalData,
  } = useSalesDisposal();

  if (!type || !message) return null;

  return (
    <BannerMessage
      type={type}
      message={message}
      onClose={() => {
        setSalesDisposalData('SET_MESSAGE', { type: 'success', message: '' });
      }}
    />
  );
};

export default SalesDisposalAlert;
