/* eslint-disable react/prop-types */
import {
  AFPTable,
  AFPTableRowAction,
  Button,
  Pagination,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import EmptyIconSVG from '../../assets/images/icon-empty.svg';
import useDidMountEffect from '../../hooks/use-did-mount';
import { currencyFormat, formatDataValue } from '../../utils/formatting';
import WelcomeMessage from '../../widgets/welcome-message';
import DisposalCondition from '../sales-disposal/widgets/disposal-condition';
import VehicleSalesAlert from './vehicle-sales-alert';
import VehicleSalesFilter from './vehicle-sales-filter';
import VehicleSalesModal from './vehicle-sales-modal';
import { vehicleTableCellType } from './vehicle-sales-proptypes';
import { useVehicleSales } from './vehicle-sales-provider';

const actionList = [
  {
    icon: 'edit',
    label: 'Edit desired price',
    canShowIndicator: 'canEditDesiredPrice',
    operation: 'update',
  },
  {
    icon: 'edit',
    label: 'Update run order number',
    canShowIndicator: 'canUpdateRunOrderNumber',
    operation: 'update',
  },
  {
    icon: 'delete',
    label: 'Remove',
    canShowIndicator: 'canRemoveVehicle',
    operation: 'delete',
  },
];

const initFilters = [
  {
    conditions: [],
    operator: '$and',
  },
];

const VehicleTableCell = ({ title, vin }) => {
  const vehicleURL = `${window.AFP_CONFIG.appURLs.vms}/vehicle/${vin}`;
  const handleClick = () => {
    window.open(vehicleURL);
  };
  return (
    <div>
      <div>
        <strong>{title}</strong>
      </div>
      <Button
        data-testid={`vehicle-detail-link-${vin}`}
        variant="unstyled"
        onClick={handleClick}
      >
        {vin}
      </Button>
    </div>
  );
};
VehicleTableCell.propTypes = vehicleTableCellType;

const VehicleSalesTable = ({ title, welcomeMessage }) => {
  const [filters, setFilters] = useState(initFilters);
  const [order, setOrder] = useState('runNumber DESC');
  const { saleNumber } = useParams();

  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const {
    getVehicleSaleList,
    vehicleSalesList,
    setVehicleSalesData,
    vehicleSaleListLoading,
    vehicleSalesModalShow,
  } = useVehicleSales();

  const tableRef = React.createRef();
  const ability = useAppAbility();

  const getData = () => {
    const { limit, offset } = paginationState;
    getVehicleSaleList({
      variables: { filters, limit, offset, order },
    });
  };

  useDidMountEffect(() => {
    getData();
  }, [filters, order, paginationState]);

  const handleSelectedAction = (e, row) => {
    setVehicleSalesData('SET_SELECTED', row?.original);
    setVehicleSalesData('SET_MESSAGE', { type: 'success', message: '' });

    if (e === 'Edit desired price')
      setVehicleSalesData('SET_MODAL_MODE', 'UPDATE_DESIRED_PRICE');

    if (e === 'Update run order number')
      setVehicleSalesData('SET_MODAL_MODE', 'UPDATE_RUN_ORDER_NUMBER');

    if (e === 'Remove') setVehicleSalesData('SET_MODAL_MODE', 'REMOVE_VEHICLE');

    setVehicleSalesData('SET_SHOW_MODAL', true);
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'VIN',
        accessor: 'vin',
        Cell: ({ value, row: { original } }) => {
          const carTitle = `${original?.vehicleListing?.modelYear} ${original?.vehicleListing?.makeName} ${original?.vehicleListing?.modelName}`;
          return <VehicleTableCell title={carTitle} vin={value} />;
        },
      },
      {
        Header: 'Condition Code',
        accessor: 'vehicleListing.disposalCondition.code',
        cellClassName: 'cell-center',
        headerClassName: 'cell-center',
        Cell: ({ row: { original } }) => (
          <DisposalCondition
            disposalCondition={original?.vehicleListing?.disposalCondition}
          />
        ),
      },
      {
        Header: 'Third Party Value',
        accessor: 'marketValue.moodysValue',
        Cell: ({ value }) => {
          return value ? currencyFormat(value) : <>&ndash;</>;
        },
      },
      {
        Header: 'Historic Value',
        accessor: 'vehicleListing.historicValue',
        Cell: ({ value }) => {
          return value ? currencyFormat(value) : <>&ndash;</>;
        },
      },
      {
        Header: 'Desired Price',
        accessor: 'desiredPrice',
        Cell: ({ value }) => {
          return value ? currencyFormat(value) : <>&ndash;</>;
        },
      },
      {
        Header: 'Run Number',
        accessor: 'runNumber',
        Cell: ({ value }) => formatDataValue(value),
      },
    ];

    const allowedActions = actionList.filter((a) =>
      ability.can(a.operation, 'VehicleSale'),
    );

    if (allowedActions.length > 0) {
      columnList.push({
        Header: 'Actions',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: (props) => (
          <AFPTableRowAction
            actions={allowedActions}
            // eslint-disable-next-line react/prop-types
            onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
            {...props}
          />
        ),
      });
    }
    return columnList;
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return (
      <div className="grid-container sales-disposal-sub-component-wrapper">
        <div className="grid-row grid-gap-6">
          <div className="tablet:grid-col desktop:margin-bottom-4">
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Sale Contract Number</div>
              <div className="tablet:grid-col text-right">
                {original?.saleContractNumber ?? <>&ndash;</>}
              </div>
            </div>
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Lot Number</div>
              <div className="tablet:grid-col text-right">
                {original?.lotNumber ?? <>&ndash;</>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const formatFilter = useCallback((filterState) => {
    const filterObject = {
      operator: '$and',
      conditions: [],
    };

    // Filter results based on sale number in params
    if (saleNumber)
      filterObject.conditions.push({
        operator: '$exact',
        key: 'saleNumber',
        value: `${saleNumber}`,
      });

    // VIN
    if (filterState?.vin)
      filterObject.conditions.push({
        operator: '$exact',
        key: 'vin',
        value: filterState.vin.vin,
      });

    // Vehicle
    if (filterState?.makeCode) {
      // Make
      if (filterState?.makeCode)
        filterObject.conditions.push({
          operator: '$exact',
          key: '$vehicleListing.make_code$',
          value: filterState.makeCode,
        });

      // Model
      if (filterState?.modelCode)
        filterObject.conditions.push({
          operator: '$exact',
          key: '$vehicleListing.model_code$',
          value: filterState.modelCode,
        });
    }

    // Year
    if (filterState?.modelYear)
      filterObject.conditions.push({
        operator: '$exact',
        key: '$vehicleListing.model_year$',
        value: filterState.modelYear,
      });

    // Condition code
    if (filterState?.disposalCondition) {
      filterObject.conditions.push({
        operator: '$in',
        key: '$vehicleListing.disposal_condition_code$',
        value: filterState.disposalCondition,
      });
    }

    // Run number
    if (filterState?.runNumber)
      filterObject.conditions.push({
        operator: '$exact',
        key: 'runNumber',
        value: filterState.runNumber,
      });

    // Sale contract number
    if (filterState?.saleContractNumber)
      filterObject.conditions.push({
        operator: '$exact',
        key: 'saleContractNumber',
        value: filterState.saleContractNumber.saleContractNumber,
      });

    // Lot number
    if (filterState?.lotNumber)
      filterObject.conditions.push({
        operator: '$exact',
        key: 'lotNumber',
        value: filterState.lotNumber.lotNumber,
      });

    setFilters(filterObject);
  }, []);

  return (
    <>
      {!vehicleSalesModalShow && <VehicleSalesAlert />}
      <h1>{title || 'Sales'}</h1>
      <WelcomeMessage message={welcomeMessage} />
      <div className="vehicle-sales-table grid-row grid-gap-2">
        <div className="tablet:grid-col-2 tablet:padding-top-4 ">
          <VehicleSalesFilter onFilter={formatFilter} />
        </div>
        <div className="tablet:grid-col-10">
          <AFPTable
            expandable
            fullWidth
            ref={tableRef}
            testId="vehicle-sales-table"
            columns={columns}
            data={vehicleSalesList.rows}
            renderRowSubComponent={renderRowSubComponent}
            onSort={setOrder}
            defaultSort={order}
          />
          <div className="margin-y-6">
            <Pagination
              fullWidth
              variant="advanced"
              itemsPerPageOptions={[10, 25, 50]}
              itemsCount={vehicleSalesList?.count}
              itemsPerPage={paginationState.limit}
              currentPage={paginationState.currentPage}
              onPageChange={handlePaginationChange}
            />
            {!vehicleSaleListLoading && !vehicleSalesList?.rows?.length ? (
              <div
                className="vehicle-sale-table-no-listings display-flex flex-column"
                data-testid="vehicle-sale-table-no-listings"
              >
                <div className="title display-flex flex-row flex-justify-center">
                  <strong>No Results Available</strong>
                </div>
                <div className="description display-flex flex-row flex-justify-center margin-top-2">
                  There are no matches for the filtered values at left.
                </div>
                <div className="display-flex flex-row flex-justify-center margin-top-2">
                  <img src={EmptyIconSVG} alt="Empty Vehicle Sale Listing" />
                </div>
              </div>
            ) : null}
          </div>
          <VehicleSalesModal />
        </div>
      </div>
    </>
  );
};

export default VehicleSalesTable;
