import React from 'react';
import ModifyConditionCodeForm from './forms/modify-condition-code-form';
import { useSalesDisposal } from './sales-disposal-provider';
import DisposalCondition from './widgets/disposal-condition';

const ModifyConditionCode = () => {
  const {
    salesDisposalSelected: {
      disposalCondition,
      modelYear,
      makeName,
      modelName,
      vin,
    },
  } = useSalesDisposal();

  return (
    <>
      <div className="grid-col-8 margin-top-6">
        <p>Please use this window to change current Condition Code.</p>

        <div className="grid-row grid-gap">
          <div className="grid-col-auto">
            <DisposalCondition disposalCondition={disposalCondition} />
          </div>
          <div className="grid-col-auto text-bold">
            {modelYear ?? ''} {makeName ?? ''} {modelName ?? ''}
          </div>
          <div className="grid-col">{vin ?? ''}</div>
        </div>
      </div>
      <div className="margin-y-4 border-top-1px border-base-lighter" />
      <ModifyConditionCodeForm />
    </>
  );
};

export default ModifyConditionCode;
