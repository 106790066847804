import { Button } from '@gsa/afp-component-library';
import { DateTime } from 'luxon';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SaleNumberTableCellType } from '../sales-listing-proptypes';

const SaleNumberCell = ({ data }) => {
  const history = useHistory();
  const titleData = {};
  titleData.saleNumber = data?.saleNumber;
  titleData.auctionHouseName = data?.saleLocation?.name;
  titleData.saleDate = data?.saleStartDate
    ? DateTime.fromISO(data.saleStartDate).toFormat('LL/dd/yyyy')
    : null;
  titleData.saleType = data?.saleType?.title;
  const handleClick = () => {
    history.push(`/vehicle-sales/${data?.saleNumber}`, titleData);
  };
  return (
    <>
      {data?.saleNumber ? (
        <Button
          data-testid="sale-number-detail-link"
          variant="unstyled"
          onClick={handleClick}
        >
          {data?.saleNumber}
        </Button>
      ) : (
        <>&ndash;</>
      )}
    </>
  );
};
SaleNumberCell.propTypes = SaleNumberTableCellType;

export default SaleNumberCell;
