/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';
import classNames from 'classnames';
import { useMarshallingSchedule } from '../marshalling-schedule-provider';
import './schedule-table.scss';

const DateTimeCell = ({
  date,
  hour,
  dayServiceHours: [am, pm],
  onDateSelected,
  blockedHours = [],
  bookedHours = [],
  unavailableHours = [],
  holidays,
}) => {
  const { appointmentStartTime } = useMarshallingSchedule();

  const cellDateTime = moment(`${date} ${hour}`, 'YYYY-MM-DD h:mm a').format();
  let isServiceHour = false;
  let selectedTimeSlot = false;
  let isBlocked = false;
  let isHoliday = false;
  let isBooked = false;
  let isUnavailable = false;
  const nextDay = moment().add(1, 'day').format();
  if (moment(cellDateTime).isSame(appointmentStartTime)) {
    selectedTimeSlot = true;
  }
  // Check if it's a service hour
  if (
    moment(hour, 'h:mm a').isBetween(
      moment(am[0], 'h:mm a'),
      moment(am[1], 'h:mm a'),
      null,
      '[)',
    ) ||
    moment(hour, 'h:mm a').isBetween(
      moment(pm[0], 'h:mm a'),
      moment(pm[1], 'h:mm a'),
      null,
      '[)',
    )
  ) {
    isServiceHour = true;
  }

  // Check if it's a blocked hour, converting to UTC because the blocked hours are stored in UTC
  if (blockedHours.includes(moment(cellDateTime).utc().format()))
    isBlocked = true;

  if (holidays.includes(date)) isHoliday = true;
  if (unavailableHours.includes(moment(cellDateTime).utc().format()))
    isUnavailable = true;

  // Check if it's a booked hour, converting to UTC because the blocked hours are stored in UTC
  if (bookedHours.includes(moment(cellDateTime).utc().format()))
    isBooked = true;

  const isClickable = isServiceHour && !isBooked && !isHoliday;

  let cellText;
  if (isBlocked) {
    cellText = 'Blocked';
  } else if (isBooked) {
    cellText = 'Blocked';
  } else if (selectedTimeSlot) {
    cellText = 'Block';
  } else if (cellDateTime <= nextDay || isUnavailable) {
    cellText = 'Unavailable';
  }
  const cellClasses = classNames('schedule-table__active-cell', {
    'schedule-table__disabled-cell':
      !isClickable || cellDateTime <= nextDay || isUnavailable,
    'today-color': moment(moment().format('YYYY-MM-DD')).isSame(moment(date)),
    'booked-color': selectedTimeSlot,
    'blocked-color': isBlocked || isHoliday,
    holiday: isHoliday,
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <td
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      className={cellClasses}
      onClick={() => {
        if (cellDateTime >= nextDay && !isUnavailable)
          onDateSelected(cellDateTime);
      }}
    >
      {isBlocked || selectedTimeSlot ? (
        <div className="display-flex">
          {isBlocked ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              className="margin-right-2 margin-left-1"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                fill="black"
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              className="margin-right-2 margin-left-1"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                fill="white"
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
              />
            </svg>
          )}
          {cellText}
        </div>
      ) : (
        (cellDateTime <= nextDay || isUnavailable) && (
          <div className="display-flex flex-justify-center">{cellText}</div>
        )
      )}
    </td>
  );
};

const getWeekDays = (date) => {
  const days = [];
  const start = moment(date).startOf('isoWeek');

  while (start.format('ddd') !== 'Sat') {
    days.push({
      formatted: start.format('ddd DD'),
      date: start.format('YYYY-MM-DD'),
      day: start.format('dddd'),
    });
    start.add(1, 'days');
  }
  return days;
};

const getScheduleHours = (interval = 15) => {
  const hours = [];
  const start = moment().set({ hour: 9, minute: 0, second: 0 }); // 9:00 am
  const end = '4:00 pm';

  while (start.isBefore(moment(end, 'h:mm a', 'hour'))) {
    hours.push(start.format('h:mm a'));
    start.add(interval, 'minutes');
  }
  hours.push(end);
  const resultHours = [];
  hours.forEach((hour) => {
    if (hour === '12:00 pm') {
      resultHours.push(hour.replace('12:00 pm', 'Noon'));
    } else if (hour.substring(hour.length - 2) === 'am') {
      resultHours.push(hour.replace('am', 'a.m.'));
    } else if (hour.substring(hour.length - 2) === 'pm') {
      resultHours.push(hour.replace('pm', 'p.m.'));
    }
  });

  return resultHours;
};

const ScheduleTable = ({
  dateSelected,
  intervalMinutes = 15, // minutes
  serviceHours = {},
  blockedHours = [],
  bookedHours = [],
  unavailableHours = [],
  onDateSelected,
  holidays,
}) => {
  const weekDays = getWeekDays(dateSelected);
  return (
    <div className="schedule-table margin-left-5">
      <table className="usa-table usa-table--full-width">
        <thead>
          <tr>
            <th className="time-slot" key="spacer" scope="col">
              &nbsp;
            </th>
            {weekDays.map(({ date, day }, i) => (
              <th tabIndex={0} key={i} scope="col">
                <div>{day}</div>
                <div>{moment(date).format('MM/DD/YYYY')}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {getScheduleHours(intervalMinutes).map((hour, i) => (
            <tr key={hour}>
              <th className="time-slot" scope="row">
                <div
                  className={
                    // eslint-disable-next-line prefer-template
                    `time-slot-start ${
                      i % 4 === 0 ? 'text-bold' : 'time-slot-start'
                    }`
                  }
                >
                  {hour === '12:00 pm' ? 'Noon' : hour}
                </div>
              </th>
              {weekDays.map((d, index) => {
                const day = d?.day ? d.day.toLowerCase() : '';
                const dayServiceHours = serviceHours[day] ?? [];

                return (
                  <DateTimeCell
                    key={index}
                    date={d.date}
                    hour={hour}
                    dayServiceHours={dayServiceHours}
                    blockedHours={blockedHours}
                    bookedHours={bookedHours}
                    unavailableHours={unavailableHours}
                    onDateSelected={(selectedDate) => {
                      onDateSelected(selectedDate);
                    }}
                    holidays={holidays}
                  />
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

DateTimeCell.defaultProps = {
  date: undefined,
  hour: undefined, // minutes
  dayServiceHours: undefined,
  onDateSelected: undefined,
  blockedHours: [],
  bookedHours: [],
  unavailableHours: [],
  holidays: [],
};

DateTimeCell.propTypes = {
  date: PropTypes.string,
  hour: PropTypes.string, // minutes
  dayServiceHours: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.string),
  ),
  blockedHours: PropTypes.arrayOf(PropTypes.string),
  bookedHours: PropTypes.arrayOf(PropTypes.string),
  unavailableHours: PropTypes.arrayOf(PropTypes.string),
  onDateSelected: PropTypes.func,
  holidays: PropTypes.arrayOf(PropTypes.string),
};

ScheduleTable.defaultProps = {
  dateSelected: undefined,
  intervalMinutes: undefined, // minutes
  serviceHours: {
    monday: [
      ['9:00 am', '12:00 pm'],
      ['1:00 pm', '4:00 pm'],
    ],
    tuesday: [
      ['9:00 am', '12:00 pm'],
      ['1:00 pm', '4:00 pm'],
    ],
    wednesday: [
      ['9:00 am', '12:00 pm'],
      ['1:00 pm', '4:00 pm'],
    ],
    thursday: [
      ['9:00 am', '12:00 pm'],
      ['1:00 pm', '4:00 pm'],
    ],
    friday: [
      ['9:00 am', '12:00 pm'],
      ['1:00 pm', '4:00 pm'],
    ],
  },
  blockedHours: undefined,
  bookedHours: undefined,
  onDateSelected: undefined,
  unavailableHours: undefined,
  holidays: ['2021-10-21', '2021-10-27'],
};

ScheduleTable.propTypes = {
  dateSelected: PropTypes.string,
  intervalMinutes: PropTypes.number, // minutes
  serviceHours: PropTypes.shape({
    monday: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.string),
    ),
    tuesday: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.string),
    ),
    wednesday: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.string),
    ),
    thursday: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.string),
    ),
    friday: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.string),
    ),
  }),
  blockedHours: PropTypes.arrayOf(PropTypes.string),
  bookedHours: PropTypes.arrayOf(PropTypes.string),
  unavailableHours: PropTypes.arrayOf(PropTypes.string),
  onDateSelected: PropTypes.func,
  holidays: PropTypes.arrayOf(PropTypes.string),
};

export default ScheduleTable;
