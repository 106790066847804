import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSalesDisposal } from '../sales-disposal-provider';
import CommentConditionCodeField from './comment-condition-code-field';
import SelectConditionCodeField from './select-condition-code-field';

const ModifyConditionCodeForm = () => {
  const methods = useForm();

  const {
    salesDisposalSelected: { vin },
    updateConditionCode,
  } = useSalesDisposal();

  const onSubmit = ({ disposalConditionCode, disposalConditionComment }) => {
    updateConditionCode({
      variables: {
        updateInput: { vin, disposalConditionCode, disposalConditionComment },
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="modify-condition-code-form"
        id="modify-condition-code-form"
        className="usa-form usa-form--large"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="usa-form-group">
          <SelectConditionCodeField />

          <CommentConditionCodeField />
        </div>
      </form>
    </FormProvider>
  );
};

export default ModifyConditionCodeForm;
