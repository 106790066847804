import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import { GET_MARSHALLING_VEHICLES_LIST } from './marshalling.gql';

export const MarshallingContext = createContext();

const initialState = {
  dataSelected: null,
  marshallingVehiclesList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  marshallingFilters: {
    makeFilter: [],
    modelFilter: [],
    yearFilter: [],
    statusFilter: [],
    customerFilter: [],
  },
  marshallingMessage: { type: '', message: '' },
  marshallingError: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MARSHALLING_VEHICLES_LIST':
      return { ...state, marshallingVehiclesList: action.payload };
    case 'SET_MARSHALLING_FILTERS':
      return {
        ...state,
        marshallingFilters: action.payload,
      };
    case 'SET_MESSAGE':
      return { ...state, marshallingMessage: action.payload };

    case 'SET_ERROR': {
      return { ...state, marshallingError: action.payload };
    }

    default:
      throw new Error('Invalid action');
  }
};

function MarshallingProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setMarshallingError = (type, payload) => {
    dispatch({
      type: 'SET_ERROR',
      payload: { ...state.salesDisposalError, [type]: payload },
    });
  };

  const setMarshallingData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const setRequestError = (requestError) => {
    setMarshallingError('SET_ERROR', requestError.message);
    setMarshallingError('SET_MESSAGE', {
      type: 'error',
      message: `${requestError.message} `,
    });
  };

  // Get vehicle list lazy.
  const [
    getMarshallingVehiclesList,
    { loading: marshallingVehiclesListLoading },
  ] = useLazyQuery(GET_MARSHALLING_VEHICLES_LIST, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: setRequestError,
    onCompleted: ({ getMarshallingVehiclesList: responseData }) => {
      if (getMarshallingVehiclesList) {
        setMarshallingData('SET_MARSHALLING_VEHICLES_LIST', responseData);
      }
    },
  });

  return (
    <MarshallingContext.Provider
      value={{
        ...state,
        setMarshallingError,
        setMarshallingData,
        getMarshallingVehiclesList,
        marshallingVehiclesListLoading,
        ...props,
      }}
    >
      {children}
    </MarshallingContext.Provider>
  );
}

export default MarshallingProvider;

MarshallingProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useMarshalling = () => useContext(MarshallingContext);
