import { gql } from '@apollo/client';

export const GET_DISPOSAL_LIST = gql`
  query getDisposalList(
    $filters: [Filter!]!
    $order: OrderBy!
    $limit: Int!
    $offset: Int!
  ) {
    getDisposalList(
      filters: $filters
      order: $order
      limit: $limit
      offset: $offset
    ) {
      rows {
        vin
        makeName
        modelName
        modelYear
        listingDate
        disposalStatus {
          code
          title
        }
        disposalStatusCode
        canBeLotted
        canBeAssociated
        currentLocation {
          typeCode
          code
          name
          stateCode
          postalCode
        }
        disposalConditionCode
        disposalCondition {
          code
          title
        }
        disposalConditionComment
        tag
        tagCount
        marketValue {
          moodysValue
        }
        historicValue
        association {
          sellingAgentCode
          sellingAgentType
          transportationType {
            code
            title
          }
          sellingLocation {
            typeCode
            code
            name
            stateCode
            postalCode
          }
          comment
          createdByUser
          updatedByUser
        }
        vehicleSale {
          saleListing {
            saleNumber
            saleStartDate
            saleType {
              title
            }
            saleLocation {
              name
            }
          }
        }
        daysToSell
        createdByUser
        updatedByUser
      }
      count
      hasMore
    }
  }
`;

export const GET_DISPOSAL_LIST_VINS = gql`
  query getDisposalList($limit: Int!, $filters: [Filter!]!) {
    getDisposalList(limit: $limit, filters: $filters) {
      rows {
        vin
      }
    }
  }
`;

export const GET_DISPOSAL_LIST_FILTERS = gql`
  query GetDisposalListFilters {
    getDisposalListFilters {
      makeFilter
      modelFilter
      yearFilter
      disposalStatusFilter
      conditionCodeFilter
      tagCountFilter
    }
  }
`;

export const ADD_VEHICLE_ASSOCIATION = gql`
  mutation AddVehicleAssociation(
    $vin: String!
    $sellingAgentCode: String!
    $sellingAgentType: String!
    $transportationTypeCode: String!
    $comment: String
  ) {
    addVehicleAssociation(
      association: {
        vin: $vin
        sellingAgentCode: $sellingAgentCode
        sellingAgentType: $sellingAgentType
        transportationTypeCode: $transportationTypeCode
        comment: $comment
      }
    ) {
      vin
    }
  }
`;

export const LOCATION_SEARCH = gql`
  query ($typeCodes: [String!]!, $nameQuery: String!, $limit: Float) {
    locationSearch(
      typeCodes: $typeCodes
      nameQuery: $nameQuery
      limit: $limit
    ) {
      id
      name
      subsectionTypeCode
    }
  }
`;

export const MARKETPLACE_LOOKUP = gql`
  query ($category: String, $order: OrderBy) {
    getLookupItems(category: $category, order: $order) {
      category
      code
      title
    }
  }
`;

export const GET_LOCATION = gql`
  query ($id: String!, $subsectionTypeCode: string!) {
    getLocation(id: $id, subsectionTypeCode: $subsectionTypeCode) {
      id
      name
      subsectionTypeCode
    }
  }
`;

export const ADD_VEHICLE_SALE = gql`
  mutation AddVehicleSale($saleNumber: String!, $vins: [String!]!) {
    addVehicleSale(vehicleSale: { saleNumber: $saleNumber, vins: $vins }) {
      saleNumber
      lotNumber
    }
  }
`;

export const UPDATE_CONDITION_CODE = gql`
  mutation UpdateConditionCode($updateInput: UpdateConditionCodeInput!) {
    updateConditionCode(updateInput: $updateInput) {
      vin
      disposalCondition {
        title
        code
      }
      disposalConditionComment
    }
  }
`;

export default {
  GET_DISPOSAL_LIST,
  GET_DISPOSAL_LIST_FILTERS,
  ADD_VEHICLE_ASSOCIATION,
  LOCATION_SEARCH,
  MARKETPLACE_LOOKUP,
  ADD_VEHICLE_SALE,
  UPDATE_CONDITION_CODE,
};
