import React from 'react';
import Breadcrumbs from '../../widgets/breadcrumbs';
import WelcomeMessage from '../../widgets/welcome-message';
import SalesListingProvider from './sales-listing-provider';
import SalesListingTable from './sales-listing-table';
import SalesListingAlert from './sales-listing-alert';

const SalesListing = () => {
  document.title = 'AFP - Sales Listing';
  return (
    <div className="grid-col sales-listing-page">
      <SalesListingProvider>
        <>
          <Breadcrumbs
            path={[
              {
                location: `${window.AFP_CONFIG.appURLs.home}/home`,
                label: 'Home',
              },
            ]}
            current="Sales Listing"
          />
          <SalesListingAlert />
          <h1>Sales Listing</h1>
          <WelcomeMessage message="On this page the SCO can view a listing of currently active/created sales. The SCO can sort each column and filter the list based on a variety of parameters (as shown on the left panel of this page)." />
          <SalesListingTable />
        </>
      </SalesListingProvider>
    </div>
  );
};

export default SalesListing;
