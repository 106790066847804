/* eslint-disable react/button-has-type */
import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import AutoCompleteInput from '../../../components/autocomplete-input';
import { useFilter } from '../../../components/filter/filter-provider';
import { GET_VEHICLE_SALE_LIST_CONTRACT_NUMBERS } from '../vehicle-sales.gql';

const SaleContractNumberFilter = () => {
  const { filterState, onFilterItemChange } = useFilter();
  const [options, setOptions] = useState([]);

  const [fetchOptions] = useLazyQuery(GET_VEHICLE_SALE_LIST_CONTRACT_NUMBERS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getVehicleSaleList }) => {
      let filteredOptions = ['Error: 404'];
      if (getVehicleSaleList && getVehicleSaleList?.rows?.length) {
        filteredOptions = getVehicleSaleList.rows.map((o) => ({
          key: o.saleContractNumber,
          label: o.saleContractNumber,
          ...o,
        }));
      }
      setOptions(filteredOptions);
    },
  });

  const getOptions = (searchQuery) => {
    fetchOptions({
      variables: {
        limit: 25,
        filters: [
          {
            conditions: [
              {
                operator: '$like',
                key: 'saleContractNumber',
                value: searchQuery,
              },
            ],
            operator: '$and',
          },
        ],
      },
    });
  };

  return (
    <AutoCompleteInput
      id="sale-contract-number-filter"
      data-testid="sale-contract-number-filter"
      value={filterState?.saleContractNumber}
      options={options}
      onSelect={(selected) => {
        onFilterItemChange({ id: 'saleContractNumber', value: selected });
      }}
      onFilter={(keyword) => {
        getOptions(keyword);
      }}
      inputProps={{
        placeholder: 'Search Sale Contract #',
      }}
    />
  );
};

export default SaleContractNumberFilter;
