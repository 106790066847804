/* eslint-disable react/button-has-type */
import { useLazyQuery } from '@apollo/client';
import React, { useState, useEffect, useRef, createRef } from 'react';
import { Label, Typeahead } from '@gsa/afp-component-library';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useFormContext } from 'react-hook-form';
import { GET_SALES_LIST_SALE_NUMBERS } from '../../sales-listing/sales-listing.gql';

const SaleNumberField = () => {
  const userTypedRef = useRef(false);
  const mounted = createRef(true);
  const [options, setOptions] = useState({ values: [], raw: [] });

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [fetchOptions] = useLazyQuery(GET_SALES_LIST_SALE_NUMBERS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getSalesList }) => {
      let result = { values: ['Error: 404'], raw: [] };

      if (getSalesList && getSalesList?.rows?.length) {
        result = {
          values: getSalesList.rows.map((s) => s.saleNumber),
          raw: getSalesList,
        };
      }
      setOptions(result);
    },
  });

  const getOptions = (searchQuery) => {
    fetchOptions({
      variables: {
        limit: 25,
        filters: [
          {
            conditions: [
              { operator: '$like', key: 'saleNumber', value: searchQuery },
              { operator: '$in', key: 'saleStatusCode', value: 'ACTIVE' },
            ],
            operator: '$and',
          },
        ],
      },
    });
  };

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <div className="margin-y-4">
      <Label required className="text-bold">
        Sale Number
      </Label>

      <Controller
        name="saleNumber"
        control={control}
        defaultValue=""
        rules={{ required: 'Sale Number is required' }}
        render={({ field: { ref, ...fieldProps } }) => {
          return (
            <Typeahead
              {...fieldProps}
              data-testid="sale-number"
              filterValue=""
              typeaheadValues={options?.values}
              onFilterKeyDown={() => {
                userTypedRef.current = true;
              }}
              onOptionEnter={(s) => {
                const selectedData = options?.raw?.rows?.find(
                  (o) => o.saleNumber === s,
                );

                userTypedRef.current = false;

                if (selectedData?.saleNumber) {
                  fieldProps.onChange(selectedData);
                }
              }}
              fetchTypeaheadValues={(_, search) => {
                const keyword = search.trim();

                if (userTypedRef.current) getOptions(keyword);
                userTypedRef.current = false;
              }}
              debounceDelay={500}
              inputCharNum={3}
            />
          );
        }}
      />

      <div className="margin-y-1 text-bold text-secondary">
        <ErrorMessage errors={errors} name="saleNumber" />
      </div>
    </div>
  );
};

export default SaleNumberField;
