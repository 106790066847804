import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useFilter } from '../../../components/filter/filter-provider';
import AutoCompleteInput from '../../../components/autocomplete-input';
import { GET_RESPONSIBLE_SCO } from '../task-order.gql';

const ResponsibleSCOFilter = () => {
  const { filterState, onFilterItemChange } = useFilter();
  const [options, setOptions] = useState([]);

  const [fetchOptions] = useLazyQuery(GET_RESPONSIBLE_SCO, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getTaskOrderList }) => {
      let filteredOptions = ['Error: 404'];
      if (getTaskOrderList && getTaskOrderList?.rows?.length) {
        filteredOptions = getTaskOrderList.rows.map((o, index) => ({
          key: o.responsibleSCO + index,
          label: o.responsibleSCO,
          ...o,
        }));
      }
      setOptions(filteredOptions);
    },
  });

  const getOptions = (searchQuery) => {
    fetchOptions({
      variables: {
        limit: 25,
        filters: [
          {
            conditions: [
              { operator: '$like', key: 'responsibleSCO', value: searchQuery },
            ],
            operator: '$and',
          },
        ],
      },
    });
  };

  return (
    <AutoCompleteInput
      id="responsible-sco-filter"
      data-testid="responsible-sco-filter"
      value={filterState?.responsibleSCO}
      options={options}
      onSelect={(selected) => {
        onFilterItemChange({ id: 'responsibleSCO', value: selected });
      }}
      onFilter={(keyword) => {
        getOptions(keyword);
      }}
      inputProps={{
        placeholder: 'Responsible COR',
      }}
    />
  );
};

export default ResponsibleSCOFilter;
