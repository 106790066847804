import React from 'react';
import BannerMessage from '../../components/banner-message';
import { useSalesListing } from './sales-listing-provider';

const SalesListingAlert = () => {
  const {
    salesListingMessage: { type, message },
    setSalesListingData,
  } = useSalesListing();

  if (!type || !message) return null;

  return (
    <BannerMessage
      type={type}
      message={message}
      onClose={() => {
        setSalesListingData('SET_MESSAGE', { type: 'success', message: '' });
      }}
    />
  );
};

export default SalesListingAlert;
