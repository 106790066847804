import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { createContext, useContext, useReducer } from 'react';
import moment from 'moment';
import {
  BLOCK_APPOINTMENT,
  UN_BLOCK_APPOINTMENT,
  GET_SCHEDULED_APPOINTMENT_LIST,
  GET_DRIVER_SCHEDULED_APPOINTMENT_LIST,
  UN_BLOCK_DRIVER_APPOINTMENT,
  BLOCK_DRIVER_APPOINTMENT,
} from './marshalling-schedule.gql';

export const MarshallingScheduleContext = createContext();

const initialState = {
  fleetVendorNumber: null,
  nonWorkingHours: [],
  blockedAppointments: [],
  driverBlockedAppointments: [],
  appointmentStartTime: null,
  appointmentEndTime: null,
  selectedWeekStartDate: moment().startOf('week').toDate(),
  selectedWeekEndDate: moment().endOf('week').toDate(),
  marshallingScheduleMessage: { type: '', message: '' },
  marshallingScheduleError: null,
  scheduledDriverAppointmentList: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_NON_WORKING_HOURS':
      return { ...state, nonWorkingHours: action.payload };
    case 'SET_BLOCKED_APPOINTMENTS':
      return { ...state, blockedAppointments: action.payload };
    case 'SET_DRIVER_BLOCKED_APPOINTMENTS':
      return { ...state, driverBlockedAppointments: action.payload };
    case 'SET_APPOINTMENT_START_TIME':
      return {
        ...state,
        appointmentStartTime: moment(action.payload).utc().format(),
        appointmentEndTime: action.payload
          ? moment(action.payload).add(15, 'minutes').utc().format()
          : null,
      };
    case 'SET_SELECTED_WEEK_START_DATE':
      return { ...state, selectedWeekStartDate: action.payload };
    case 'SET_SELECTED_WEEK_END_1DATE':
      return { ...state, selectedWeekEndDate: action.payload };
    case 'SET_ERROR': {
      return { ...state, marshallingScheduleError: action.payload };
    }
    case 'SET_MESSAGE':
      return { ...state, marshallingScheduleMessage: action.payload };
    case 'SET_SCHEDULED_DRIVER_APPOINTMENT_LIST':
      return { ...state, scheduledDriverAppointmentList: action.payload };
    default:
      throw new Error('Invalid action');
  }
};

function MarshallingScheduleProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setMarshallingScheduleError = (type, payload) => {
    dispatch({
      type: 'SET_ERROR',
      payload: { ...state.marshallingScheduleError, [type]: payload },
    });
  };

  const setMarshallingScheduleData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const setRequestError = (requestError) => {
    setMarshallingScheduleData('SET_MESSAGE', {
      type: 'error',
      message: `${requestError.message} `,
    });
  };

  const [getScheduledAppointmentList, { refetch }] = useLazyQuery(
    GET_SCHEDULED_APPOINTMENT_LIST,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: setRequestError,
      onCompleted: ({ getScheduledAppointmentList: responseData }) => {
        if (getScheduledAppointmentList) {
          const result = [];
          responseData.forEach((d) =>
            result.push(d?.appointmentStartTime.replace('00.0', '')),
          );
          setMarshallingScheduleData('SET_BLOCKED_APPOINTMENTS', result);
          setMarshallingScheduleData('SET_APPOINTMENT_START_TIME', null);
        }
      },
    },
  );

  const [getDriverScheduledAppointmentList] = useLazyQuery(
    GET_DRIVER_SCHEDULED_APPOINTMENT_LIST,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: setRequestError,
      onCompleted: ({ getScheduledDriverAppointmentList: responseData }) => {
        if (getScheduledAppointmentList) {
          const result = [];
          responseData.forEach((d) =>
            result.push(d?.appointmentStartTime.replace('00.0', '')),
          );

          setMarshallingScheduleData(
            'SET_SCHEDULED_DRIVER_APPOINTMENT_LIST',
            responseData,
          );
          setMarshallingScheduleData('SET_DRIVER_BLOCKED_APPOINTMENTS', result);
          setMarshallingScheduleData('SET_APPOINTMENT_START_TIME', null);
        }
      },
    },
  );

  const [blockAppointment] = useMutation(BLOCK_APPOINTMENT, {
    onError: setRequestError,
    onCompleted: (responseData) => {
      if (responseData?.blockAppointment) {
        const date = moment(
          responseData?.blockAppointment?.appointmentStartTime,
        ).format('MM/DD/YYYY');
        const day = moment(
          responseData?.blockAppointment?.appointmentStartTime,
        ).format('dddd');
        const startTime = moment(
          responseData?.blockAppointment?.appointmentStartTime,
        ).format('h:mm a');
        const endTime = moment(
          responseData?.blockAppointment?.appointmentEndTime,
        ).format('h:mm a');

        setMarshallingScheduleData('SET_MESSAGE', {
          type: 'success',
          message: `You have successfully blocked <strong>${date} ${day} ${startTime}-${endTime}</strong>`,
        });

        setMarshallingScheduleData('SET_ERROR', '');
      }
      refetch({
        variables: {
          fleetVendorNumber: '1069',
          weekStartDate: state?.selectedWeekStartDate,
          weekEndDate: state?.selectedWeekEndDate,
        },
      });
    },
  });

  const [unBlockAppointment] = useMutation(UN_BLOCK_APPOINTMENT, {
    onError: setRequestError,
    onCompleted: (responseData) => {
      if (responseData?.unBlockAppointment) {
        const date = moment(
          responseData?.unBlockAppointment?.appointmentStartTime,
        ).format('MM/DD/YYYY');
        const day = moment(
          responseData?.unBlockAppointment?.appointmentStartTime,
        ).format('dddd');
        const startTime = moment(
          responseData?.unBlockAppointment?.appointmentStartTime,
        ).format('h:mm a');
        const endTime = moment(
          responseData?.unBlockAppointment?.appointmentEndTime,
        ).format('h:mm a');

        setMarshallingScheduleData('SET_MESSAGE', {
          type: 'success',
          message: `You have successfully unblocked <strong>${date} ${day} ${startTime}-${endTime}</strong>`,
        });
        setMarshallingScheduleData('SET_ERROR', '');
      }
      refetch({
        variables: {
          fleetVendorNumber: '1069',
          weekStartDate: state?.selectedWeekStartDate,
          weekEndDate: state?.selectedWeekEndDate,
        },
      });
    },
  });

  const [unBlockDriverAppointment] = useMutation(UN_BLOCK_DRIVER_APPOINTMENT, {
    onError: setRequestError,
    onCompleted: (responseData) => {
      if (responseData?.unBlockDriverAppointment) {
        const date = moment(
          responseData?.unBlockDriverAppointment?.appointmentStartTime,
        ).format('MM/DD/YYYY');
        const day = moment(
          responseData?.unBlockDriverAppointment?.appointmentStartTime,
        ).format('dddd');
        const startTime = moment(
          responseData?.unBlockDriverAppointment?.appointmentStartTime,
        ).format('h:mm a');
        const endTime = moment(
          responseData?.unBlockDriverAppointment?.appointmentEndTime,
        ).format('h:mm a');

        setMarshallingScheduleData('SET_MESSAGE', {
          type: 'success',
          message: `You have successfully unblocked <strong>${date} ${day} ${startTime}-${endTime}</strong>`,
        });
        setMarshallingScheduleData('SET_ERROR', '');
      }
    },

    refetchQueries: () => [
      {
        query: GET_DRIVER_SCHEDULED_APPOINTMENT_LIST,
        variables: {
          fleetVendorNumber: '1069',
          weekStartDate: state?.selectedWeekStartDate,
          weekEndDate: state?.selectedWeekEndDate,
        },
      },
    ],
  });

  const [blockDriverAppointment] = useMutation(BLOCK_DRIVER_APPOINTMENT, {
    onError: setRequestError,
    onCompleted: (responseData) => {
      if (responseData?.blockDriverAppointment) {
        const date = moment(
          responseData?.blockDriverAppointment?.appointmentStartTime,
        ).format('MM/DD/YYYY');
        const day = moment(
          responseData?.blockDriverAppointment?.appointmentStartTime,
        ).format('dddd');
        const startTime = moment(
          responseData?.blockDriverAppointment?.appointmentStartTime,
        ).format('h:mm a');
        const endTime = moment(
          responseData?.blockDriverAppointment?.appointmentEndTime,
        ).format('h:mm a');

        setMarshallingScheduleData('SET_MESSAGE', {
          type: 'success',
          message: `You have successfully blocked <strong>${date} ${day} ${startTime}-${endTime}</strong>`,
        });

        setMarshallingScheduleData('SET_ERROR', '');
      }
    },

    refetchQueries: () => [
      {
        query: GET_DRIVER_SCHEDULED_APPOINTMENT_LIST,
        variables: {
          fleetVendorNumber: '1069',
          weekStartDate: state?.selectedWeekStartDate,
          weekEndDate: state?.selectedWeekEndDate,
        },
      },
    ],
  });

  return (
    <MarshallingScheduleContext.Provider
      value={{
        ...state,
        setMarshallingScheduleError,
        setMarshallingScheduleData,
        blockAppointment,
        unBlockAppointment,
        getScheduledAppointmentList,
        getDriverScheduledAppointmentList,
        unBlockDriverAppointment,
        blockDriverAppointment,
        ...props,
      }}
    >
      {children}
    </MarshallingScheduleContext.Provider>
  );
}

export default MarshallingScheduleProvider;

MarshallingScheduleProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useMarshallingSchedule = () =>
  useContext(MarshallingScheduleContext);
