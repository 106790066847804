import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useFilter } from '../../../components/filter/filter-provider';
import AutoCompleteInput from '../../../components/autocomplete-input';
import { GET_TASK_ORDER_NUMBERS } from '../task-order.gql';

const TaskOrderNumberFilter = () => {
  const { filterState, onFilterItemChange } = useFilter();
  const [options, setOptions] = useState([]);

  const [fetchOptions] = useLazyQuery(GET_TASK_ORDER_NUMBERS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getTaskOrderList }) => {
      let filteredOptions = ['Error: 404'];
      if (getTaskOrderList && getTaskOrderList?.rows?.length) {
        filteredOptions = getTaskOrderList.rows.map((o) => ({
          key: o.taskOrderNumber,
          label: o.taskOrderNumber,
          ...o,
        }));
      }
      setOptions(filteredOptions);
    },
  });

  const getOptions = (searchQuery) => {
    fetchOptions({
      variables: {
        limit: 25,
        filters: [
          {
            conditions: [
              { operator: '$like', key: 'taskOrderNumber', value: searchQuery },
            ],
            operator: '$and',
          },
        ],
      },
    });
  };

  return (
    <AutoCompleteInput
      id="task-order-number-filter"
      data-testid="task-order-number-filter"
      value={filterState?.taskOrderNumber}
      options={options}
      onSelect={(selected) => {
        onFilterItemChange({ id: 'taskOrderNumber', value: selected });
      }}
      onFilter={(keyword) => {
        getOptions(keyword);
      }}
      inputProps={{
        placeholder: 'Task Order Number',
      }}
    />
  );
};

export default TaskOrderNumberFilter;
