import { gql } from '@apollo/client';

export const GET_VEHICLE_SALE_LIST = gql`
  query getVehicleSaleList(
    $filters: [Filter!]!
    $order: OrderBy!
    $limit: Int!
    $offset: Int!
  ) {
    getVehicleSaleList(
      filters: $filters
      order: $order
      limit: $limit
      offset: $offset
    ) {
      rows {
        vin
        desiredPrice
        marketValue {
          moodysValue
        }
        saleNumber
        saleContractNumber
        lotNumber
        runNumber
        canUpdateRunOrderNumber
        vehicleListing {
          vin
          makeName
          modelName
          modelYear
          historicValue
          disposalCondition {
            code
            title
          }
        }
        createdByUser
        updatedByUser
      }
      count
      hasMore
    }
  }
`;

export const GET_VEHICLE_SALE_LIST_VINS = gql`
  query getVehicleSaleList($limit: Int!, $filters: [Filter!]!) {
    getVehicleSaleList(limit: $limit, filters: $filters) {
      rows {
        vin
      }
    }
  }
`;

export const GET_VEHICLE_SALE_LIST_CONTRACT_NUMBERS = gql`
  query getVehicleSaleList($limit: Int!, $filters: [Filter!]!) {
    getVehicleSaleList(limit: $limit, filters: $filters) {
      rows {
        saleContractNumber
      }
    }
  }
`;

export const GET_VEHICLE_SALE_LIST_LOT_NUMBERS = gql`
  query getVehicleSaleList($limit: Int!, $filters: [Filter!]!) {
    getVehicleSaleList(limit: $limit, filters: $filters) {
      rows {
        lotNumber
      }
    }
  }
`;

export const GET_VEHICLE_SALE_LIST_FILTERS = gql`
  query GetVehicleSaleListFilters {
    getVehicleSaleListFilters {
      makeFilter
      modelFilter
      yearFilter
      disposalConditionFilter
    }
  }
`;

export const UPDATE_VEHICLE_SALE_DESIRED_PRICE = gql`
  mutation UpdateVehicleSaleDesiredPrice($vin: String!, $desiredPrice: Float!) {
    updateVehicleSaleDesiredPrice(
      vehicleSale: { vin: $vin, desiredPrice: $desiredPrice }
    ) {
      vin
      desiredPrice
      vehicleListing {
        modelYear
        makeName
        modelName
      }
    }
  }
`;

export const DELETE_VEHICLE_SALE = gql`
  mutation DeleteVehicleSale($vin: String!) {
    deleteVehicleSale(vin: $vin) {
      vehicleListing {
        vin
        makeName
        modelName
        modelYear
      }
    }
  }
`;

export const MARKETPLACE_LOOKUP = gql`
  query ($category: String, $order: OrderBy) {
    getLookupItems(category: $category, order: $order) {
      category
      code
      title
    }
  }
`;

export const UPDATE_RUN_ORDER = gql`
  mutation UpdateRunOrder($updateInput: UpdateRunOrderInput!) {
    updateRunOrder(updateInput: $updateInput) {
      vin
      runNumber
      saleNumber
      vehicleListing {
        modelYear
        makeName
        modelName
      }
    }
  }
`;

export default {
  GET_VEHICLE_SALE_LIST,
  GET_VEHICLE_SALE_LIST_VINS,
  GET_VEHICLE_SALE_LIST_CONTRACT_NUMBERS,
  GET_VEHICLE_SALE_LIST_LOT_NUMBERS,
  GET_VEHICLE_SALE_LIST_FILTERS,
  UPDATE_VEHICLE_SALE_DESIRED_PRICE,
  UPDATE_RUN_ORDER,
  DELETE_VEHICLE_SALE,
  MARKETPLACE_LOOKUP,
};
