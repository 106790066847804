import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import {
  GET_SALES_LIST,
  ADD_SALE_LISTING,
  UPDATE_SALE_LISTING,
  CANCEL_SALE_LISTING,
} from './sales-listing.gql';

export const SalesListingContext = createContext();

export const SALES_LISTING_MODAL_MODES = {
  ADD_SALES_LISTING: 'ADD_SALES_LISTING',
  DUPLICATE_SALES_LISTING: 'DUPLICATE_SALES_LISTING',
  UPDATE_SALES_LISTING: 'UPDATE_SALES_LISTING',
  CANCEL_SALES_LISTING: 'CANCEL_SALES_LISTING',
};

const initialState = {
  salesList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  salesListingSelected: null,
  salesListingModalMode: null,
  salesListingModalShow: false,
  salesListingMessage: { type: '', message: '' }, // type => error || success || info e.g.: => {type: 'error', 'Association already exists.'}
  salesListingError: null,
  SALES_LISTING_MODAL_MODES,
  saleListFilters: {
    saleStatusFilter: [],
    saleTypeFilter: [],
  },
  lookup: {
    saleType: [],
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SALES_LIST':
      return { ...state, salesList: action.payload };

    case 'SET_SELECTED':
      return { ...state, salesListingSelected: action.payload };

    case 'SET_MODAL_MODE': {
      return { ...state, salesListingModalMode: action.payload };
    }

    case 'SET_SHOW_MODAL': {
      return { ...state, salesListingModalShow: action.payload };
    }

    case 'SET_MESSAGE':
      return { ...state, salesListingMessage: action.payload };

    case 'SET_ERROR': {
      return { ...state, salesListingError: action.payload };
    }

    case 'SET_SALE_LIST_FILTERS':
      return {
        ...state,
        saleListFilters: action.payload,
      };

    case 'SET_LOOKUP_ITEM':
      return {
        ...state,
        lookup: { [action.key]: action.payload },
      };

    default:
      throw new Error('Invalid action');
  }
};

function SalesListingProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setSalesListingError = (type, payload) => {
    dispatch({
      type: 'SET_ERROR',
      payload: { ...state.salesListingError, [type]: payload },
    });
  };

  const setSalesListingData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const setNestedSalesListingData = (type, key, payload) => {
    dispatch({
      type,
      key,
      payload,
    });
  };

  const resetModal = () => {
    setSalesListingData('SET_SHOW_MODAL', false);
    setSalesListingData('SET_MODAL_MODE', null);
    setSalesListingData('SET_SELECTED', null);
  };

  const setRequestError = (requestError) => {
    setSalesListingError('SET_ERROR', requestError.message);
    setSalesListingData('SET_MESSAGE', {
      type: 'error',
      message: `${requestError.message} `,
    });
  };

  // Get sales list lazy.
  const [getSalesList, { refetch }] = useLazyQuery(GET_SALES_LIST, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: setRequestError,
    onCompleted: (responseData) => {
      if (responseData?.getSalesList) {
        setSalesListingData('SET_SALES_LIST', responseData.getSalesList);
        setSalesListingError('SET_SALES_LIST', '');
      }
    },
  });

  // create sales listing.
  const [addSaleListing] = useMutation(ADD_SALE_LISTING, {
    onError: setRequestError,
    onCompleted: (saleListingResponse) => {
      resetModal();
      const successMessage = `You have successfully created a new sale <strong>${saleListingResponse?.addSaleListing?.saleNumber}</strong>.`;
      setSalesListingData('SET_MESSAGE', {
        type: 'success',
        message: successMessage,
      });

      refetch({
        variables: {
          limit: 10,
          offset: 0,
          order: 'updatedAt DESC',
        },
      });
    },
  });

  // update sales listing.
  const [updateSaleListing] = useMutation(UPDATE_SALE_LISTING, {
    onError: setRequestError,
    onCompleted: (saleListingResponse) => {
      resetModal();
      const successMessage = `You have successfully updated the sale for <strong>${saleListingResponse?.updateSaleListing?.saleNumber}</strong>.`;
      setSalesListingData('SET_MESSAGE', {
        type: 'success',
        message: successMessage,
      });

      refetch({
        variables: {
          limit: 10,
          offset: 0,
          order: 'updatedAt DESC',
        },
      });
    },
  });

  // cancel sale
  const [cancelSaleListing] = useMutation(CANCEL_SALE_LISTING, {
    onError: setRequestError,
    onCompleted: (cancelSaleResponse) => {
      resetModal();
      const successMessage = `You have successfully cancelled the sale for sale number <strong>${cancelSaleResponse?.cancelSaleListing?.saleNumber}</strong>.`;
      setSalesListingData('SET_MESSAGE', {
        type: 'success',
        message: successMessage,
      });

      refetch({
        variables: {
          limit: 10,
          offset: 0,
          order: 'updatedAt DESC',
        },
      });
    },
  });
  return (
    <SalesListingContext.Provider
      value={{
        ...state,
        getSalesList,
        setSalesListingData,
        setNestedSalesListingData,
        setSalesListingError,
        addSaleListing,
        updateSaleListing,
        cancelSaleListing,
        resetModal,
        ...props,
      }}
    >
      {children}
    </SalesListingContext.Provider>
  );
}

export default SalesListingProvider;

SalesListingProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useSalesListing = () => useContext(SalesListingContext);
