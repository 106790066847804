import { useLazyQuery } from '@apollo/client';
import { Alert, Label, Typeahead } from '@gsa/afp-component-library';
import { ErrorMessage } from '@hookform/error-message';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTaskOrder } from '../task-order-provider';
import { GET_CONTRACTOR_NAME } from '../task-order.gql';
import ContractorDetails from '../widgets/contractor-details';

const ContractorNameField = () => {
  const userTypedRef = useRef(false);
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [options, setOptions] = useState({ values: [], raw: [] });
  const [selectedOption, setSelectedOption] = useState({});
  const [displayUEI, setDisplayUEI] = useState(false);
  const mounted = createRef(true);
  const { setTaskOrderMode, setTaskOrderSelected } = useTaskOrder();

  const vendorTypeConverter = (vt) => {
    if (
      vt.find(
        (v) => v.vendorTypeCode === 'SA' || v.vendorTypeCode === 'Auction',
      )
    )
      return 'AHO';
    return 'MSI';
  };

  const [fetchOptions] = useLazyQuery(GET_CONTRACTOR_NAME, {
    fetchPolicy: 'network-only',
    onCompleted: ({ vendorLocationSearch }) => {
      let result = { values: ['Error: 404'], raw: [] };
      let formattedVendorLocation;

      if (vendorLocationSearch?.length) {
        formattedVendorLocation = vendorLocationSearch.map((v) => {
          return {
            ...v,
            subsectionTypeCode: vendorTypeConverter(v.vendorTypes),
          };
        });

        result = {
          values: formattedVendorLocation.map((location) => location.name),
          raw: formattedVendorLocation,
        };
      }
      setOptions(result);
    },
  });

  useEffect(() => {
    if (
      setTaskOrderMode === 'UPDATE_TASK_ORDER' ||
      setTaskOrderMode === 'DUPLICATE_TASK_ORDER' ||
      setTaskOrderMode === 'VIEW_TASK_ORDER'
    ) {
      setDisplayUEI(true);
    }
  }, [setTaskOrderMode]);

  const getOptions = (searchQuery) => {
    fetchOptions({
      variables: {
        limit: 25,
        nameQuery: searchQuery,
        subFilters: {
          operator: '$and',
          conditions: [
            {
              operator: '$in',
              key: 'vendor_type_code',
              value: ['SA', 'Auction', 'Marshalling', 'MAR'],
            },
          ],
        },
      },
    });
  };

  useEffect(() => {
    if (setTaskOrderSelected?.vendorLocation?.name) {
      setValue('contractorName', {
        id: `${setTaskOrderSelected?.contractor?.contractorCode}`,
        name: `${setTaskOrderSelected?.vendorLocation?.name}`,
        subsectionTypeCode: `${setTaskOrderSelected?.contractor?.contractorType}`,
        address1: `${setTaskOrderSelected?.vendorLocation?.address1}`,
        address2: `${setTaskOrderSelected?.vendorLocation?.address2}`,
        city: `${setTaskOrderSelected?.vendorLocation?.city}`,
        stateCode: `${setTaskOrderSelected?.vendorLocation?.stateCode}`,
        postalCode: `${setTaskOrderSelected?.vendorLocation?.postalCode}`,
      });
    }
  }, [setTaskOrderSelected?.vendorLocation]);

  useEffect(() => {
    // mount.current warning => prevents can't perform a react state update on an unmounted component
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <div className="grid-col">
      {setTaskOrderMode === 'ADD_TASK_ORDER' ||
      setTaskOrderMode === 'DUPLICATE_TASK_ORDER' ? (
        <Label required className="text-bold">
          Contractor Name
        </Label>
      ) : (
        <Label className="text-bold">Contractor Name</Label>
      )}

      {(setTaskOrderMode === 'UPDATE_TASK_ORDER' ||
        setTaskOrderMode === 'DUPLICATE_TASK_ORDER' ||
        setTaskOrderMode === 'VIEW_TASK_ORDER') && (
        <div className="text-base-darkest margin-y-2">
          {setTaskOrderSelected?.vendorLocation?.name}
        </div>
      )}

      {(setTaskOrderMode === 'ADD_TASK_ORDER' ||
        setTaskOrderMode === 'DUPLICATE_TASK_ORDER') && (
        <div className="maxw-mobile-lg">
          <Controller
            name="contractorName"
            control={control}
            defaultValue={setTaskOrderSelected?.vendorLocation?.name ?? ''}
            rules={{ required: 'Contract Name is required' }}
            render={({ field: { ref, ...fieldProps } }) => {
              return (
                <Typeahead
                  {...fieldProps}
                  data-testid="contractor-name"
                  filterValue=""
                  placeholder="Enter Contractor Name"
                  typeaheadValues={options?.values}
                  onFilterKeyDown={() => {
                    if (!userTypedRef.current) {
                      setDisplayUEI(false);
                    }
                    userTypedRef.current = true;
                  }}
                  onOptionEnter={(s) => {
                    const selectedData = options?.raw?.find(
                      (o) => o.name === s,
                    );

                    userTypedRef.current = false;

                    if (selectedData?.id) {
                      fieldProps.onChange(selectedData);
                      setDisplayUEI(true);
                      setSelectedOption(selectedData);
                    }
                  }}
                  fetchTypeaheadValues={(_, search) => {
                    const keyword = search.trim();

                    if (userTypedRef.current) getOptions(keyword);
                    userTypedRef.current = false;
                  }}
                  debounceDelay={500}
                  inputCharNum={3}
                />
              );
            }}
          />
        </div>
      )}
      <div className="margin-y-1 text-bold text-secondary">
        <ErrorMessage errors={errors} name="contractorName" />
      </div>
      <div>
        {setTaskOrderMode === 'ADD_TASK_ORDER' && (
          <div className="grid-row">
            {displayUEI ? (
              <ContractorDetails selectedOption={selectedOption} />
            ) : (
              <div className="margin-top-2">
                <Alert actions={false} className="usa-alert--slim" type="info">
                  Unique Entity Identifier (UEI) and address will be populated
                  after Contractor name entry
                </Alert>
              </div>
            )}
          </div>
        )}
        {setTaskOrderMode !== 'ADD_TASK_ORDER' && (
          <ContractorDetails selectedOption={selectedOption} />
        )}
      </div>
    </div>
  );
};

export default ContractorNameField;
