import PropTypes from 'prop-types';
import React from 'react';
import { Filter, FilterItem } from '../../../components/filter';
import VehicleFilter from './vehicle-filter';
import VinFilter from './vin-filter';
import YearFilter from './year-filter';
import ReceivedDateFilter from './received-date-filter';
import StatusFilter from './status-filter';
import CustomerFilter from './customer-filter';
import TagFilter from './license-plate-filter';

const MarshallingFilter = ({ onFilter }) => {
  return (
    <Filter onChange={onFilter}>
      <FilterItem
        id="vin-filter"
        title="VIN"
        clearButtons={[{ id: 'vin', clearValue: null }]}
        content={<VinFilter />}
      />
      <FilterItem
        id="vehicle-filter"
        title="Vehicle"
        content={<VehicleFilter />}
      />
      <FilterItem
        id="year-filter"
        title="Year"
        content={<YearFilter />}
        clearButtons={[{ id: 'year', clearValue: null }]}
      />
      <FilterItem
        id="received-date-filter"
        title="Received Date"
        clearButtons={[{ id: 'receivedDate', clearValue: null }]}
        content={<ReceivedDateFilter />}
      />
      <FilterItem
        id="status-filter"
        title="Status"
        clearButtons={[
          { text: 'Clear status', id: 'status', clearValue: null },
        ]}
        content={<StatusFilter />}
      />
      <FilterItem
        id="customer-filter"
        title="Customer"
        clearButtons={[
          { text: 'Clear customer', id: 'customer', clearValue: null },
        ]}
        content={<CustomerFilter />}
      />
      <FilterItem
        id="license-plate-filter"
        title="License Plate"
        clearButtons={[{ id: 'tag', clearValue: null }]}
        content={<TagFilter />}
      />
    </Filter>
  );
};

MarshallingFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

export default MarshallingFilter;
