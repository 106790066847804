import React from 'react';
import PropTypes from 'prop-types';
import { StatusTag } from '@gsa/afp-component-library';

const colors = {
  green: 'green',
  cyan: 'cyan',
  grey: 'gray-30',
};

const statusMap = {
  New: colors.green,
  Received: colors.cyan,
  AppointmentSet: colors.grey,
};

const MarshallingStatusTag = ({ statusCode }) => {
  if (!statusCode) {
    return null;
  }
  const status = statusMap[statusCode];
  return <StatusTag color={status}>{statusCode}</StatusTag>;
};

MarshallingStatusTag.defaultProps = {
  statusCode: undefined,
};

MarshallingStatusTag.propTypes = {
  statusCode: PropTypes.string,
};

export default MarshallingStatusTag;
