/* eslint-disable react/prop-types */
import { useQuery } from '@apollo/client';
import {
  AFPTable,
  AFPTableRowAction,
  Button,
  Pagination,
} from '@gsa/afp-component-library';
import { Can, useAppAbility } from '@gsa/afp-shared-ui-utils';
import { DateTime } from 'luxon';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DisposalStatusTag from '../../components/disposal-status-tag';
import useDidMountEffect from '../../hooks/use-did-mount';
import { formatDataValue } from '../../utils/formatting';
import SaleNumberCell from '../sales-listing/widgets/sale-number-cell';
import SalesDisposalFilter from './sales-disposal-filter';
import SalesDisposalModal from './sales-disposal-modal';
import {
  SalesLocationType,
  vehicleTableCellType,
} from './sales-disposal-proptypes';
import { useSalesDisposal } from './sales-disposal-provider';
import { GET_DISPOSAL_LIST_FILTERS } from './sales-disposal.gql';
import DisposalCondition from './widgets/disposal-condition';
import LotToSaleAction from './widgets/lot-to-sale-action';
import EmptyIconSVG from '../../assets/images/icon-empty.svg';

const actionList = [
  {
    icon: 'edit',
    label: 'Associate auction house',
    canShowIndicator: 'canBeAssociated',
  },
  {
    icon: 'edit',
    label: 'Modify Condition Code',
    canShowIndicator: 'canModifyConditionCode',
  },
];

const initFilters = [
  {
    conditions: [],
    operator: '$and',
  },
];

const SaleLocation = ({ location }) => {
  const name = location?.name ? location.name : '';
  const state = location?.stateCode ? `,${location.stateCode}` : '';

  if (!name.length) return <>&ndash;</>;

  return <>{`${name} ${state}`}</>;
};

SaleLocation.propTypes = SalesLocationType;

const VehicleTableCell = ({ title, vin }) => {
  const vehicleURL = `${window.AFP_CONFIG.appURLs.vms}/vehicle/${vin}`;
  const handleClick = () => {
    window.open(vehicleURL);
  };
  return (
    <div>
      <div>
        <strong>{title}</strong>
      </div>
      <Button
        data-testid="vehicle-detail-link"
        variant="unstyled"
        onClick={handleClick}
      >
        {vin}
      </Button>
    </div>
  );
};
VehicleTableCell.propTypes = vehicleTableCellType;

const SalesDisposalTable = () => {
  const ability = useAppAbility();
  const filterRef = useRef(false);

  const [filters, setFilters] = useState(initFilters);
  const [order, setOrder] = useState('listingDate DESC');

  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const {
    getDisposalList,
    salesDisposalList,
    setSalesDisposalData,
    salesDisposalLoading,
    selectedRows,
    selectDisabledRows,
  } = useSalesDisposal();

  const tableRef = React.createRef();

  // Load filters
  useQuery(GET_DISPOSAL_LIST_FILTERS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setSalesDisposalData(
        'SET_DISPOSAL_LIST_FILTERS',
        data.getDisposalListFilters,
      );
    },
  });

  const getData = () => {
    const { limit, offset } = paginationState;

    getDisposalList({
      variables: { filters, limit, offset, order },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useDidMountEffect(() => {
    getData();
  }, [filters, order, paginationState]);

  const handleSelectedAction = (e, row) => {
    setSalesDisposalData('SET_SELECTED', row?.original);

    if (e === 'Associate auction house')
      setSalesDisposalData('SET_MODAL_MODE', 'ADD_AUCTION_HOUSE_ASSOCIATION');

    if (e === 'Modify Condition Code')
      setSalesDisposalData('SET_MODAL_MODE', 'MODIFY_CONDITION_CODE');

    setSalesDisposalData('SET_SHOW_MODAL', true);
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'VIN',
        accessor: 'vin',
        Cell: ({ value, row: { original } }) => {
          const carTitle = `${original?.modelYear} ${original?.makeName} ${original?.modelName}`;
          return <VehicleTableCell title={carTitle} vin={value} />;
        },
      },
      {
        Header: 'Listing Date',
        accessor: 'listingDate',
        Cell: ({ value }) => {
          return formatDataValue(
            value ? DateTime.fromISO(value).toFormat('LL/dd/yyyy') : null,
          );
        },
      },
      {
        Header: 'Disposal Status',
        accessor: 'disposalStatus.code',
        Cell: ({ row: { original } }) => {
          return (
            <DisposalStatusTag
              // eslint-disable-next-line react/prop-types
              statusCode={original?.disposalStatus?.code}
              // eslint-disable-next-line react/prop-types
              statusDescription={original?.disposalStatus?.title}
            />
          );
        },
      },
      {
        Header: 'Current Location',
        accessor: 'currentLocation.name',
        Cell: ({ value }) => formatDataValue(value),
        sortable: false,
      },
    ];
    if (ability.can('update', 'VehicleListing')) {
      columnList.push({
        Header: 'Actions',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: (props) => (
          <AFPTableRowAction
            actions={actionList}
            onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
            {...props}
          />
        ),
      });
    }
    return columnList;
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return (
      <div className="grid-container sales-disposal-sub-component-wrapper">
        <div className="grid-row grid-gap-6">
          <div className="tablet:grid-col desktop:margin-bottom-4">
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">License Plate (Tag Qty)</div>
              <div className="tablet:grid-col text-right">
                {original?.tag && (
                  <div>
                    {original?.tag} {`(${original?.tagCount})`}
                  </div>
                )}
              </div>
            </div>
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Condition Code</div>
              <div className="tablet:grid-col text-right">
                <DisposalCondition
                  disposalCondition={original?.disposalCondition}
                />
              </div>
            </div>
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Third Party Value</div>
              <div className="tablet:grid-col text-right">
                {formatDataValue(original?.marketValue?.moodysValue, true)}
              </div>
            </div>
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Historic Value</div>
              <div className="tablet:grid-col text-right">
                {formatDataValue(original?.historicValue, true)}
              </div>
            </div>
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Edited by</div>
              <div className="tablet:grid-col text-right">
                {formatDataValue(original?.updatedByUser)}
              </div>
            </div>
          </div>
          <div className="tablet:grid-col">
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Sale Location</div>
              <div className="tablet:grid-col text-right">
                <SaleLocation
                  location={original?.association?.sellingLocation}
                />
              </div>
            </div>
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Transportation</div>
              <div className="tablet:grid-col text-right">
                {formatDataValue(
                  original?.association?.transportationType?.title,
                )}
              </div>
            </div>
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Days to Sell</div>
              <div className="tablet:grid-col text-right">
                {formatDataValue(original?.daysToSell)}
              </div>
            </div>
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Sale Number</div>
              <div className="tablet:grid-col text-right">
                <SaleNumberCell data={original?.vehicleSale?.saleListing} />
              </div>
            </div>
            <div className="grid-row padding-y-2">
              <div className="text-bold">Cond. comment</div>
              <div className="tablet:grid-col text-right">
                {formatDataValue(original?.disposalConditionComment)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const formatFilter = useCallback((filterState) => {
    // Prevents firing at first load.
    if (!filterRef.current) {
      filterRef.current = true;
      return;
    }

    const filterObject = {
      operator: '$and',
      conditions: [],
    };

    // VIN
    if (filterState?.vin)
      filterObject.conditions.push({
        operator: '$exact',
        key: 'vin',
        value: filterState.vin.vin,
      });

    // Vehicle
    if (filterState?.makeCode) {
      // Make
      if (filterState?.makeCode)
        filterObject.conditions.push({
          operator: '$exact',
          key: 'makeCode',
          value: filterState.makeCode,
        });

      // Model
      if (filterState?.modelCode)
        filterObject.conditions.push({
          operator: '$exact',
          key: 'modelCode',
          value: filterState.modelCode,
        });
    }

    // Year
    if (filterState?.modelYear)
      filterObject.conditions.push({
        operator: '$exact',
        key: 'modelYear',
        value: filterState.modelYear,
      });

    // Listing date
    if (filterState?.listingDate) {
      const from = DateTime.fromFormat(filterState?.listingDate, 'D')
        .startOf('day')
        .toUTC()
        .toISO();
      const to = DateTime.fromFormat(filterState?.listingDate, 'D')
        .endOf('day')
        .toUTC()
        .toISO();

      filterObject.conditions.push({
        operator: '$between',
        key: 'listingDate',
        value: [from, to],
      });
    }

    // Disposal status
    if (filterState?.disposalStatus) {
      filterObject.conditions.push({
        operator: '$in',
        key: 'disposalStatusCode',
        value: filterState.disposalStatus,
      });
    }

    // Location name
    if (filterState?.currentLocation) {
      // currentLocationCode
      filterObject.conditions.push({
        operator: '$exact',
        key: 'currentLocationCode',
        value: filterState?.currentLocation?.id,
      });
      // currentLocationCode
      filterObject.conditions.push({
        operator: '$exact',
        key: 'currentLocationType',
        value: filterState?.currentLocation?.subsectionTypeCode,
      });
    }

    // Tag count
    if (filterState?.tagCount) {
      filterObject.conditions.push({
        operator: '$in',
        key: 'tagCount',
        value: filterState.tagCount,
      });
    }

    setFilters(filterObject);
  }, []);

  const handleOnRowSelect = ({ selectedFlatRows }) => {
    setSalesDisposalData('SET_VEHICLE_SALE_ROWS_SELECTED', selectedFlatRows);
  };

  return (
    <>
      <Can I="update" a="VehicleListing">
        <LotToSaleAction
          onClearSelection={() => {
            if (tableRef.current) tableRef.current.toggleAllRowsSelected(false);
          }}
        />
      </Can>
      <div className="sales-disposal-table grid-row grid-gap-2">
        <div className="tablet:grid-col-2 tablet:padding-top-4 ">
          <SalesDisposalFilter onFilter={formatFilter} />
        </div>

        <div className="tablet:grid-col-10">
          <AFPTable
            expandable
            fullWidth
            ref={tableRef}
            testId="sales-disposal-table"
            columns={columns}
            data={salesDisposalList.rows}
            renderRowSubComponent={renderRowSubComponent}
            onSort={setOrder}
            defaultSort={order}
            selectable={ability.can('update', 'VehicleListing')}
            selectedRows={selectedRows}
            selectDisabledRows={selectDisabledRows}
            onRowSelect={handleOnRowSelect}
          />
          <div className="margin-y-6">
            <Pagination
              fullWidth
              variant="advanced"
              itemsPerPageOptions={[10, 25, 50]}
              itemsCount={salesDisposalList.count}
              itemsPerPage={paginationState.limit}
              currentPage={paginationState.currentPage}
              onPageChange={handlePaginationChange}
            />
            {!salesDisposalLoading && !salesDisposalList?.rows?.length ? (
              <div
                className="vehicle-sale-table-no-listings display-flex flex-column"
                data-testid="vehicle-sale-table-no-listings"
              >
                <div className="title display-flex flex-row flex-justify-center">
                  <strong>No Results Available</strong>
                </div>
                <div className="description display-flex flex-row flex-justify-center margin-top-2">
                  There are no matches for the filtered values at left.
                </div>
                <div className="display-flex flex-row flex-justify-center margin-top-2">
                  <img src={EmptyIconSVG} alt="Empty Vehicle Sale Listing" />
                </div>
              </div>
            ) : null}
          </div>
          <SalesDisposalModal />
        </div>
      </div>
    </>
  );
};

export default SalesDisposalTable;
