import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import SaleNumberField from './sale-number-field';
import VehicleSaleTable from '../vehicle-sale-table';
import { useSalesDisposal } from '../sales-disposal-provider';

const VehicleSaleForm = () => {
  const methods = useForm();
  const { vehicleSaleRowsSelected, addVehicleSale } = useSalesDisposal();

  const onSubmit = ({ saleNumber }) => {
    const vins = vehicleSaleRowsSelected?.map((s) => s?.original?.vin);
    addVehicleSale({
      variables: {
        saleNumber: saleNumber?.saleNumber,
        vins,
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="vehicle-sale-add-form"
        id="vehicle-sale-add-form"
        className="usa-form usa-form--large"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <SaleNumberField />

        <p>
          The following <strong>{vehicleSaleRowsSelected?.length}</strong>{' '}
          vehicle(s) have been selected to be lotted to Sale.
        </p>
      </form>
      <VehicleSaleTable />
    </FormProvider>
  );
};

export default VehicleSaleForm;
