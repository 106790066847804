import React from 'react';
import { Spinner } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import ErrorMessage from './error-message';

const Unauthorized = () => {
  const { isLoading } = useCurrentUser();

  if (isLoading) return <Spinner className="margin-y-9" />;

  return (
    <>
      <h1 className="tablet:padding-x-10">Access denied</h1>
      <ErrorMessage
        id="Unauthorized"
        alert="It is restricted to GSA Fleet employees."
        heading="You're not authorized to access this page."
        description={[
          <div key="unathorized-error">
            If you need assistance or believe you’re receiving this message in
            error, please contact
            <br />
            <a href="mailto:fleetsystemsmodernization@gsa.gov">
              fleetsystemsmodernization@gsa.gov
            </a>
          </div>,
        ]}
      />
    </>
  );
};

export default Unauthorized;
