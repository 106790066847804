import { useQuery } from '@apollo/client';
import {
  AFPTable,
  AFPTableRowAction,
  Button,
  Pagination,
} from '@gsa/afp-component-library';
import { DateTime } from 'luxon';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useDidMountEffect from '../../hooks/use-did-mount';
import { formatDataValue } from '../../utils/formatting';
import { vehicleTableCellType } from '../sales-disposal/sales-disposal-proptypes';
import MarshallingFilter from './marshalling-filter/index';
import { useMarshalling } from './marshalling-provider';
import { GET_MARSHALLING_FILTERS } from './marshalling.gql';
import MarshallingStatusTag from './widgets/marshalling-status-tag';
import EmptyIconSVG from '../../assets/images/icon-empty.svg';

const actionList = [];

const initFilters = [
  {
    conditions: [],
    operator: '$and',
  },
];

const VehicleTableCell = ({ title, vin }) => {
  const vehicleURL = `${window.AFP_CONFIG.appURLs.vms}/vehicle/${vin}`;
  const handleClick = () => {
    window.open(vehicleURL);
  };
  return (
    <div>
      <div>
        <strong>{title}</strong>
      </div>
      <Button
        data-testid="vehicle-detail-link"
        variant="unstyled"
        onClick={handleClick}
      >
        {vin}
      </Button>
    </div>
  );
};
VehicleTableCell.propTypes = vehicleTableCellType;

const MarshallingTable = () => {
  const tableRef = React.createRef();
  const filterRef = useRef(false);
  const [order, setOrder] = useState('vehicleMarshallingStatus.code DESC');
  const [filters, setFilters] = useState(initFilters);

  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });
  const {
    getMarshallingVehiclesList,
    setMarshallingData,
    marshallingVehiclesListLoading,
    marshallingVehiclesList,
  } = useMarshalling();

  useQuery(GET_MARSHALLING_FILTERS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setMarshallingData(
        'SET_MARSHALLING_FILTERS',
        data?.getMarshallingFilters,
      );
    },
  });

  const getData = () => {
    const { limit, offset } = paginationState;
    getMarshallingVehiclesList({
      variables: { filters, limit, offset, order },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useDidMountEffect(() => {
    getData();
  }, [filters, order, paginationState]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'VIN',
        accessor: 'vin',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value, row: { original } }) => {
          // eslint-disable-next-line react/prop-types
          const carTitle = `${original?.year} ${original?.makeName} ${original?.modelName}`;
          return <VehicleTableCell title={carTitle} vin={value} />;
        },
      },
      {
        Header: 'Received Date',
        accessor: 'receivedDate',
        Cell: ({ value }) => {
          return formatDataValue(
            value ? DateTime.fromISO(value).toFormat('LL/dd/yyyy') : null,
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'vehicleMarshallingStatus.code',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return (
            <MarshallingStatusTag
              // eslint-disable-next-line react/prop-types
              statusCode={original?.vehicleMarshallingStatus?.title}
            />
          );
        },
      },
      {
        Header: 'License Plate',
        accessor: 'tag',
      },
      {
        Header: 'Actions',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: (props) => <AFPTableRowAction actions={actionList} {...props} />,
      },
    ];
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return (
      <div className="grid-container sales-disposal-sub-component-wrapper">
        <div className="grid-row grid-gap-6">
          <div className="tablet:grid-col desktop:margin-bottom-4">
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Customer</div>
              <div className="tablet:grid-col text-right">
                {original?.marshallingCustomer?.title ?? <>&ndash;</>}
              </div>
            </div>
          </div>
          <div className="tablet:grid-col">
            <div className="grid-row padding-y-2 border-bottom border-base-lighter">
              <div className="text-bold">Order Number</div>
              <div className="tablet:grid-col text-right">
                {original?.orderNumber ?? <>&ndash;</>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const formatFilter = useCallback((filterState) => {
    // Prevents firing at first load.
    if (!filterRef.current) {
      filterRef.current = true;
      return;
    }

    const filterObject = {
      operator: '$and',
      conditions: [],
    };

    // VIN
    if (filterState?.vin)
      filterObject.conditions.push({
        operator: '$exact',
        key: 'vin',
        value: filterState.vin.vin,
      });

    // Vehicle
    if (filterState?.makeCode) {
      // Make
      if (filterState?.makeCode)
        filterObject.conditions.push({
          operator: '$exact',
          key: 'makeCode',
          value: filterState.makeCode,
        });

      // Model
      if (filterState?.modelCode)
        filterObject.conditions.push({
          operator: '$exact',
          key: 'modelCode',
          value: filterState.modelCode,
        });
    }

    // Year
    if (filterState?.year)
      filterObject.conditions.push({
        operator: '$exact',
        key: 'year',
        value: filterState.year,
      });

    // Received date
    if (filterState?.receivedDate) {
      const from = DateTime.fromFormat(filterState?.receivedDate, 'D')
        .startOf('day')
        .toUTC()
        .toISO();
      const to = DateTime.fromFormat(filterState?.receivedDate, 'D')
        .endOf('day')
        .toUTC()
        .toISO();

      filterObject.conditions.push({
        operator: '$between',
        key: 'receivedDate',
        value: [from, to],
      });
    }

    // status
    if (filterState?.status) {
      filterObject.conditions.push({
        operator: '$in',
        key: 'marshallingStatusCode',
        value: filterState.status,
      });
    }

    // customer
    if (filterState?.customer) {
      filterObject.conditions.push({
        operator: '$in',
        key: 'customerCode',
        value: filterState.customer,
      });
    }

    // tag
    if (filterState?.tag)
      filterObject.conditions.push({
        operator: '$exact',
        key: 'tag',
        value: filterState.tag.tag,
      });

    setFilters(filterObject);
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  return (
    <div className="marshalling-table grid-row grid-gap-2">
      <div className="tablet:grid-col-2 tablet:padding-top-4 ">
        <MarshallingFilter onFilter={formatFilter} />
      </div>
      <div className="tablet:grid-col-10">
        <AFPTable
          expandable
          fullWidth
          ref={tableRef}
          testId="marshalling-table"
          columns={columns}
          data={marshallingVehiclesList?.rows}
          renderRowSubComponent={renderRowSubComponent}
          onSort={setOrder}
          defaultSort={order}
        />
        <div className="margin-y-6">
          <Pagination
            fullWidth
            variant="advanced"
            itemsPerPageOptions={[10, 25, 50]}
            itemsCount={marshallingVehiclesList.count}
            itemsPerPage={paginationState.limit}
            currentPage={paginationState.currentPage}
            onPageChange={handlePaginationChange}
          />
          {!marshallingVehiclesListLoading &&
          !marshallingVehiclesList?.rows?.length ? (
            <div
              className="vehicle-sale-table-no-listings display-flex flex-column"
              data-testid="vehicle-sale-table-no-listings"
            >
              <div className="title display-flex flex-row flex-justify-center">
                <strong>No Results Available</strong>
              </div>
              <div className="description display-flex flex-row flex-justify-center margin-top-2">
                There are no matches for the filtered values at left.
              </div>
              <div className="display-flex flex-row flex-justify-center margin-top-2">
                <img src={EmptyIconSVG} alt="Empty Vehicle Sale Listing" />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MarshallingTable;
