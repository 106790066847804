import { TextInput, Label } from '@gsa/afp-component-library';
import React from 'react';
import { ErrorMessage } from '@hookform/error-message';

import { useFormContext, Controller } from 'react-hook-form';

const DriverPhoneNumber = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="driver-phone-number">
      <Label required className="text-bold">
        Phone
      </Label>
      <Controller
        name="driverPhoneNumber"
        control={control}
        rules={{
          required: 'Driver Phone Number is required',
        }}
        defaultValue=""
        render={({ field: { ref, ...fieldProps } }) => (
          <>
            <TextInput
              data-testid="driver-phone-number"
              className="text-left width-card-lg"
              {...fieldProps}
            />
          </>
        )}
      />
      <div className="margin-y-1 text-bold text-secondary">
        <ErrorMessage errors={errors} name="driverPhoneNumber" />
      </div>
    </div>
  );
};

export default DriverPhoneNumber;
