import { Checkbox } from '@gsa/afp-component-library';
import { snakeCase } from 'lodash';
import React, { useMemo } from 'react';
import { useFilter } from '../../../components/filter/filter-provider';
import { arrayToggle } from '../../../utils/list';
import { useTaskOrder } from '../task-order-provider';

const StatusFilter = () => {
  const {
    taskOrderFilters: { statusFilter },
  } = useTaskOrder();

  const { filterState, onFilterItemChange } = useFilter();

  const value = useMemo(() => {
    return filterState.status ?? [];
  }, [filterState.status]);

  return (
    <>
      {statusFilter.map((option) => (
        <Checkbox
          id={snakeCase(option.label)}
          key={option.label}
          name={option.label}
          label={option.label}
          checked={value.includes(option.value)}
          onChange={() => {
            const selected = arrayToggle(value, option.value);
            let newValue = null;

            if (selected.length) newValue = selected;

            onFilterItemChange({
              id: 'status',
              value: newValue,
            });
          }}
        />
      ))}
    </>
  );
};

export default StatusFilter;
