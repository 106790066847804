/* eslint-disable react/prop-types */
import { AFPTable } from '@gsa/afp-component-library';
import React, { useEffect, useMemo, useState } from 'react';
import { useSalesDisposal } from './sales-disposal-provider';

const VehicleSaleTable = () => {
  const [tableData, setTableData] = useState([]);
  const { vehicleSaleRowsSelected } = useSalesDisposal();

  useEffect(() => {
    setTableData(vehicleSaleRowsSelected.map((v) => v?.original));
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Vehicle',
        sortable: false,
        Cell: ({ row: { original } }) => {
          return (
            <strong>
              {`${original?.modelYear} ${original?.makeName} ${original?.modelName}`}
            </strong>
          );
        },
      },
      {
        Header: 'VIN Number',
        accessor: 'vin',
      },
    ],
    [],
  );

  return (
    <div>
      <AFPTable
        fullWidth
        testId="vehicle-sale-table"
        columns={columns}
        data={tableData}
      />
    </div>
  );
};

export default VehicleSaleTable;
