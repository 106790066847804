import { Calendar, Button } from '@gsa/afp-component-library';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import useDidMountEffect from '../../hooks/use-did-mount';
import ScheduleTable from './schedule-table';
import { useMarshallingSchedule } from './marshalling-schedule-provider';
import MarshallingInformation from './marshalling-information';

const AppointmentScheduler = () => {
  const [selectedDate, setSelectedDate] = useState();
  const {
    nonWorkingHours,
    blockedAppointments,
    driverBlockedAppointments,
    setMarshallingScheduleData,
    appointmentStartTime,
    appointmentEndTime,
    blockAppointment,
    unBlockAppointment,
    getScheduledAppointmentList,
    getDriverScheduledAppointmentList,
    selectedWeekStartDate,
    selectedWeekEndDate,
    unBlockDriverAppointment,
    scheduledDriverAppointmentList,
  } = useMarshallingSchedule();

  const getData = (weekStartDate, weekEndDate) => {
    getScheduledAppointmentList({
      variables: {
        fleetVendorNumber: '1069',
        weekStartDate,
        weekEndDate,
      },
    });
  };

  const getDriverAppointmentData = (weekStartDate, weekEndDate) => {
    getDriverScheduledAppointmentList({
      variables: {
        fleetVendorNumber: '1069',
        weekStartDate,
        weekEndDate,
      },
    });
  };

  useEffect(() => {
    // get appointment list for current week on inital render
    const currentWeekStartDate = moment().startOf('week').toDate();
    const currentWeekEndDate = moment().endOf('week').toDate();
    getData(currentWeekStartDate, currentWeekEndDate);
    getDriverAppointmentData(currentWeekStartDate, currentWeekEndDate);
  }, []);

  useDidMountEffect(() => {
    getData(selectedWeekStartDate, selectedWeekEndDate);
  }, [selectedWeekStartDate, selectedWeekEndDate]);

  const handleOnBlock = () => {
    const isAppointmentBlocked = blockedAppointments.every(
      (blockedAppointment) => blockedAppointment !== appointmentStartTime,
    );

    // TODO: add vendorId
    if (appointmentEndTime && isAppointmentBlocked) {
      blockAppointment({
        variables: {
          fleetVendorNumber: '1069',
          appointmentStartTime,
          appointmentEndTime,
        },
      });
    }
  };

  const handleUnBlock = () => {
    const isAppointmentBlocked = blockedAppointments.some(
      (blockedAppointment) => blockedAppointment === appointmentStartTime,
    );

    // unblock only blocked time slot
    if (appointmentEndTime && isAppointmentBlocked) {
      unBlockAppointment({
        variables: {
          fleetVendorNumber: '1069',
          appointmentStartTime,
          appointmentEndTime,
        },
      });
    }

    const isDriverAppointmentBlocked = driverBlockedAppointments.some(
      (blockedAppointment) => blockedAppointment === appointmentStartTime,
    );

    const getAppointmentId = scheduledDriverAppointmentList?.filter(
      (scheduledAppointment) =>
        scheduledAppointment.appointmentStartTime.replace('00.0', '') ===
        appointmentStartTime,
    );

    if (
      getAppointmentId[0]?.appointmentId &&
      appointmentEndTime &&
      isDriverAppointmentBlocked
    ) {
      unBlockDriverAppointment({
        variables: {
          fleetVendorNumber: '1069',
          appointmentStartTime,
          appointmentEndTime,
          appointmentId: getAppointmentId[0]?.appointmentId,
          vin: '283748239289',
        },
      });
    }
  };

  return (
    <div className="grid-col calendar-test">
      <div className="grid-row row-gap">
        <div className="tablet:grid-col-3">
          <h2 className="text-primary">SCHEDULE APPOINTMENT</h2>
          <Calendar
            handleSelectDate={(dateString) => {
              setMarshallingScheduleData(
                'SET_SELECTED_WEEK_START_DATE',
                moment(dateString).startOf('week').toDate().toString(),
              );
              setMarshallingScheduleData(
                'SET_SELECTED_WEEK_END_1DATE',
                moment(dateString).endOf('week').toDate().toString(),
              );
              return setSelectedDate(dateString);
            }}
            disableWeekends={false}
            onKeyDown={null}
          />
          <h2 className="text-primary padding-top-4">MARSHALLER INFORMATION</h2>
          <MarshallingInformation />
        </div>
        <div className="grid-col-8 margin-left-3">
          <ScheduleTable
            dateSelected={selectedDate}
            blockedHours={[
              ...blockedAppointments,
              ...driverBlockedAppointments,
            ]}
            bookedHours={nonWorkingHours}
            onDateSelected={(date) => {
              setMarshallingScheduleData('SET_APPOINTMENT_START_TIME', date);
            }}
          />
        </div>
        <div className="display-flex flex-row margin-left-auto margin-right-10">
          <Button
            data-testid="marshalling-schedule-unblock-button"
            className="display-flex margin-left-auto margin-top-4 margin-right-2"
            type="button"
            variant="outline"
            onClick={handleUnBlock}
          >
            Unblock
          </Button>
          <Button
            data-testid="marshalling-schedule-block-button"
            className="display-flex margin-left-auto margin-top-4"
            type="button"
            onClick={handleOnBlock}
          >
            Block
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AppointmentScheduler;
