import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import SellingAgentSearchField from './selling-agent-search-field';
import SelectTransportationField from './select-transportation-field';
import CommentField from './comment-field';
import { useSalesDisposal } from '../sales-disposal-provider';

const getLocation = (formValue, contextValue) => {
  return {
    sellingAgentCode: formValue?.id || contextValue?.sellingAgentCode,
    sellingAgentType:
      formValue?.subsectionTypeCode || contextValue?.sellingAgentType,
  };
};

const VehicleAssociationForm = () => {
  const { salesDisposalSelected, addAuctionHouse } = useSalesDisposal();
  const methods = useForm();
  const onSubmit = ({ sellingLocation, transportationType, comment }) => {
    addAuctionHouse({
      variables: {
        vin: salesDisposalSelected?.vin,
        ...getLocation(sellingLocation, salesDisposalSelected?.association),
        transportationTypeCode: transportationType?.code,
        comment,
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="vehicle-association-add-form"
        id="vehicle-association-add-form"
        className="usa-form usa-form--large"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <SellingAgentSearchField />

        <SelectTransportationField />

        <CommentField />
      </form>
    </FormProvider>
  );
};

export default VehicleAssociationForm;
