import { Label, TextInput } from '@gsa/afp-component-library';
import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext, Controller } from 'react-hook-form';
import { useSalesListing } from '../sales-listing-provider';

const PromotionDescriptionField = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { salesListingSelected, salesListingModalMode } = useSalesListing();
  return (
    <div className="margin-y-4">
      <Label required className="text-bold">
        Promotion Description
      </Label>

      <Controller
        name="promotionDescription"
        control={control}
        defaultValue={
          salesListingModalMode !== 'ADD_SALES_LISTING' &&
          salesListingModalMode !== 'CANCEL_SALES_LISTING'
            ? salesListingSelected.promotionDescription
            : ''
        }
        rules={{ required: 'Promotion description is required' }}
        render={({ field: { ref, ...fieldProps } }) => (
          <TextInput
            data-testid="promotion-description-box"
            className="height-15 maxw-300"
            type="textarea"
            maxLength={2000}
            required
            {...fieldProps}
          />
        )}
      />
      <div className="text-base padding-top-1">2000 characters allowed</div>
      <div className="margin-y-1 text-bold text-secondary">
        <ErrorMessage errors={errors} name="promotionDescription" />
      </div>
    </div>
  );
};

export default PromotionDescriptionField;
