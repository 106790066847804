import { Label, Textbox } from '@gsa/afp-component-library';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSalesDisposal } from '../sales-disposal-provider';

const CommentConditionCodeField = () => {
  const {
    salesDisposalSelected: { disposalConditionComment },
  } = useSalesDisposal();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const wrapperClasses = classNames('margin-y-4', {
    [`usa-form-group--error`]: !!errors.disposalConditionComment,
  });

  const formElementClasses = classNames('height-15', {
    [`usa-input--error`]: !!errors.disposalConditionComment,
  });

  return (
    <div className={wrapperClasses}>
      <Label className="text-bold" required>
        Reason for change
      </Label>

      <Controller
        name="disposalConditionComment"
        control={control}
        rules={{
          required: 'Reason for change is required',
          maxLength: {
            value: 200,
            message: 'Reason for change must not exceed 200 characters',
          },
        }}
        defaultValue={disposalConditionComment ?? ''}
        render={({ field: { ref, ...fieldProps } }) => (
          <Textbox
            {...fieldProps}
            data-testid="disposal-condition-comment"
            className={formElementClasses}
            type="textarea"
            maxLength={200}
          />
        )}
      />

      <div className="text-base padding-top-1">200 characters allowed</div>

      <div className="margin-y-2 text-bold usa-error-message">
        <ErrorMessage errors={errors} name="disposalConditionComment" />
      </div>
    </div>
  );
};

export default CommentConditionCodeField;
