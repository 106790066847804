import { gql } from '@apollo/client';

export const GET_SALES_LIST = gql`
  query getSalesList(
    $filters: [Filter!]!
    $order: OrderBy!
    $limit: Int!
    $offset: Int!
  ) {
    getSalesList(
      filters: $filters
      order: $order
      limit: $limit
      offset: $offset
    ) {
      rows {
        saleNumber
        saleType {
          code
          title
        }
        saleStatus {
          code
          title
        }
        saleLocation {
          typeCode
          code
          name
          stateCode
          postalCode
        }
        saleLocationCode
        saleLocationType
        saleStartDate
        saleFinishDate
        inspectionStartDate1
        inspectionFinishDate1
        inspectionStartDate2
        inspectionFinishDate2
        promotionDescription
        createdByUser
        updatedByUser
      }
      count
      hasMore
    }
  }
`;

export const GET_SALES_LIST_SALE_NUMBERS = gql`
  query getSalesList($limit: Int!, $filters: [Filter!]!) {
    getSalesList(limit: $limit, filters: $filters) {
      rows {
        saleNumber
      }
    }
  }
`;

export const GET_SALE_LIST_FILTERS = gql`
  query GetSaleListFilters {
    getSaleListFilters {
      saleStatusFilter
      saleTypeFilter
    }
  }
`;

export const ADD_SALE_LISTING = gql`
  mutation AddSaleListing(
    $saleNumber: String
    $saleLocationCode: String!
    $saleLocationType: String!
    $saleTypeCode: String!
    $saleStatusCode: String
    $saleStartDate: DateTime!
    $saleFinishDate: DateTime!
    $inspectionStartDate1: DateTime!
    $inspectionFinishDate1: DateTime!
    $inspectionStartDate2: DateTime
    $inspectionFinishDate2: DateTime
    $promotionDescription: String!
  ) {
    addSaleListing(
      saleListing: {
        saleNumber: $saleNumber
        saleLocationType: $saleLocationType
        saleTypeCode: $saleTypeCode
        saleStatusCode: $saleStatusCode
        saleLocationCode: $saleLocationCode
        saleStartDate: $saleStartDate
        saleFinishDate: $saleFinishDate
        inspectionStartDate1: $inspectionStartDate1
        inspectionFinishDate1: $inspectionFinishDate1
        inspectionStartDate2: $inspectionStartDate2
        inspectionFinishDate2: $inspectionFinishDate2
        promotionDescription: $promotionDescription
      }
    ) {
      saleNumber
    }
  }
`;

export const UPDATE_SALE_LISTING = gql`
  mutation UpdateSaleListing(
    $saleNumber: String!
    $saleLocationCode: String!
    $saleLocationType: String!
    $saleTypeCode: String!
    $saleStatusCode: String
    $saleStartDate: DateTime!
    $saleFinishDate: DateTime!
    $inspectionStartDate1: DateTime!
    $inspectionFinishDate1: DateTime!
    $inspectionStartDate2: DateTime
    $inspectionFinishDate2: DateTime
    $promotionDescription: String!
  ) {
    updateSaleListing(
      updateSale: {
        saleNumber: $saleNumber
        saleLocationType: $saleLocationType
        saleTypeCode: $saleTypeCode
        saleStatusCode: $saleStatusCode
        saleLocationCode: $saleLocationCode
        saleStartDate: $saleStartDate
        saleFinishDate: $saleFinishDate
        inspectionStartDate1: $inspectionStartDate1
        inspectionFinishDate1: $inspectionFinishDate1
        inspectionStartDate2: $inspectionStartDate2
        inspectionFinishDate2: $inspectionFinishDate2
        promotionDescription: $promotionDescription
      }
    ) {
      saleNumber
    }
  }
`;

export const CANCEL_SALE_LISTING = gql`
  mutation CancelSaleListing(
    $saleNumber: String!
    $promotionDescription: String!
  ) {
    cancelSaleListing(
      cancelSale: {
        saleNumber: $saleNumber
        promotionDescription: $promotionDescription
      }
    ) {
      saleNumber
    }
  }
`;

export const LOCATION_SEARCH = gql`
  query ($typeCodes: [String!]!, $nameQuery: String!, $limit: Float) {
    locationSearch(
      typeCodes: $typeCodes
      nameQuery: $nameQuery
      limit: $limit
    ) {
      id
      name
      subsectionTypeCode
    }
  }
`;

export const MARKETPLACE_LOOKUP = gql`
  query ($category: String, $order: OrderBy) {
    getLookupItems(category: $category, order: $order) {
      category
      code
      title
    }
  }
`;

export const GET_LOCATION = gql`
  query ($id: String!, $subsectionTypeCode: string!) {
    getLocation(id: $id, subsectionTypeCode: $subsectionTypeCode) {
      id
      name
      subsectionTypeCode
    }
  }
`;

export default {
  GET_SALES_LIST,
  GET_SALES_LIST_SALE_NUMBERS,
  GET_SALE_LIST_FILTERS,
  ADD_SALE_LISTING,
  UPDATE_SALE_LISTING,
  CANCEL_SALE_LISTING,
  LOCATION_SEARCH,
  MARKETPLACE_LOOKUP,
};
