import { Label, TextInput } from '@gsa/afp-component-library';
import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext, Controller } from 'react-hook-form';

const CommentField = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="height-card-lg padding-top-1">
      <Label required className="text-bold">
        Comment
      </Label>

      <Controller
        name="driverInformationComment"
        control={control}
        rules={{ maxLength: 500, required: 'Comment is required' }}
        defaultValue=""
        render={({ field: { ref, ...fieldProps } }) => (
          <TextInput
            data-testid="comment-box"
            className="height-15 maxw-full"
            type="textarea"
            maxLength={500}
            {...fieldProps}
          />
        )}
      />
      <div className="text-base padding-top-1">500 characters allowed</div>
      <div className="margin-y-1 text-bold text-secondary">
        <ErrorMessage errors={errors} name="driverInformationComment" />
      </div>
    </div>
  );
};

export default CommentField;
