import { Button, Label } from '@gsa/afp-component-library';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { useMarshallingSchedule } from '../marshalling-schedule/marshalling-schedule-provider';
import './driver-information.scss';
import CommentField from './forms/comment-field';
import DriverEmailAddress from './forms/driver-email-address';
import DriverName from './forms/driver-name';
import DriverPhoneNumber from './forms/driver-phone-number';

const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const driverInformationSchema = yup.object().shape({
  driverName: yup.string().nullable(),
  driverPhoneNumber: yup
    .string()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Phone number is not valid'),
  driverEmail: yup
    .string()
    .email('Please enter a valid email address')
    .required('Driver Email Address is required'),
  driverInformationComment: yup.string().required('Comment is required'),
});

const DriverInformation = () => {
  const {
    driverBlockedAppointments,
    blockDriverAppointment,
    unBlockDriverAppointment,
    appointmentStartTime,
    appointmentEndTime,
    scheduledDriverAppointmentList,
  } = useMarshallingSchedule();

  const methods = useForm({
    resolver: yupResolver(driverInformationSchema),
  });

  const handleOnBlock = ({ driverPhoneNumber, driverEmail, driverName }) => {
    const isAppointmentBlocked = driverBlockedAppointments.every(
      (blockedAppointment) => blockedAppointment !== appointmentStartTime,
    );
    // TODO: replace fleetVendorNumber,vin
    if (appointmentEndTime && isAppointmentBlocked) {
      blockDriverAppointment({
        variables: {
          fleetVendorNumber: '1069',
          appointmentStartTime,
          appointmentEndTime,
          appointmentId: uuidv4(),
          vin: '283748239289',
          driverName,
          driverEmail,
          driverPhoneNumber,
        },
      });
    }
  };

  const handleUnBlock = ({ driverPhoneNumber, driverEmail, driverName }) => {
    const isDriverAppointmentBlocked = driverBlockedAppointments.some(
      (blockedAppointment) => blockedAppointment === appointmentStartTime,
    );

    const getAppointmentId = scheduledDriverAppointmentList.filter(
      (scheduledAppointment) =>
        scheduledAppointment.appointmentStartTime.replace('00.0', '') ===
        appointmentStartTime,
    );

    // unblock only blocked time slot
    if (
      getAppointmentId[0]?.appointmentId &&
      appointmentEndTime &&
      isDriverAppointmentBlocked
    ) {
      unBlockDriverAppointment({
        variables: {
          fleetVendorNumber: '1069',
          appointmentStartTime,
          appointmentEndTime,
          appointmentId: getAppointmentId[0]?.appointmentId,
          vin: '283748239289',
          driverName,
          driverEmail,
          driverPhoneNumber,
        },
      });
    }
  };

  const getAmPm = () => {
    let a;
    let b;

    if (appointmentStartTime === 'Invalid date') {
      return <>&ndash;</>;
    }
    if (moment(appointmentStartTime).local().format('a') === 'am') {
      a = `${moment(appointmentStartTime).local().format('h:mm')} a.m.`;
      b = `${moment(appointmentEndTime).local().format('h:mm')} p.m.`;
    }
    if (moment(appointmentEndTime).local().format('a') === 'pm') {
      a = `${moment(appointmentStartTime).local().format('h:mm')} a.m.`;
      b = `${moment(appointmentEndTime).local().format('h:mm')} p.m.`;
    }

    return `${a} - ${b}`;
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          data-testid="driver-information-form"
          id="driver-information-form"
          className="usa-form usa-form--large "
        />
        <div className="maxw-tablet border padding-3 bg-primary-lighter border-solid border-primary-light margin-left-auto">
          <h2 className="text-primary">DRIVER INFORMATION</h2>
          <div className="display-flex">
            <div className="date-class">
              <Label className="text-bold">Date</Label>
              <div className="display-flex margin-top-2">
                <div>
                  {appointmentStartTime === 'Invalid date' ? (
                    <>&ndash;</>
                  ) : (
                    moment(appointmentStartTime).format('MM/DD/YYYY')
                  )}
                </div>
                <div className="margin-left-2">
                  {appointmentStartTime === 'Invalid date'
                    ? ''
                    : moment(appointmentStartTime).format('dddd')}
                </div>
              </div>
            </div>
            <div className="driver-appointment-time-value">
              <Label className="text-bold">Time</Label>
              <div className="margin-top-2 display-flex">
                <div className="display-flex">{getAmPm()}</div>
              </div>
            </div>
          </div>
          <div className="display-flex flex-justify height-15">
            <DriverName />
            <DriverPhoneNumber />
          </div>
          <DriverEmailAddress />
          <CommentField />
        </div>
        <div className="driver-schedule-block-unblock">
          <Button
            data-testid="driver-schedule-unblock-button"
            className="display-flex margin-left-auto margin-top-4 margin-left-auto"
            type="button"
            variant="outline"
            onClick={methods.handleSubmit(handleUnBlock)}
          >
            Unblock
          </Button>
          <Button
            data-testid="driver-schedule-block-button"
            className="display-flex margin-left-auto margin-top-4"
            type="submit"
            onClick={methods.handleSubmit(handleOnBlock)}
          >
            Block
          </Button>
        </div>
      </FormProvider>
    </>
  );
};

export default DriverInformation;
