import { useLazyQuery } from '@apollo/client';
import { Label, Typeahead } from '@gsa/afp-component-library';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSalesListing } from '../sales-listing-provider';
import { LOCATION_SEARCH } from '../sales-listing.gql';

const SaleLocationSearchField = () => {
  const userTypedRef = useRef(false);
  const mounted = useRef(true);

  const [options, setOptions] = useState({ values: [], raw: [] });

  const { salesListingModalShow, salesListingSelected, salesListingModalMode } =
    useSalesListing();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [fetchOptions] = useLazyQuery(LOCATION_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted: ({ locationSearch }) => {
      let result = { values: ['Error: 404'], raw: [] };

      if (locationSearch?.length) {
        result = {
          values: locationSearch.map((location) => location.name),
          raw: locationSearch,
        };
      }
      setOptions(result);
    },
  });

  const getOptions = (searchQuery) => {
    fetchOptions({
      variables: {
        limit: 25,
        typeCodes: ['AHO', 'MSI'],
        nameQuery: searchQuery,
      },
    });
  };

  useEffect(() => {
    // Fetch location during edit only if modal is open.
    if (salesListingModalShow && salesListingSelected?.saleLocation?.name) {
      // mount.current warning => prevents can't perform a react state update on an unmounted component
      if (mounted.current) getOptions(salesListingSelected.saleLocation.name);
    }

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <div className="margin-y-4">
      {salesListingModalMode === 'ADD_SALES_LISTING' ? (
        <Label required className="text-bold">
          Sale Location
        </Label>
      ) : (
        <Label className="text-bold">Sale location</Label>
      )}

      <div className="text-base-darkest margin-y-2">
        {salesListingSelected?.saleLocation?.name}
      </div>
      {salesListingModalMode === 'ADD_SALES_LISTING' && (
        <Controller
          name="saleLocation"
          control={control}
          defaultValue=""
          rules={{ required: 'Sale location is required' }}
          render={({ field: { ref, ...fieldProps } }) => {
            return (
              <Typeahead
                {...fieldProps}
                data-testid="sale-location"
                filterValue=""
                typeaheadValues={options?.values}
                onFilterKeyDown={() => {
                  userTypedRef.current = true;
                }}
                onOptionEnter={(s) => {
                  const selectedData = options?.raw.find((o) => o.name === s);

                  userTypedRef.current = false;

                  if (selectedData?.id) {
                    fieldProps.onChange(selectedData);
                  }
                }}
                fetchTypeaheadValues={(_, search) => {
                  const keyword = search.trim();

                  if (userTypedRef.current) getOptions(keyword);
                  userTypedRef.current = false;
                }}
                debounceDelay={500}
                inputCharNum={3}
              />
            );
          }}
        />
      )}
      <div className="margin-y-1 text-bold text-secondary">
        <ErrorMessage errors={errors} name="saleLocation" />
      </div>
    </div>
  );
};

export default SaleLocationSearchField;
