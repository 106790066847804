import React from 'react';
import { Label } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { formatDataValue } from '../../../utils/formatting';

const ContractorDetails = ({ selectedOption }) => {
  return (
    <div>
      <Label className="text-bold">Unique Entity identifier</Label>
      <span data-testid="unique_entity_identifier">
        {formatDataValue(
          selectedOption?.vendorSam?.uniqueEntityId ||
            selectedOption?.vendorSam?.dunsNumber,
        )}
      </span>
      <Label className="text-bold">Street Address</Label>
      <span data-testid="address_1">
        {formatDataValue(selectedOption?.address1)}
      </span>
      <div className="display-flex flex-row">
        <div className="display-flex flex-column">
          <Label className="text-bold padding-right-8">City</Label>
          <span data-testid="city">
            {formatDataValue(selectedOption?.city)}
          </span>
        </div>
        <div className="display-flex flex-column">
          <Label className="text-bold">State</Label>
          <span data-testid="state">
            {formatDataValue(
              selectedOption?.stateCode || selectedOption?.stateName,
            )}
          </span>
        </div>
      </div>
      <Label className="text-bold">Zip</Label>
      <span data-testid="postal_code">
        {formatDataValue(selectedOption?.postalCode)}
      </span>
    </div>
  );
};
ContractorDetails.defaultProps = {
  selectedOption: PropTypes.shape({
    address1: undefined,
    address2: undefined,
    city: undefined,
    id: undefined,
    name: undefined,
    postalCode: undefined,
    stateCode: undefined,
    stateName: undefined,
    vendorSam: {
      uniqueEntityId: undefined,
      dunsNumber: undefined,
    },
  }),
};

ContractorDetails.propTypes = {
  selectedOption: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    postalCode: PropTypes.string,
    stateCode: PropTypes.string,
    stateName: PropTypes.string,
    vendorSam: {
      uniqueEntityId: PropTypes.string,
      dunsNumber: PropTypes.string,
    },
  }),
};

export default ContractorDetails;
