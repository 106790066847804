import { Label, SelectDropdown } from '@gsa/afp-component-library';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSalesDisposal } from '../sales-disposal-provider';

const SelectConditionCodeField = () => {
  const {
    disposalListFilters: { conditionCodeFilter },
    salesDisposalSelected: { disposalConditionCode },
  } = useSalesDisposal();

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const options = [{ label: '- Select -', value: '' }, ...conditionCodeFilter];

  const wrapperClasses = classNames('margin-y-4', {
    [`usa-form-group--error`]: !!errors.disposalConditionCode,
  });

  const formElementClasses = classNames({
    [`usa-input--error`]: !!errors.disposalConditionCode,
  });

  return (
    <div className={wrapperClasses}>
      <Label className="text-bold" required>
        Select a new Condition Code
      </Label>

      <Controller
        name="disposalConditionCode"
        control={control}
        defaultValue={disposalConditionCode ?? ''}
        rules={{
          required: 'Disposal Condition Code is required',
        }}
        render={({ field: { ref, ...fieldProps } }) => (
          <SelectDropdown
            {...fieldProps}
            options={options}
            className={formElementClasses}
            onChange={(e) => {
              // Clears comment input.
              setValue('disposalConditionComment', '');
              fieldProps.onChange(e);
            }}
          />
        )}
      />
      <div className="margin-y-2 text-bold usa-error-message">
        <ErrorMessage errors={errors} name="disposalConditionCode" />
      </div>
    </div>
  );
};

export default SelectConditionCodeField;
